var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"relative overflow-scroll rounded-lg"},[(_vm.loading)?_c('custom-loader'):_vm._e(),(_vm.companyId && _vm.error)?_c('div',{staticClass:"\n      mx-4\n      border\n      text-xs\n      border-error-red\n      text-error-red\n      px-2\n      py-1\n      rounded\n      relative\n      mb-2\n    ",attrs:{"role":"alert"}},[_c('span',{staticClass:"block sm:inline"},[_vm._v(" "+_vm._s(_vm.error)+" ")])]):_vm._e(),_c('div',{staticClass:"flex relative button-container"},[_c('div',{staticClass:"flex self-center border-b-2 border-gris-moyen w-full"}),_c('custom-button',{staticClass:"absolute button-line font-bold",attrs:{"id":"updateAlerts","name":"Actualiser les alertes"},on:{"onClick":_vm.updateCompanyAlerts}})],1),_c('div',{staticClass:"overflow-scroll"},[(_vm.companyId && _vm.employees && _vm.employees.length > 0)?_c('div',{staticClass:"h-table-container text-gris mt-2"},[_c('table',{staticClass:"w-table isolate",attrs:{"aria-describedby":"employeesTable"}},[_c('thead',{staticClass:"relative z-2"},[_c('tr',{staticClass:"sticky top-0"},[_c('th',{class:['sticky_col0 z-1', 'border-0', 'overflow-auto', 'p-4', 'h-table-header'],attrs:{"scope":"col"}},[_c('custom-checkbox',{attrs:{"is-checked":_vm.employees.length === _vm.checkedEmployeesIds.length},on:{"change":_vm.changeStatusOfAll}})],1),_vm._l((_vm.headerItems),function(item,index){return _c('th',{key:index,class:[
                'relative',
                'border-0',
                'w-table-cell',
                'break-normal',
                'text-table-cell-content',
                'h-table-header',
                'p-1',
                'p-b-0',
                { 'border-r-0': index === _vm.headerItems.length - 1 },
                { 'sticky_col1 z-1': index === 0 }
              ],attrs:{"scope":"col"}},[_c('div',{staticClass:"h-full"},[_c('div',{staticClass:"flex flex-col justify-between"},[_c('div',[_c('span',{class:[
                        'float-left',
                        'cursor-pointer',
                        {
                          'text-turquoise':
                            _vm.currentHeaderIndexToSortBy === index && _vm.sortDirection === 'asc'
                        }
                      ],on:{"click":function($event){return _vm.sort(index, 'asc')}}},[_vm._v(" ↓ ")]),_c('span',{class:[
                        'float-left',
                        'cursor-pointer',
                        {
                          'text-turquoise':
                            _vm.currentHeaderIndexToSortBy === index && _vm.sortDirection === 'desc'
                        }
                      ],on:{"click":function($event){return _vm.sort(index, 'desc')}}},[_vm._v(" ↑ ")])]),_c('div',[_c('p',[_c('strong',[_vm._v(_vm._s(item))])])])])])])})],2)]),_c('tbody',{staticClass:"relative z-1"},_vm._l((_vm.employees),function(employee,outerIndex){return _c('tr',{key:outerIndex},[_c('td',{class:['sticky_col0 relative z-1', 'border-0', 'overflow-auto', 'p-4']},[_c('custom-checkbox',{attrs:{"is-checked":_vm.isIdInArray(employee.id)},on:{"change":function($event){return _vm.changeStatus($event, employee.id)}}})],1),_vm._l((employee.fields),function(field,index){return _c('td',{key:outerIndex + '' + index,class:[
                { 'border-2': index !== 0 },
                'border-gris-moyen',
                'w-table-cell',
                {
                  'bg-couleur-orange-30':
                    employee.alerts &&
                    employee.alerts.find(function (alert) { return alert.fieldName === employee.fields[index].key; })
                },
                { 'border-r-0': index === employee.fields.length - 1 },
                { 'sticky_col1 z-1 border-0': index === 0 }
              ]},[_c('custom-table-cell',{attrs:{"value":field.value},on:{"edit-infos":function($event){return _vm.editEmployeeInformations(employee.id)}}})],1)})],2)}),0)])]):(_vm.companyId && _vm.employees && _vm.employees.length === 0)?_c('information',{staticClass:"p-subview",attrs:{"message":"Vous devez d'abord créer des salariés afin d'accéder à cette fonctionnalité"}}):_c('information',{staticClass:"p-subview",attrs:{"message":"Veuillez créer une entreprise afin d'accéder à cette fonctionnalité"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }