<template>
  <div id="main-container" class="bg-gris-clair h-screen overflow-auto">
    <Header />
    <div id="top-container" class="mx-auto mt-8 h-full bg-gris-clair w-sub-view-container">
      <div class="flex">
        <custom-nav-bar
          class="justify-start"
          :elements="getNavBarElements()"
          :activePath="this.$route.path"
        />
        <custom-button
          v-if="this.$route.path === '/followed-companies'"
          id="create"
          name="CRÉER UNE FICHE"
          :imgSource="$asset('icons/plus-icon.svg')"
          altSource="plus-icon"
          @onClick="createCard"
          class="ml-auto"
        />
      </div>
      <div id="content-container" class="bg-couleur-blanc rounded-lg min-h-full h-auto mt-8">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import CustomNavBar from "@/components/organisms/CustomNavBar.vue";
import Header from "@/components/organisms/Header.vue";
import CustomButton from "@/components/atoms/CustomButton.vue";

export default {
  components: { Header, CustomNavBar, CustomButton },
  computed: {
    isAdmin() {
      return localStorage.getItem("is-admin");
    }
  },
  methods: {
    createCard() {
      this.$router.push("/forms");
    },
    getNavBarElements() {
      const navBar = [
        {
          name: "MES ENTREPRISES SUIVIES",
          id: "followedterritories",
          imgSource: "form-icon.svg",
          altSource: "Followed Territories",
          path: "/followed-companies"
        },
        {
          name: "MON TERRITOIRE",
          id: "myterritory",
          imgSource: "territories-icon.svg",
          altSource: "Territory",
          path: "/my-territory"
        }
      ];
      if (this.isAdmin === "true") {
        navBar.push({
          name: "PARAMÈTRES",
          id: "parameters",
          imgSource: "settings-icon.svg",
          altSource: "Parameters",
          path: "/parameters"
        });
      }
      return navBar;
    }
  }
};
</script>
