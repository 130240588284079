<template>
  <div class="file-input-outer-area">
    <div
      :class="[
        'file-input-inner-area',
        'duration-350',
        'hover:bg-gris-moyen',
        'hover:bg-opacity-30'
      ]"
    >
      <input
        type="file"
        class="absolute opacity-0 w-full h-full cursor-pointer"
        ref="file"
        @change="sendFile"
      />
      <img class="h-6" :src="$asset('icons/upload-icon.svg')" alt="" />
      <p
        class="
          font-normal
          text-sm
          text-center
          overflow-hidden
          truncate
          w-60
        "
        :class="!file ? 'text-gris-moyen' : 'text-gris'"
      >
        {{ file ? file.name : title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      required: false
    },
    title: {
      type: String,
      required: false
    }
  },
  methods: {
    sendFile() {
      const [file] = this.$refs.file.files;
      this.$emit("file-uploaded", file);
    }
  }
};
</script>
<style scoped>
.file-input-outer-area {
  @apply mt-6;
  @apply bg-secondary;
  @apply mt-3;
  @apply p-3;
  @apply rounded;
  @apply cursor-pointer;
  @apply w-file-upload-modal-input;
  @apply h-file-upload-modal-input;
}
.file-input-inner-area {
  @apply relative;
  @apply transition;
  @apply border;
  @apply border-dashed;
  @apply border-gris-moyen;
  @apply rounded;
  @apply h-full;
  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply justify-center;
  @apply space-y-1;
}
</style>
