import { Company } from "@/models/Company";
import { User } from "@/models/User";
import { UserResponse } from "@/models/UserResponse";
import UsersService from "@/services/UsersService";

const usersService = new UsersService();

const actions = {
  register(_: any, user: User): Promise<void> {
    return usersService.register(user);
  },
  resetPasswordRequest(_: any, user: User): Promise<UserResponse> {
    return usersService.regeneratePasswordRequest(user);
  },
  getCompanies(_: any, id: string): Promise<Company[]> {
    return usersService.getFollowedCompanies(id);
  },
  getNonActiveUsers(): Promise<User[]> {
    return usersService.getNonActiveUsers();
  },
  activateUser(_: any, id: string): Promise<User[]> {
    return usersService.activateUser(id);
  },
  deleteUser(_: any, id: string): Promise<User[]> {
    return usersService.deleteUser(id);
  },

  deleteCompany(_: any, params: { userID: string; companyID: string }): Promise<UserResponse> {
    return usersService.deleteCompany(params);
  }
};

export default {
  actions
};
