<template>
  <div>
    <template v-if="settings.length > 0 && usedClassification">
      <forms-jobs-cartography
        :settings="settings"
        :recommendation="true"
        :usedClassification="usedClassification"
      />
      <forms-income-analysis
        :setLoading="setLoading"
        :company-id="innerCompanyId"
        :settings="settings"
        :usedClassification="usedClassification"
        :recommendation="true"
        class="mt-20"
      />
    </template>
    <template v-else>
      <information message="Vous devez d'abord sélectionner des paramètres" />
    </template>
  </div>
</template>
<script>
import FormsJobsCartography from "@/components/organisms/FormsJobsCartography.vue";
import FormsIncomeAnalysis from "@/components/organisms/FormsIncomeAnalysis.vue";
import Information from "./Information.vue";

export default {
  props: {
    companyId: {
      type: String,
      required: false,
      default: ""
    },
    setLoading: {
      required: true
    }
  },
  components: {
    FormsJobsCartography,
    FormsIncomeAnalysis,
    Information
  },
  async mounted() {
    await this.updateClassification();
    this.settings = localStorage.getItem(`${this.innerCompanyId}-settings`)
      ? JSON.parse(localStorage.getItem(`${this.innerCompanyId}-settings`))
      : [];
  },
  data() {
    return {
      settings: [],
      employeesIds: [],
      innerCompanyId: this.companyId || this.$route.query.companyId,
      usedClassification: "Classification Actuelle"
    };
  },
  methods: {
    async updateClassification() {
      const foundCompany = await this.$store.dispatch("getCompany", this.innerCompanyId);
      this.usedClassification = foundCompany.usedClassification;
    }
  }
};
</script>
