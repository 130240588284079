<template>
  <div>
    <custom-sub-view-title title="Temps et durée de travail" class="mt-16">
      <h2 class="text-gris font-bold mt-10 text-2xl -mb-8">
        Non-cadres
      </h2>
      <div class="flex items-center space-x-9">
        <div class="flex-1">
          <custom-form-input
            inputId="employee-legal-working-time"
            inputLabel="TEMPS DE TRAVAIL MENSUEL"
            v-model="employee.nonExecutiveEmployeeLegalWorkingTime"
            inputPlaceholder="Indiquez le temps de travail"
          />
        </div>
        <div class="flex-1">
          <custom-form-input
            inputId="employee-day-package"
            inputLabel="FORFAIT JOURS SUR L'ANNÉE "
            v-model="employee.nonExecutiveEmployeeDayPackageForTheYear"
            inputPlaceholder="Forfait en jours"
          />
        </div>
        <div class="flex-1">
          <div class="relative h-16 mb-3">
            <label class="absolute text-input-label font-bold text-gris bottom-0">
              MODULATION DU TEMPS DE TRAVAIL (1607 HEURES)
            </label>
          </div>
          <custom-select-input
            id="employee-modulation"
            name="modulation-input"
            :options="[
              { value: 'Oui', key: 'Oui' },
              { value: 'Non', key: 'Non' }
            ]"
            :defaultOption="'Modulation'"
            cssClass="text-input font-normal"
            v-model="employee.nonExecutiveEmployeeModulationOfWorkingTime"
          />
        </div>
      </div>
      <div class="flex items-center space-x-9">
        <div class="flex-1">
          <div class="relative h-16 mb-3">
            <label class="absolute text-input-label font-bold text-gris bottom-0">
              TRAVAIL A TEMPS PARTIEL
            </label>
          </div>
          <custom-select-input
            id="employee-work-time"
            name="work-time-input"
            :options="[
              { value: '100%', key: '100%' },
              { value: '90%', key: '90%' },
              { value: '80%', key: '80%' },
              { value: '50%', key: '50%' }
            ]"
            :defaultOption="'% de présence'"
            cssClass="text-input font-normal"
            v-model="employee.nonExecutiveEmployeePartTimeWork"
          />
        </div>
        <div class="flex-1">
          <div class="relative h-16 mb-3">
            <label class="absolute text-input-label font-bold text-gris bottom-0">
              TRAVAIL EN ÉQUIPES
            </label>
          </div>
          <custom-select-input
            id="employee-team-work"
            name="team-work-input"
            :options="[
              { value: 'Oui', key: 'Oui' },
              { value: 'Non', key: 'Non' }
            ]"
            :defaultOption="'Travail en équipe'"
            cssClass="text-input font-normal"
            v-model="employee.nonExecutiveEmployeeTeamWork"
          />
        </div>
      </div>
    </custom-sub-view-title>
  </div>
</template>

<script>
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";
import CustomFormInput from "@/components/atoms/CustomFormInput.vue";
import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";

export default {
  components: {
    CustomSubViewTitle,
    CustomFormInput,
    CustomSelectInput
  },
  props: {
    employee: {
      required: true
    }
  }
};
</script>
