import { AxiosResponse } from "axios";
import Vue from "vue";
import { Company } from "@/models/Company";
import { User } from "@/models/User";
import { UserResponse } from "@/models/UserResponse";

interface DeleteParams {
  userID: string;
  companyID: string;
}

export default class UsersService {
  register = async (user: User): Promise<void> => Vue.axios.post("users", user).catch(err => err);

  regeneratePasswordRequest = async (user: User): Promise<UserResponse> =>
    Vue.axios
      .put("users/password", user)
      .then((resp: UserResponse) => resp)
      .catch(err => err);

  getFollowedCompanies = async (userID: string): Promise<Company[]> =>
    Vue.axios
      .get(`users/${userID}/companies`)
      .then((resp: AxiosResponse<Company[]>) => resp.data)
      .catch(err => err);

  deleteCompany = async (params: DeleteParams): Promise<UserResponse> =>
    Vue.axios
      .delete(`users/${params.userID}/companies/${params.companyID}`)
      .then((resp: UserResponse) => resp)
      .catch(err => err);

  getNonActiveUsers = async (): Promise<User[]> => {
    const territoryId = localStorage.getItem("user-territory-id") || "";

    return Vue.axios
      .get(`territories/${territoryId}/non-active-users`)
      .then((resp: AxiosResponse<User[]>) => resp.data)
      .catch(err => err);
  };

  activateUser = async (userID: string): Promise<User[]> =>
    Vue.axios
      .put(`users/${userID}/activate-user`)
      .then((resp: AxiosResponse<User[]>) => resp.data)
      .catch(err => err);

  deleteUser = async (userID: string): Promise<User[]> =>
    Vue.axios
      .delete(`users/${userID}/delete`)
      .then((resp: AxiosResponse<User[]>) => resp.data)
      .catch(err => err);
}
