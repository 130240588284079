import CompanyService from "@/services/CompanyService";

import { ProfessionalFamily } from "@/models/ProfessionalFamily";
import { User } from "@/models/User";
import { EmployeesByProfessionalFamilyFilter } from "@/models/EmployeesByProfessionalFamilyFilter";
import { Company } from "@/models/Company";
import { UpdateEmployeeComment } from "@/models/UpdateEmployeeComment";
import { Employee } from "@/models/Employee";

const companyService = new CompanyService();

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = {
  selectedCompanyId: localStorage.getItem("selected-company-id") || ""
};

const getters = {
  selectedCompanyId(): string {
    return state.selectedCompanyId;
  }
};

const actions = {
  getProfessionalFamilies(): Promise<ProfessionalFamily[]> {
    return companyService.getProfessionalFamilies();
  },

  async getProfessionalFamiliesEmployees(
    _: any,
    data: {
      id: string;
      settings: EmployeesByProfessionalFamilyFilter;
    }
  ): Promise<ProfessionalFamily[]> {
    return companyService.getProfessionalFamiliesEmployees(data.id, data.settings);
  },
  getCompany(_: any, id: string): Promise<Company> {
    return companyService.getCompany(id);
  },
  createCompany(_: any, company: FormData): Promise<Company> {
    return companyService.createCompany(company);
  },

  updateCompany(
    _: any,
    data: {
      companyId: string;
      company: FormData;
    }
  ): Promise<Company> {
    return companyService.updateCompany(data.companyId, data.company);
  },

  updateEmployeeCurrentSituationComment(
    _: any,
    data: {
      companyId: string;
      employeeID: string;
      updateComment: UpdateEmployeeComment;
    }
  ): Promise<Employee> {
    return companyService.updateEmployeeCurrentSituationComment(
      data.companyId,
      data.employeeID,
      data.updateComment
    );
  },

  updateEmployeeRecommendationComment(
    _: any,
    data: {
      companyId: string;
      employeeID: string;
      updateComment: UpdateEmployeeComment;
    }
  ): Promise<Employee> {
    return companyService.updateEmployeeRecommendationComment(
      data.companyId,
      data.employeeID,
      data.updateComment
    );
  },
  getNotAllowedUsers(_: any, companyId: string): Promise<User[]> {
    return companyService.getNotAllowedUsers(companyId);
  },

  shareCompany(_: any, params: { userID: string; companyID: string }): Promise<void> {
    return companyService.shareCompany(params);
  },

  async getCompanyProfessionalFamilies(_: any, companyId: string): Promise<ProfessionalFamily[]> {
    return companyService.getCompanyProfessionalFamilies(companyId);
  },

  async updateCompanyAlerts(_: any, companyId: string): Promise<Employee[]> {
    return companyService.updateCompanyAlerts(companyId);
  }
};

const mutations = {
  selectCompanyId(state: any, companyId: string): void {
    state.selectedCompanyId = companyId;
    localStorage.setItem("selected-company-id", companyId);
  }
};

export default {
  getters,
  actions,
  mutations
};
