import AuthService from "@/services/AuthService";
import { AuthState } from "@/models/authState";
import { LoginResponse } from "@/models/LoginResponse";
import { User } from "@/models/User";

const authService = new AuthService();

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = {
  token: localStorage.getItem("user-token") || "",
  firstname: localStorage.getItem("user-firstname") || "",
  lastname: localStorage.getItem("user-lastname") || "",
  userId: localStorage.getItem("user-id") || "",
  isAdmin: localStorage.getItem("is-admin") || "",
  status: ""
};

const getters = {
  isAuthenticated(): boolean {
    return !!state.token;
  },
  authStatus(): string {
    return state.status;
  },
  firstname(): string {
    return state.firstname;
  },
  lastname(): string {
    return state.lastname;
  },
  isAdmin(): string {
    return state.isAdmin;
  }
};

const actions = {
  authRequest({ commit }: any, user: User) {
    commit("authRequest");
    return authService.authRequest(user).then((resp: LoginResponse) => {
      commit("authSuccess", resp.data);
      state.firstname = resp.data.user.firstname || "";
      state.lastname = resp.data.user.lastname || "";
      state.userId = resp.data.user.id || "";
    });
  },

  disconnect({ commit }: any) {
    commit("authLogout");
    authService.logout();
    state.token = "";
    state.firstname = "";
    state.lastname = "";
    state.userId = "";
  }
};

const mutations = {
  authRequest(state: AuthState): void {
    state.status = "loading";
  },
  authSuccess(state: AuthState, resp: any): void {
    state.status = "success";
    state.token = resp.token;
    state.hasLoadedOnce = true;
  },
  authError(state: AuthState): void {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  authLogout(state: AuthState): void {
    state.token = "";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
