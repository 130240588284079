<template>
  <div class="w-file-upload">
    <file-upload-modal
      :modal-title="inBoxText"
      modal-subtitle="Importer un fichier depuis votre ordinateur.
            Celui-ci doit correspondre au modèle fourni sinon il risque de ne pas être supporté"
      v-if="isOpenModal"
      :file="file ? file : ''"
      @close="isOpenModal = false"
      @file-uploaded="sendFile"
    />
    <label class="text-input-label font-bold text-gris "> {{ title }}</label>
    <div class="h-file-upload bg-secondary mt-3 p-3 rounded cursor-pointer" @click="openModal()">
      <div
        :class="[
          'transition duration-350',
          'border',
          'border-dashed',
          'border-gris-moyen',
          'rounded',
          'h-full',
          'flex flex-col',
          'justify-center',
          'hover:bg-gris-moyen',
          'hover:bg-opacity-30',
          { 'space-y-4': file }
        ]"
      >
        <img class="h-6 mb-4" :src="$asset('icons/upload-icon.svg')" alt="" />
        <div v-if="file">
          <p class="font-normal text-sm text-gris text-center">
            Actuellement le fichier
          </p>
          <p class="font-normal text-sm text-gris font-bold mx-4 truncate w-42 text-center">
            {{ file.name }}
          </p>
          <p class="font-normal text-sm text-gris text-center">
            est utilisé
          </p>
        </div>
        <p class="font-normal text-sm text-gris-moyen text-center mx-5" v-else>{{ inBoxText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploadModal from "@/components/molecules/FileUploadModal.vue";

export default {
  components: {
    FileUploadModal
  },
  props: {
    title: {
      type: String,
      required: false
    },
    inBoxText: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      isOpenModal: false,
      file: null
    };
  },
  methods: {
    openModal() {
      this.isOpenModal = true;
    },
    sendFile(file) {
      this.file = file;
      this.$emit("file-uploaded", file);
    }
  }
};
</script>
