import { saveAs } from "file-saver";
import domtoimage from "dom-to-image-chrome-fix";

export function saveTable(node: Element, filename: string): void {
  const now = Date.now();
  node.setAttribute("style", "padding:2px;padding-bottom:32px;padding-right:32px");
  domtoimage.toBlob(node).then(blob => {
    saveAs(blob, `${filename}-${now}.png`);
    node.setAttribute("style", "padding:0px");
  });
}

export function checkFieldValidity(input: string): string {
  if (input) return input;
  return "N/A";
}

export const toastMessagesTranslation = {
  createCompany: `l'entreprise a été créée avec succès`,
  updateCompany: `l'entreprise a été mis-à-jour avec succès`
};
