<template>
  <div class="flex">
    <div v-for="(element, index) in innerElements" class="flex" :key="element.id">
      <div v-if="index !== 0" class="flex self-center border-b-2 border-gris-moyen px-4 mr-4" />
      <div class="flex self-center cursor-pointer" @click="redirect(element.path)">
        <img
          class="h-sm mr-3 rounded-icon"
          :src="
            isNotActive(element.path)
              ? $asset(`icons/disabled-${element.imgSource}`)
              : $asset(`icons/${element.imgSource}`)
          "
          :alt="element.altSource"
        />
        <h1
          class="text-gris self-center mr-4 text-navbar font-bold"
          :class="{ 'text-gris-moyen': isNotActive(element.path) }"
        >
          {{ element.name }}
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    elements: { required: true },
    activePath: { required: true },
    companyId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      innerElements: this.elements,
      curentPath: this.activePath
    };
  },
  watch: {
    $route(to) {
      this.curentPath = to.path;
    }
  },
  methods: {
    redirect(path) {
      if (this.$route.path !== path) {
        if (this.companyId) {
          this.$router.push({ path, query: { companyId: this.companyId } });
        } else {
          this.$router.push(path);
        }
      }
    },
    isNotActive(path) {
      return !this.curentPath.includes(path);
    }
  }
};
</script>
