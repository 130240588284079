import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";

import store from "../store";

import FollowedCompanies from "../components/pages/home/sub-views/FollowedCompanies.vue";
import MyTerritory from "../components/pages/home/sub-views/MyTerritory.vue";
import Parameters from "../components/pages/home/sub-views/Parameters.vue";

import Login from "../components/pages/Login.vue";
import Home from "../components/pages/home/Home.vue";
import Register from "../components/pages/Register.vue";
import Welcome from "../components/pages/Welcome.vue";
import Forms from "../components/pages/forms/Forms.vue";
import Company from "../components/pages/forms/sub-views/Company.vue";
import Employees from "../components/pages/forms/sub-views/employees/Employees.vue";
import Table from "../components/pages/forms/sub-views/Table.vue";
import Cartography from "../components/pages/forms/sub-views/Cartography.vue";

Vue.use(VueRouter);

const ifNotAuthenticated = (to: Route, from: Route, next: any) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to: Route, from: Route, next: any) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/followed-companies",
    name: "Home",
    component: Home,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "followed-companies",
        component: FollowedCompanies
      },
      {
        path: "my-territory",
        component: MyTerritory
      },
      {
        path: "parameters",
        component: Parameters
      }
    ]
  },
  {
    path: "/forms",
    redirect: "/forms/company",
    name: "Forms",
    component: Forms,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "company",
        component: Company
      },
      {
        path: "employees",
        component: Employees
      },
      {
        path: "table",
        component: Table
      },
      {
        path: "cartography",
        component: Cartography
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Welcome
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
