<template>
  <div class="mt-4">
    <custom-sub-view-title title="Minimums conventionnels" class="mb-9" />
    <new-conventional-minimums class="w-9/12 ml-8" />
  </div>
</template>

<script>
import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";
import NewConventionalMinimums from "@/components/molecules/NewConventionalMinimums.vue";

export default {
  components: {
    NewConventionalMinimums,
    CustomSubViewTitle
  }
};
</script>
