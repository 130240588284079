import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import user from "./modules/user";
import company from "./modules/company";
import employee from "./modules/employee";
import employeeRecommendation from "./modules/employeeRecommendation";
import territories from "./modules/territories";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    company,
    territories,
    employee,
    employeeRecommendation
  }
});
