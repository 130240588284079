<template>
  <div class="bg-gris-clair">
    <div class="min-h-screen flex items-center justify-center">
      <div class="bg-couleur-blanc rounded-lg max-w-md w-full px-20 py-8">
        <div>
          <div class="flex items-center justify-center">
            <img class="h-lg" :src="$asset('icons/logo-uimm.svg')" alt="UIMM LOGO" />
          </div>
          <h2 class="mt-10 mb-2 text-center text-2xl font-bold text-gris">
            Bienvenue !
          </h2>
        </div>
        <p class="mt-10 mb-10 text-center text-sm text-gris">
          Bienvenue sur l'outils de cartographie des emplois de l'UIMM.
        </p>
        <p class="mb-16 text-center text-sm text-gris">
          Remplissez le formulaire d'inscription pour envoyer votre demande d'inscription.
        </p>
        <div class="flex flex-col items-center">
          <div>
            <button
              id="validate-btn"
              type="submit"
              class="submit-button bg-gris text-md"
              @click="goToRegisterPage"
            >
              INSCRIPTION
            </button>
          </div>
          <div>
            <a class="font-light text-xs text-gris underline cursor-pointer" @click="goToLoginPage">
              Déjà un compte ? Se connecter
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToLoginPage() {
      this.$router.push("/login");
    },
    goToRegisterPage() {
      this.$router.push("/register");
    }
  }
};
</script>
