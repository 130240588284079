<template>
  <div>
    <custom-sub-view-title title="Données salarié" class="mt-16">
      <div class="flex items-center space-x-9">
        <div class="flex-1">
          <custom-form-input
            inputId="employee-matricule"
            inputLabel="MATRICULE"
            v-model="employee.registrationNumber"
            inputPlaceholder="000000"
          />
        </div>
        <div class="flex-1">
          <custom-form-input
            inputId="employee-name"
            inputLabel="NOM DU SALARIÉ"
            v-model="employee.lastName"
            inputPlaceholder="Nom"
            :isPassword="true"
          />
        </div>
        <div class="flex-1">
          <custom-form-input
            inputId="employee-surname"
            inputLabel="PRÉNOM DU SALARIÉ"
            v-model="employee.firstName"
            inputPlaceholder="Prénom"
            :isPassword="true"
          />
        </div>
      </div>

      <div class="flex items-center space-x-9">
        <div class="flex-1">
          <div class="relative h-16 mb-3">
            <label class="absolute text-input-label font-bold text-gris bottom-0">
              DATE DE NAISSANCE
            </label>
          </div>
          <custom-select-input-date
            id="employee-birthdate"
            name="birthdate-input"
            v-model="employee.birthDate"
          />
        </div>
        <div class="flex-1">
          <div class="relative h-16 mb-3">
            <label class="absolute text-input-label font-bold text-gris bottom-0">
              SEXE
            </label>
          </div>
          <custom-select-input
            id="employee-gender"
            name="gender-input"
            :options="[
              { value: 'F', key: 'Femme' },
              { value: 'H', key: 'Homme' },
              { value: 'O', key: 'Autre' }
            ]"
            :defaultOption="'Genre'"
            cssClass="text-input font-normal"
            v-model="employee.gender"
          />
        </div>
        <div class="flex-1"></div>
      </div>

      <div class="flex items-center space-x-9">
        <div class="flex-1">
          <div class="relative h-16 mb-3">
            <label class="absolute text-input-label font-bold text-gris bottom-0">
              DATE D'EMBAUCHE
            </label>
          </div>
          <custom-select-input-date
            id="employee-hire-date"
            name="employee-hire-date-input"
            v-model="employee.dateOfHire"
          />
        </div>
        <div class="flex-1">
          <div class="relative h-16 mb-3">
            <label class="absolute text-input-label font-bold text-gris bottom-0">
              DATE D’ANCIENNETÉ DANS LE POSTE
            </label>
          </div>
          <custom-select-input-date
            id="employee-seniority-date"
            name="employee-seniority-date-input"
            v-model="employee.seniorityDateInTheJob"
          />
        </div>
        <div class="flex-1">
          <div class="relative h-16 mb-3">
            <label class="absolute text-input-label font-bold text-gris bottom-0">
              CONTRAT
            </label>
          </div>
          <custom-select-input
            id="employee-contract"
            name="contract-input"
            :options="[
              { value: 'CDD', key: 'CDD' },
              { value: 'CDI', key: 'CDI' },
              { value: 'Alternance', key: 'Alternance' }
            ]"
            :defaultOption="'Contrat'"
            cssClass="text-input font-normal"
            v-model="employee.contractType"
          />
        </div>
      </div>

      <div class="flex items-center space-x-9">
        <div class="flex-1">
          <div class="relative h-16 mb-3">
            <label class="absolute text-input-label font-bold text-gris bottom-0">
              CSP
            </label>
          </div>
          <custom-select-input
            v-if="employee.socioProfessionalCategory"
            id="employee-csp"
            name="csp-input"
            :options="$employeeCsp"
            :defaultOption="'Sélectionnez un statut'"
            cssClass="text-input font-normal"
            v-model="employee.socioProfessionalCategory.name"
          />
        </div>
        <div class="flex-1">
          <custom-form-input
            inputId="employee-last-diploma"
            inputLabel="DERNIER DIPLÔME OBTENU"
            v-model="employee.lastDiploma"
            inputPlaceholder="Indiquez un titre ou un diplôme"
          />
        </div>
        <div class="flex-1">
          <custom-form-input
            inputId="employee-cqpm-category"
            inputLabel="CQPM OBTENU AVEC CATÉGORIE "
            v-model="employee.cqpmCategory"
            inputPlaceholder="Indiquez l’intitulé et la catégorie A, B, C, C*, D"
          />
        </div>
      </div>
    </custom-sub-view-title>
  </div>
</template>

<script>
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";
import CustomFormInput from "@/components/atoms/CustomFormInput.vue";
import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";
import CustomSelectInputDate from "@/components/atoms/CustomSelectInputDate.vue";

export default {
  components: {
    CustomSubViewTitle,
    CustomFormInput,
    CustomSelectInputDate,
    CustomSelectInput
  },
  props: {
    employee: {
      required: true
    }
  },
  data() {
    return {
      actualEmployee: {
        lastName: this.employee.lastName || "",
        firstName: this.employee.firstName || "",
        registrationNumber: this.employee.registrationNumber || "",
        birthDate: this.employee.birthDate || null,
        gender: this.employee.gender || "",
        seniorityDateInTheJob: this.employee.seniorityDateInTheJob || null,
        dateOfHire: this.employee.dateOfHire || null,
        contractType: this.employee.contractType || "",
        cqpmCategory: this.employee.cqpmCategory || "",
        lastDiploma: this.employee.lastDiploma || "",
        socioProfessionalCategory: this.employee.socioProfessionalCategory || ""
      },
      errors: []
    };
  },
  watch: {
    employee: {
      deep: true,
      handler() {
        this.actualEmployee = this.$props.employee;
      }
    }
  }
};
</script>
