<template>
  <div>
    <div id="Min-Conventionnels-Non-Cadres">
      <custom-sub-view-title title="Minimums conventionnels non cadres" class="mb-9" />
      <non-executive-conventional-minimums class="w-7/12 ml-8" />
    </div>
  </div>
</template>

<script>
import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";
import NonExecutiveConventionalMinimums from "@/components/molecules/NonExecutiveConventionalMinimums.vue";

export default {
  components: {
    CustomSubViewTitle,
    NonExecutiveConventionalMinimums
  }
};
</script>
