<template>
  <div class="flex mb-3">
    <div class="flex-1 mr-6">
      <custom-select-input
        :name="service"
        id="choose-service"
        :options="serviceOptions"
        value=""
        v-model="service"
        cssClass="h-9 text-input font-normal"
      />
    </div>
    <div class="flex-1">
      <img
        class="mb-1 cursor-pointer"
        :src="$asset('icons/delete-button.svg')"
        alt="delete-service"
        @click="deleteService"
      />
    </div>
  </div>
</template>

<script>
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";

export default {
  components: { CustomSelectInput },
  props: {
    //:TODO props mutation to fix
    service: {
      type: String,
      required: true,
      default: ""
    },
    services: {
      type: Array,
      required: true
    }
  },

  mounted() {
    this.serviceOptions = this.services
      .filter(service => service !== null)
      .map(service => ({ value: service, key: service }));

    if (this.services.length > 0) {
      this.serviceOptions.unshift({ key: "Tout", value: "Tout" });
    }
    this.serviceOptions.push({ key: "Autre", value: "Autre" });

    if (this.service === "") {
      this.service = this.serviceOptions[0].value;
    }
  },
  data() {
    return {
      serviceOptions: []
    };
  },
  watch: {
    service(newService) {
      this.$emit("change-service", newService);
    }
  },
  methods: {
    deleteService() {
      this.$emit("delete-service", this.service);
    }
  }
};
</script>
