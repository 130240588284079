<template>
  <div>
    <div
      v-if="error"
      class="border text-xs border-error-red text-error-red px-2 py-1 rounded relative mb-2"
      role="alert"
    >
      <span class="block sm:inline"> {{ error }} </span>
    </div>

    <custom-sub-view-title title="Paramètres" />

    <div class="flex items-center space-x-16">
      <div class="flex-1">
        <div class="relative h-16 mb-3">
          <label
            for="professional-family"
            class="absolute text-input-label font-bold text-gris bottom-0"
          >
            FAMILLE PROFESSIONNELLE
          </label>
        </div>
      </div>
      <div class="flex-1">
        <div class="relative h-16 mb-3">
          <label for="services-list" class="absolute text-input-label font-bold text-gris bottom-0">
            LISTE DES SERVICES / SECTEURS / ATELIERS / ILOTS
          </label>
        </div>
      </div>
      <div class="flex-1"></div>
    </div>

    <professional-family-parameters
      v-for="(addedProfessionalFamily, index) in professionalFamilies"
      :key="`${index}-${addedProfessionalFamily.name}`"
      :professionalFamily="addedProfessionalFamily"
      :professionalFamiliesOptions="professionalFamiliesOptions"
      @delete-professional-family="deleteProfessionalFamily(index)"
      @update-professional-family="updateProfessionalFamily($event, index)"
    />

    <div class="mt-2">
      <custom-button
        id="add-professional-family"
        name="AJOUTER UNE FAMILLE"
        :imgSource="$asset('icons/plus-icon.svg')"
        @onClick="addProfessionalFamily"
      />
    </div>
    <div class="mt-2 float-right">
      <custom-button
        id="apply-settings"
        name="APPLIQUER"
        :disabled="isApplyButtonDisabled"
        @onClick="applySettings"
      />
    </div>
  </div>
</template>
<script>
import ProfessionalFamilyParameters from "@/components/molecules/ProfessionalFamilyParameters.vue";
import CustomButton from "@/components/atoms/CustomButton.vue";
import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";

export default {
  components: {
    ProfessionalFamilyParameters,
    CustomButton,
    CustomSubViewTitle
  },
  data() {
    return {
      professionalFamiliesOptions: [],
      professionalFamilies: [],
      companyId: this.$route.query.companyId,
      isApplyButtonDisabled: false,
      error: null
    };
  },
  async mounted() {
    await this.getProfessionalFamilies();
    this.professionalFamilies =
      this.companyId && localStorage.getItem(`${this.companyId}-settings`)
        ? JSON.parse(localStorage.getItem(`${this.companyId}-settings`))
        : [];
    if (this.professionalFamilies.length === 0) {
      this.addProfessionalFamily();
    }
    this.updateApplyButtonState();
  },
  methods: {
    async getProfessionalFamilies() {
      if (this.companyId) {
        this.professionalFamiliesOptions = await this.$store
          .dispatch("getCompanyProfessionalFamilies", this.companyId)
          .catch(() => {
            this.error = "L'entreprise n'existe pas.";
          });
      }

      const servicesList = this.professionalFamiliesOptions.map(option => option.services);

      this.professionalFamiliesOptions = this.professionalFamiliesOptions.filter(
        professionalFamily => professionalFamily?.name !== null
      );

      this.professionalFamiliesOptions.unshift({
        name: "Tout",
        services: [].concat(...servicesList)
      });
      this.professionalFamiliesOptions.push({
        name: "Autre",
        services: []
      });
    },
    addProfessionalFamily() {
      this.professionalFamilies.push({
        name: "",
        services: []
      });

      this.updateApplyButtonState();
    },
    deleteProfessionalFamily(index) {
      this.professionalFamilies.splice(index, 1);
      this.updateApplyButtonState();
    },
    updateProfessionalFamily(professionalFamily, index) {
      this.professionalFamilies[index] = professionalFamily;
      this.updateApplyButtonState();
    },
    applySettings() {
      this.$emit("apply-settings", [...this.professionalFamilies]);
    },
    updateApplyButtonState() {
      if (
        !this.professionalFamilies.length ||
        (this.professionalFamilies.length === 1 &&
          (this.professionalFamilies[0].name === "" ||
            !this.professionalFamilies[0].services.length))
      ) {
        this.isApplyButtonDisabled = true;
      } else {
        this.isApplyButtonDisabled = false;
      }
    }
  }
};
</script>
