var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('label',{staticClass:"block text-xs font-medium text-gris mb-3 font-bold",attrs:{"for":_vm.label}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],class:[
      _vm.cssClass,
      'cursor-pointer',
      'custom-input',
      'focus:ring-black',
      { 'text-gris': _vm.inputValue && _vm.inputValue.length },
      { 'text-gris-moyen': !_vm.inputValue || !_vm.inputValue.length }
    ],attrs:{"name":_vm.name,"id":_vm.id},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.inputValue=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{staticClass:"text-gray-500",domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.defaultOption)+" ")]),_vm._l((_vm.options),function(option){return _c('option',{key:option.key,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.key)+" ")])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }