<template>
  <div>
    <forms-cartography-settings @apply-settings="applySettings" />

    <template v-if="settings.length > 0 && usedClassification">
      <forms-jobs-cartography :settings="settings" :usedClassification="usedClassification" />

      <forms-income-analysis
        :setLoading="setLoading"
        :company-id="companyId"
        :settings="settings"
        :usedClassification="usedClassification"
        class="mt-20"
      />
    </template>

    <template v-else>
      <information message="Vous devez d'abord sélectionner des paramètres" />
    </template>
  </div>
</template>

<script>
import FormsCartographySettings from "@/components/organisms/FormsCartographySettings.vue";
import FormsJobsCartography from "@/components/organisms/FormsJobsCartography.vue";
import FormsIncomeAnalysis from "@/components/organisms/FormsIncomeAnalysis.vue";
import Information from "./Information.vue";

export default {
  data() {
    return {
      settings: [],
      companyId: this.$route.query.companyId || "",
      usedClassification: "Classification Actuelle"
    };
  },
  components: {
    FormsCartographySettings,
    FormsJobsCartography,
    FormsIncomeAnalysis,
    Information
  },
  props: {
    setLoading: {
      required: true
    }
  },
  async mounted() {
    await this.updateClassification();
    this.settings = localStorage.getItem(`${this.companyId}-settings`)
      ? JSON.parse(localStorage.getItem(`${this.companyId}-settings`))
      : [];
  },
  methods: {
    applySettings(newSettings) {
      this.settings = newSettings;
      localStorage.setItem(`${this.companyId}-settings`, JSON.stringify(newSettings));
    },
    async updateClassification() {
      const company = await this.$store.dispatch("getCompany", this.companyId);

      this.usedClassification = company.usedClassification;
    }
  }
};
</script>
