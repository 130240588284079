import { AxiosResponse } from "axios";
import Vue from "vue";

import { Company } from "@/models/Company";
import {
  ExecutiveConventionalMinimums,
  ExecutiveConventionalMinimumsDto
} from "@/models/ExecutiveConventionalMinimums";
import {
  NewConventionalMinimums,
  UpdateNewConventionalMinimum,
  NewConventionalMinimumsDto
} from "@/models/NewConventionalMinimums";
import {
  NonExecutiveConventionalMinimums,
  NonExecutiveConventionalMinimumsDto
} from "@/models/NonExecutiveConventionalMinimums";
import { Territory } from "@/models/Territory";

export default class TerritoriesService {
  getTerritoryCompanies = (): Promise<Company[]> => {
    const territoryId = localStorage.getItem("user-territory-id") || "";

    return Vue.axios
      .get<Company[]>(`territories/${territoryId}/companies`)
      .then((res: AxiosResponse<Company[]>) => res.data)
      .catch(err => err);
  };

  getTerritories = (): Promise<Territory[]> =>
    Vue.axios
      .get<Territory[]>("territories")
      .then((resp: AxiosResponse<Territory[]>): Territory[] => resp.data)
      .catch(err => err);

  getNonExecutiveConventionalMinimums = async (): Promise<NonExecutiveConventionalMinimums[]> => {
    try {
      const territoryId = localStorage.getItem("user-territory-id") || "";

      const executiveConventionalMinimums = await Vue.axios.get<NonExecutiveConventionalMinimums[]>(
        `territories/${territoryId}/non-executive-conventional-minimums`
      );
      return executiveConventionalMinimums.data;
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  updateNonExecutiveConventionalMinimums = async (
    minimum: NonExecutiveConventionalMinimumsDto
  ): Promise<NonExecutiveConventionalMinimumsDto> => {
    try {
      const territoryId = localStorage.getItem("user-territory-id") || "";

      const executiveConventionalMinimum = await Vue.axios.put<NonExecutiveConventionalMinimumsDto>(
        `territories/${territoryId}/non-executive-conventional-minimums`,
        minimum
      );
      return executiveConventionalMinimum.data;
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  getExecutiveConventionalMinimums = async (): Promise<ExecutiveConventionalMinimums[]> => {
    try {
      const territoryId = localStorage.getItem("user-territory-id") || "";

      const executiveConventionalMinimums = await Vue.axios.get<ExecutiveConventionalMinimums[]>(
        `territories/${territoryId}/executive-conventional-minimums`
      );
      return executiveConventionalMinimums.data;
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  updateExecutiveConventionalMinimums = async (
    minimum: ExecutiveConventionalMinimumsDto
  ): Promise<ExecutiveConventionalMinimumsDto> => {
    try {
      const territoryId = localStorage.getItem("user-territory-id") || "";

      const executiveConventionalMinimum = await Vue.axios.put<ExecutiveConventionalMinimumsDto>(
        `territories/${territoryId}/executive-conventional-minimums`,
        minimum
      );
      return executiveConventionalMinimum.data;
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  getNewConventionalMinimums = async (): Promise<NewConventionalMinimums[]> => {
    try {
      const territoryId = localStorage.getItem("user-territory-id") || "";

      const newConventionalMinimums = await Vue.axios.get<NewConventionalMinimums[]>(
        `territories/${territoryId}/new-conventional-minimums`
      );
      return newConventionalMinimums.data;
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  updateNewConventionalMinimums = async (
    minimum: UpdateNewConventionalMinimum
  ): Promise<NewConventionalMinimumsDto> => {
    try {
      const territoryId = localStorage.getItem("user-territory-id") || "";

      const newConventionalMinimum = await Vue.axios.put<NewConventionalMinimumsDto>(
        `territories/${territoryId}/new-conventional-minimums`,
        minimum
      );
      return newConventionalMinimum.data;
    } catch (e) {
      console.log(e);
      return e;
    }
  };
}
