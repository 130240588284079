<template>
  <div class="flex space-x-9 mb-8">
    <div class="w-input">
      <custom-select-input
        :name="innerProfessionalFamily.name"
        id="choose-professional-family"
        :options="innerProfessionalFamiliesNamesOptions"
        value=""
        cssClass="h-9 text-input font-normal"
        v-model="innerProfessionalFamilyName"
      />
    </div>
    <div class="flex flex-1 flex-col">
      <service-parameters
        v-for="(service, index) in innerProfessionalFamily.services"
        :key="`${index}-${service}`"
        :service="service || ''"
        :services="getServiceOptions(innerProfessionalFamily.name) || []"
        @delete-service="deleteService(index)"
        @change-service="changeService($event, index)"
      />
      <custom-button
        id="add-service-button"
        name="AJOUTER UN ILOT"
        :imgSource="$asset('icons/plus-icon.svg')"
        :disabled="!innerProfessionalFamilyName"
        @onClick="addService"
      />
    </div>
  </div>
</template>
<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";
import ServiceParameters from "@/components/molecules/ServiceParameters.vue";

export default {
  components: { CustomSelectInput, ServiceParameters, CustomButton },
  props: {
    professionalFamily: {
      type: Object,
      required: true,
      default() {
        return {
          name: "",
          services: []
        };
      }
    },
    professionalFamiliesOptions: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      innerProfessionalFamily: this.professionalFamily,
      innerProfessionalFamiliesOptions: this.professionalFamiliesOptions || [],
      innerProfessionalFamilyName: this.professionalFamily.name || ""
    };
  },
  watch: {
    innerProfessionalFamilyName(newName) {
      this.innerProfessionalFamily.name = newName;
      this.innerProfessionalFamily.services = [];
      this.updateProfessionalFamilyContent();
    }
  },
  computed: {
    innerProfessionalFamiliesNamesOptions() {
      return this.innerProfessionalFamiliesOptions.map(professionalFamily => ({
        value: professionalFamily.name,
        key: professionalFamily.name
      }));
    }
  },
  methods: {
    addService() {
      this.innerProfessionalFamily.services.push("");
      this.updateProfessionalFamilyContent();
    },
    deleteService(index) {
      this.innerProfessionalFamily.services.splice(index, 1);

      if (this.innerProfessionalFamily.services?.length) {
        return this.updateProfessionalFamilyContent();
      }

      this.$emit("delete-professional-family");
    },
    changeService(newService, serviceIndex) {
      this.innerProfessionalFamily.services[serviceIndex] = newService;
      this.updateProfessionalFamilyContent();
    },
    updateProfessionalFamilyContent() {
      this.$emit("update-professional-family", {
        name: this.innerProfessionalFamily.name,
        services: [...this.innerProfessionalFamily.services]
      });
    },
    getServiceOptions(professionalFamilyName) {
      return this.innerProfessionalFamiliesOptions.filter(
        professionalFamily => professionalFamily.name === professionalFamilyName
      )[0]?.services;
    }
  }
};
</script>
