<template>
  <main
    class="toast fixed top-0 left-0 z-20 translate-x-full shadow-lg  w-full text-sm pointer-events-auto bg-clip-padding bg-green-400 rounded-xl"
    id="successToast"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    data-mdb-autohide="false"
    ref="successToast"
  >
    <h1 class="message text-center p-2 marker:rounded-b-lg break-words text-white text-lg">
      {{ toastInfos.message }}
    </h1>
  </main>
</template>
<script type="module">
export default {
  props: {
    toastInfos: {
      required: false,
      default: { message: "success !", isShowed: false }
    }
  },
  mounted() {
    const sucessToast = this.$refs.successToast;
    if (sucessToast) {
      sucessToast.onanimationend = () => {
        this.$props.toastInfos.isShowed = false;
      };
    }
  }
};
</script>
<style lang="css" scoped>
.toast {
  animation-name: animateSubmitToast;
  animation-duration: 8s;
}
@keyframes animateSubmitToast {
  0% {
    transform: translateX(100%);
    opacity: 1;
  }
  5% {
    transform: translateX(0);
    opacity: 1;
  }
  90% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
</style>
