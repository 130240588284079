<template>
  <div>
    <table aria-describedby="minimumsConventionnel" class="minimums-conventionnel-non-cadre-table">
      <tr class="text-center font-bold border-b-4 border-gris-moyen">
        <th scope="position"><strong>Position</strong></th>
        <th scope="echelon"><strong>Echelon</strong></th>
        <th scope="coefficient"><strong>Coefficient</strong></th>
        <th scope="minimum"><strong>Minimum conventionnel annuel</strong></th>
      </tr>
      <tbody
        v-for="minimumsConventionnel in conventionalMinimums"
        :key="minimumsConventionnel.position"
      >
        <tr
          v-for="(convention, index) in minimumsConventionnel.conventionAnnuel"
          :key="convention.echelon"
        >
          <td
            v-if="index === 0"
            :rowspan="minimumsConventionnel.conventionAnnuel.length"
            class="text-center"
          >
            {{ romanValues.get(minimumsConventionnel.position) }}
          </td>
          <td>{{ convertEchelon(convention.echelon) }}</td>
          <td>{{ convention.coefficient }}</td>
          <td>
            <input
              type="number"
              v-model="convention.minimumsConventionnelsAnnuel"
              class="custom-input-grey-placeholder focus:ring-black mc-input"
              @keyup="
                updateNonExecutiveConventionalMinimums(
                  minimumsConventionnel.position,
                  convention.echelon,
                  convention.coefficient,
                  convention.minimumsConventionnelsAnnuel
                )
              "
            />
          </td>
        </tr>
        <div></div>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      romanValues: new Map([
        [1, "I"],
        [2, "II"],
        [3, "III"],
        [4, "IV"],
        [5, "V"]
      ]),
      nonExecutiveConventionalMinimums: []
    };
  },
  computed: {
    conventionalMinimums() {
      return this.nonExecutiveConventionalMinimums;
    }
  },
  methods: {
    async getNonExecutiveConventionalMinimums() {
      this.nonExecutiveConventionalMinimums = await this.$store.dispatch(
        "getNonExecutiveConventionalMinimums"
      );
    },
    updateNonExecutiveConventionalMinimums: async function(
      position,
      echelon,
      coefficient,
      minimum
    ) {
      const minimums = {
        position,
        coefficient,
        echelon,
        minimumsConventionnelsAnnuel: minimum ? minimum : "0"
      };

      await this.$store.dispatch("updateNonExecutiveConventionalMinimums", minimums);
    },
    convertEchelon(input) {
      if (input === 4) return "";
      return input;
    }
  },
  async mounted() {
    await this.getNonExecutiveConventionalMinimums();
  }
};
</script>

<style>
.minimums-conventionnel-non-cadre-table {
  @apply w-full;
  @apply border-collapse;
}

.minimums-conventionnel-non-cadre-table td,
th {
  @apply p-2;
  @apply border-2;
  @apply border-gris-moyen;
}

.mc-input::-webkit-outer-spin-button,
.mc-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  @apply m-0;
}
</style>
