<template>
  <div class="bg-gris-clair">
    <register-confirmation-modal
      v-if="showConfirmationModal"
      @close="showConfirmationModal = false"
    />
    <div class="min-h-screen flex items-center justify-center">
      <div class="bg-couleur-blanc rounded-lg max-w-md w-full px-20 py-4">
        <div>
          <div class="flex items-center justify-center">
            <img class="h-lg" :src="$asset('icons/logo-uimm.svg')" alt="UIMM LOGO" />
          </div>
          <h2 class="mt-4 mb-2 text-center text-2xl font-bold text-gris">
            Inscription
          </h2>
          <div
            v-show="registerError.length"
            class="border text-xs border-error-red text-error-red px-2 py-1 rounded relative"
            role="alert"
          >
            <span class="block sm:inline"> {{ registerError }} </span>
          </div>
        </div>
        <form class="mt-8 space-y-6" @submit.prevent="register">
          <div>
            <div class="flex items-center justify-between space-x-3">
              <div class="flex-1">
                <label for="lastname" class="block text-xs font-medium text-gris mb-3">
                  NOM
                </label>
                <input
                  id="lastname"
                  v-model="lastname"
                  :class="['custom-input', 'focus:ring-black', 'px-3 py-2 h-8']"
                />
              </div>
              <div class="flex-1">
                <label for="firstname" class="block text-xs font-medium text-gris mb-3">
                  PRÉNOM
                </label>
                <input
                  id="firstname"
                  v-model="firstname"
                  :class="['custom-input', 'focus:ring-black', 'px-3 py-2 h-8']"
                />
              </div>
            </div>
            <br />
            <div>
              <custom-select-input
                label="TERRITOIRE"
                name="territories"
                id="territories"
                :options="territories"
                defaultOption="Sélectionnez un territoire de rattachement"
                v-model="territory"
              />
            </div>
            <br />
            <div>
              <label for="password" class="block text-xs font-medium text-gris">
                ADRESSE MAIL
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autocomplete="email"
                required=""
                v-model="email"
                class="custom-input focus:ring-black"
              />
            </div>
            <br />
            <div>
              <label for="password" class="block text-xs font-medium text-gris">
                MOT DE PASSE
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autocomplete=""
                required=""
                v-model="password"
                :class="[
                  'custom-input',
                  'focus:ring-black',
                  { 'border-error-red border': passwordsPatternError }
                ]"
              />
              <span v-show="passwordsPatternError" class="text-error-red text-xs">
                le mot de passe doit avoir une longueur minimale de 8.
              </span>
            </div>
            <br />
            <div>
              <label for="password-validation" class="block text-xs font-medium text-gris">
                MOT DE PASSE - VALIDATION
              </label>
              <input
                id="password-validation"
                name="password-validation"
                type="password"
                autocomplete=""
                required=""
                v-model="passwordValidation"
                :class="[
                  'custom-input',
                  'focus:ring-black',
                  { 'border-error-red border': passwordsMatchError }
                ]"
              />
              <span v-show="passwordsMatchError" class="text-error-red text-xs">
                les mots de passes doivent être identiques.
              </span>
            </div>
          </div>
          <div class="flex flex-col items-center">
            <div>
              <button
                id="validate-btn"
                type="submit"
                :class="[
                  'submit-button bg-gris text-md',
                  'disabled:bg-button-disabled',
                  'disabled:cursor-default'
                ]"
                :disabled="validationButtonDisactivated"
              >
                VALIDER
              </button>
            </div>
            <div>
              <a
                class="font-light text-xs text-gris underline cursor-pointer"
                @click="goToLoginPage"
              >
                Déjà un compte ? Se connecter
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterConfirmationModal from "@/components/molecules/RegisterConfirmationModal.vue";
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";

export default {
  components: {
    RegisterConfirmationModal,
    CustomSelectInput
  },
  data() {
    return {
      lastname: "",
      firstname: "",
      email: "",
      password: "",
      passwordValidation: "",
      territory: "",
      territories: [],
      registerError: "",
      passwordsPatternError: false,
      passwordsMatchError: false,
      showConfirmationModal: false
    };
  },
  async mounted() {
    await this.getTerritories();
  },
  methods: {
    async getTerritories() {
      await this.$store
        .dispatch("getTerritories")
        .then(allTerritories => {
          this.territories = allTerritories
            .map(territory => ({
              key: territory.name,
              value: territory.name
            }))
            .sort();
        })
        .catch(err => {
          console.log(err);
        });
    },
    async register() {
      this.emptyErrors();

      if (this.validateForm()) {
        const { firstname } = this;
        const { lastname } = this;
        const { email } = this;
        const { password } = this;
        const { territory } = this;

        await this.$store
          .dispatch("register", {
            firstname,
            lastname,
            email,
            password,
            territory
          })
          .then(() => {
            this.showConfirmationModal = true;
            this.cleanInputs();
          })
          .catch(err => {
            if (err.response) {
              if (err.response.status === 400) {
                this.registerError =
                  "Cette adresse email existe déjà pour le territoire selectionné.";
              } else if (err.response.status === 422) {
                this.registerError = "Veuillez insérer une adresse email valide.";
              } else {
                this.registerError =
                  "Une erreur technique s'est produit, veuillez réessayer ultérieurement.";
              }
            }
          });
      }
    },
    validateForm() {
      let isValid = true;

      if (this.password !== this.passwordValidation) {
        this.passwordsMatchError = true;
        isValid = false;
      }

      if (this.password.length < 8) {
        this.passwordsPatternError = true;
        isValid = false;
      }

      return isValid;
    },
    emptyErrors() {
      this.registerError = "";
      this.passwordsPatternError = false;
      this.passwordsMatchError = false;
    },
    cleanInputs() {
      this.lastname = "";
      this.firstname = "";
      this.email = "";
      this.password = "";
      this.passwordValidation = "";
      this.territory = "";
      this.territories = [];
    },
    goToLoginPage() {
      this.$router.push("/login");
    }
  },
  computed: {
    validationButtonDisactivated() {
      return (
        this.territory === "" ||
        this.email === "" ||
        this.password === "" ||
        this.passwordValidation === "" ||
        this.firstname === "" ||
        this.lastname === ""
      );
    }
  }
};
</script>
