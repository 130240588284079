<template>
  <div :class="['flex items-center', disabled ? 'cursor-not-allowed' : 'cursor-pointer']">
    <div
      :id="id"
      @click="onClick"
      :class="[
        'submit-button',
        'hover:shadow-lg',
        backgroundColor,
        padding,
        { 'pointer-events-none bg-button-disabled': disabled },
        { 'bg-gris': !disabled }
      ]"
    >
      <img :src="imgSource" :alt="altSource" />
      <div v-if="imgSource && name.length" class="ml-3"></div>
      <div>
        <p :class="[fontSize, 'h-6']">{{ name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: false,
      default: ""
    },
    id: {
      type: String,
      required: true
    },
    imgSource: {
      type: String,
      required: false
    },
    altSource: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "bg-gris"
    },
    padding: {
      type: String,
      required: false,
      default: ""
    },
    fontSize: {
      type: String,
      required: false,
      default: ""
    }
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    }
  },
  data() {
    return {
      innerDisabled: !!this.disabled
    };
  }
};
</script>
<style scoped>
.submit-button {
  @apply flex;
  @apply items-center;
  @apply py-1;
  @apply border;
  @apply border-transparent;
  @apply text-xl;
  @apply font-medium;
  @apply rounded;
  @apply text-couleur-blanc;
  @apply shadow-md;
}
</style>
