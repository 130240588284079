<template>
  <div class="p-subview">
    <div id="AcountsManagement" v-if="this.nonActiveUsers.length > 0" class="mb-16">
      <custom-sub-view-title title="Gestion des comptes" class="mb-9" />
      <div v-for="(user, index) in nonActiveUsers" :key="user.id">
        <div v-if="index !== 0" class="flex self-center border-b-2 border-gris-moyen px-2 mb-5" />
        <div class="flex justify-between mb-5">
          <p class="self-center">
            <strong class="pr-2"> {{ user.firstname }} {{ user.lastname }} </strong>
            avec l'adresse mail <strong class="px-2">{{ user.email }}</strong>
            demande à accéder à la plateforme
          </p>
          <div class="flex flex-nowrap">
            <custom-button
              :id="user.id"
              class="mr-5"
              name="ACCEPTER"
              @onClick="acceptUser(user.id)"
            />
            <custom-button :id="user.id" name="REFUSER" @onClick="deleteUser(user.id)" />
          </div>
        </div>
      </div>
    </div>
    <custom-sub-tab-navbar :titles="titles" @tab-change="changeTab" />
    <div class="mt-16">
      <actual-classification v-if="activeTab === 'CLASSIFICATION ACTUELLE'" />
      <future-classification v-if="activeTab === 'FUTURE CLASSIFICATION'" />
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";
import CustomSubTabNavbar from "@/components/molecules/CustomSubTabNavbar.vue";
import ActualClassification from "@/components/pages/home/sub-views/conventional-minimums/ActualClassification.vue";
import FutureClassification from "@/components/pages/home/sub-views/conventional-minimums/FutureClassification.vue";

export default {
  components: {
    CustomButton,
    CustomSubViewTitle,
    CustomSubTabNavbar,
    ActualClassification,
    FutureClassification
  },
  data() {
    return {
      nonActiveUsers: [],
      titles: ["CLASSIFICATION ACTUELLE", "FUTURE CLASSIFICATION"],
      activeTab: "CLASSIFICATION ACTUELLE"
    };
  },
  methods: {
    async getNonActiveUsers() {
      await this.$store
        .dispatch("getNonActiveUsers")
        .then(data => {
          this.nonActiveUsers = data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async acceptUser(userId) {
      await this.$store
        .dispatch("activateUser", userId)
        .then(() => {
          this.removeUserFromList(userId);
        })
        .catch(err => {
          console.log(err);
        });
    },
    async deleteUser(userId) {
      await this.$store
        .dispatch("deleteUser", userId)
        .then(() => {
          this.removeUserFromList(userId);
        })
        .catch(err => {
          console.log(err);
        });
    },
    removeUserFromList(userId) {
      this.nonActiveUsers = this.nonActiveUsers.filter(user => user.id !== userId);
    },
    changeTab(value) {
      this.activeTab = value;
    }
  },
  async mounted() {
    await this.getNonActiveUsers();
  }
};
</script>
