<template>
  <div>
    <custom-sub-view-title title="Données de l'emploi" class="mt-16">
      <div class="flex items-center space-x-9">
        <div class="flex-1">
          <div class="relative h-16 mb-3">
            <label class="absolute text-input-label font-bold text-gris bottom-0">
              FAMILLE PROFESSIONNELLE DE L'EMPLOI
            </label>
          </div>
          <custom-select-input
            v-if="recommendations.jobProfessionalFamily"
            :id="'employee-curent-job-title'"
            :name="'employee-curent-job-title'"
            :options="$professionalFamilies"
            :defaultOption="'Famille professionnelle de l’emploi'"
            cssClass="text-input font-normal"
            v-model="recommendations.jobProfessionalFamily.name"
          />
        </div>
        <div class="flex-1">
          <div class="relative h-16 mb-3">
            <label class="absolute text-input-label font-bold text-gris bottom-0">
              SERVICE / SECTEUR / ATELIER / ILOT
            </label>
          </div>
          <custom-select-input
            v-if="recommendations.service"
            :id="'employee-sector'"
            :name="'employee-sector'"
            :options="servicesOptions"
            :defaultOption="'Service / Secteur / Atelier / Ilot'"
            cssClass="text-input font-normal"
            v-model="recommendations.service.name"
          />
        </div>
        <div class="flex-1">
          <custom-form-input
            inputId="employee-position"
            inputLabel="INTITULÉ D’EMPLOI ACTUEL"
            v-model="recommendations.currentJobTitle"
            inputPlaceholder="Intitulé de l’emploi du salarié"
          />
        </div>
      </div>
    </custom-sub-view-title>

    <custom-sub-view-title title="Classification" class="mt-16">
      <div
        v-if="
          this.employeeContractType !== $SpecificContractType &&
            usedClassification === 'Classification Actuelle'
        "
      >
        <h2 class="text-gris font-bold mt-10 text-2xl -mb-8">
          Non-Cadres
        </h2>
        <div class="flex items-center space-x-9">
          <div class="flex-1">
            <div class="relative h-16 mb-3">
              <label class="absolute text-input-label font-bold text-gris bottom-0">
                NIVEAU
              </label>
            </div>
            <custom-select-input
              :id="'employee-level'"
              :name="'employee-level'"
              :options="$employeeLevels"
              :defaultOption="'Sélectionnez un niveau'"
              cssClass="text-input font-normal"
              v-model="recommendations.nonExecutiveLevel"
            />
          </div>
          <div class="flex-1">
            <div class="relative h-16 mb-3">
              <label class="absolute text-input-label font-bold text-gris bottom-0">
                ECHELON
              </label>
            </div>
            <custom-select-input
              :id="'employee-salary-grade'"
              :name="'employee-salary-grade'"
              :options="$employeeSalaryGrade"
              :defaultOption="'0'"
              cssClass="text-input font-normal"
              v-model="recommendations.nonExecutiveSalaryGrade"
            />
          </div>
          <div class="flex-1">
            <div class="relative h-16 mb-3">
              <label class="absolute text-input-label font-bold text-gris bottom-0">
                COEF
              </label>
            </div>
            <custom-select-input
              v-if="recommendations.coef"
              :id="'employee-coefficient'"
              :name="'employee-coefficient'"
              :options="$employeeCoefs"
              :defaultOption="'Sélectionnez un coefficient'"
              cssClass="text-input font-normal"
              v-model="recommendations.coef.value"
            />
          </div>
        </div>
      </div>
      <div
        v-if="
          this.employeeContractType !== $SpecificContractType &&
            usedClassification !== 'Classification Actuelle'
        "
      >
        <h2 class="text-gris font-bold mt-10 text-2xl">
          Cadres et non cadres
        </h2>
        <div class="flex items-center space-x-9">
          <div class="flex-1">
            <div class="relative h-8 mb-3">
              <label class="absolute text-input-label font-bold text-gris bottom-0">
                GROUPE - CLASSE D'EMPLOIS
              </label>
            </div>
            <custom-select-input
              id="employee-jobGroup"
              name="job-class-input"
              :options="$employeeJobGroups"
              cssClass="text-input font-normal"
              v-model="recommendations.jobGroupAndClass"
            />
          </div>
          <div class="flex-1"></div>
          <div class="flex-1"></div>
        </div>
      </div>
      <div v-if="this.employeeContractType === $SpecificContractType">
        <h2 class="text-gris font-bold mt-10 text-2xl -mb-8">
          Alternants
        </h2>
        <div class="flex items-center space-x-9">
          <div class="flex-1">
            <div class="relative h-16 mb-3">
              <label class="absolute text-input-label font-bold text-gris bottom-0">
                GROUPE
              </label>
            </div>
            <custom-select-input
              :id="'employee-student-apprentice-group'"
              :name="'employee-student-apprentice-group'"
              :options="$employeeGroups"
              :defaultOption="'Sélectionnez un groupe'"
              cssClass="text-input font-normal"
              v-model="recommendations.studentApprenticeGroup"
            />
          </div>
          <div class="flex-1"></div>
          <div class="flex-1"></div>
        </div>
      </div>
    </custom-sub-view-title>

    <custom-sub-view-title
      title="Éléments de rémunération"
      class="mt-16"
      v-if="this.employeeContractType !== $SpecificContractType"
    >
      <h2 class="text-gris font-bold mt-10 text-2xl">
        Non cadres
      </h2>
      <div class="flex items-center space-x-9">
        <div class="flex-1">
          <custom-form-price-input
            inputId="employee-base-salary"
            inputLabel="SALAIRE DE BASE ANNUEL HORS PRIME ET HEURES SUPPLÉMENTAIRES"
            v-model="recommendations.nonExecutiveEmployeeBaseSalary"
            inputPlaceholder="Indiquez le salaire annuel brut"
          />
        </div>
        <div class="flex-1">
          <custom-form-price-input
            inputId="employee-base-salary"
            inputLabel="PRIMES VERSÉES ANNUELLEMENT HORS PRIME D'ANCIENNETÉ"
            v-model="recommendations.nonExecutiveEmployeeYearlyBonusWithoutSeniorityBonus"
            inputPlaceholder="Indiquez la prime annuelle"
          />
        </div>
        <div class="flex-1">
          <custom-form-price-input
            inputId="employee-base-salary"
            inputLabel="PRIME D'ANCIENNETÉ"
            v-model="recommendations.nonExecutiveEmployeeSeniorityBonus"
            inputPlaceholder="Indiquez la prime d'ancienneté"
          />
        </div>
      </div>
      <div class="flex items-center space-x-9 mt-4">
        <div class="flex-1">
          <custom-form-price-input
            inputId="employee-base-salary"
            inputLabel="
              SALAIRE BRUT ANNUEL TOUT COMPRIS EXCLUE PRIME D'ANCIENNETÉ ET PRIME EXCEPTIONNELLE
              "
            v-model="recommendations.grossSalaryAllIncludedWithoutSeniorityAndExceptionalBonus"
            inputPlaceholder="Indiquez le salaire annuel brut"
          />
        </div>
        <div class="flex-1"></div>
        <div class="flex-1"></div>
      </div>
    </custom-sub-view-title>
  </div>
</template>

<script>
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";
import CustomFormInput from "@/components/atoms/CustomFormInput.vue";
import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";
import CustomFormPriceInput from "@/components/atoms/CustomFormPriceInput.vue";

export default {
  components: {
    CustomSubViewTitle,
    CustomSelectInput,
    CustomFormInput,
    CustomFormPriceInput
  },
  async mounted() {
    await this.getEmployeeRecommendation();
  },
  props: {
    employeeRecommendationId: {
      required: true
    },
    company: {
      required: true
    },
    employeeContractType: {
      required: true
    },
    setLoading: {
      required: true
    }
  },
  data() {
    return {
      errors: [],
      recommendations: {},
      usedClassification: this.company.usedClassification
    };
  },
  methods: {
    async getEmployeeRecommendation() {
      if (this.employeeRecommendationId) {
        this.setLoading && this.setLoading(true);
        const employeeRecommendation = await this.$store.dispatch(
          "getOneEmployeeRecommendation",
          this.employeeRecommendationId
        );
        this.recommendations = employeeRecommendation;
        this.initEmployeeRecommendationObject(employeeRecommendation);
        this.setLoading && this.setLoading(false);
      } else {
        this.initEmployeeRecommendationObject({});
        this.setLoading && this.setLoading(false);
      }
    },
    initEmployeeRecommendationObject(recommendationData) {
      this.recommendations = {
        ...recommendationData,
        coef: { value: recommendationData.coef?.value } || { value: null },
        jobProfessionalFamily: recommendationData.jobProfessionalFamily || { name: null },
        service: recommendationData.service || { name: null }
      };
    }
  },
  computed: {
    servicesOptions() {
      return this.company.services
        ? this.company.services.map(service => ({ value: service.name, key: service.name }))
        : [];
    }
  },
  watch: {
    recommendations: {
      deep: true,
      handler() {
        this.$emit("recommendationsChanged", this.recommendations);
      }
    },
    employeeRecommendationId: {
      async handler() {
        await this.getEmployeeRecommendation();
      }
    }
  }
};
</script>
