var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-sub-view-title',{staticClass:"mt-16",attrs:{"title":"Données salarié"}},[_c('div',{staticClass:"flex items-center space-x-9"},[_c('div',{staticClass:"flex-1"},[_c('custom-form-input',{attrs:{"inputId":"employee-matricule","inputLabel":"MATRICULE","inputPlaceholder":"000000"},model:{value:(_vm.employee.registrationNumber),callback:function ($$v) {_vm.$set(_vm.employee, "registrationNumber", $$v)},expression:"employee.registrationNumber"}})],1),_c('div',{staticClass:"flex-1"},[_c('custom-form-input',{attrs:{"inputId":"employee-name","inputLabel":"NOM DU SALARIÉ","inputPlaceholder":"Nom","isPassword":true},model:{value:(_vm.employee.lastName),callback:function ($$v) {_vm.$set(_vm.employee, "lastName", $$v)},expression:"employee.lastName"}})],1),_c('div',{staticClass:"flex-1"},[_c('custom-form-input',{attrs:{"inputId":"employee-surname","inputLabel":"PRÉNOM DU SALARIÉ","inputPlaceholder":"Prénom","isPassword":true},model:{value:(_vm.employee.firstName),callback:function ($$v) {_vm.$set(_vm.employee, "firstName", $$v)},expression:"employee.firstName"}})],1)]),_c('div',{staticClass:"flex items-center space-x-9"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"relative h-16 mb-3"},[_c('label',{staticClass:"absolute text-input-label font-bold text-gris bottom-0"},[_vm._v(" DATE DE NAISSANCE ")])]),_c('custom-select-input-date',{attrs:{"id":"employee-birthdate","name":"birthdate-input"},model:{value:(_vm.employee.birthDate),callback:function ($$v) {_vm.$set(_vm.employee, "birthDate", $$v)},expression:"employee.birthDate"}})],1),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"relative h-16 mb-3"},[_c('label',{staticClass:"absolute text-input-label font-bold text-gris bottom-0"},[_vm._v(" SEXE ")])]),_c('custom-select-input',{attrs:{"id":"employee-gender","name":"gender-input","options":[
            { value: 'F', key: 'Femme' },
            { value: 'H', key: 'Homme' },
            { value: 'O', key: 'Autre' }
          ],"defaultOption":'Genre',"cssClass":"text-input font-normal"},model:{value:(_vm.employee.gender),callback:function ($$v) {_vm.$set(_vm.employee, "gender", $$v)},expression:"employee.gender"}})],1),_c('div',{staticClass:"flex-1"})]),_c('div',{staticClass:"flex items-center space-x-9"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"relative h-16 mb-3"},[_c('label',{staticClass:"absolute text-input-label font-bold text-gris bottom-0"},[_vm._v(" DATE D'EMBAUCHE ")])]),_c('custom-select-input-date',{attrs:{"id":"employee-hire-date","name":"employee-hire-date-input"},model:{value:(_vm.employee.dateOfHire),callback:function ($$v) {_vm.$set(_vm.employee, "dateOfHire", $$v)},expression:"employee.dateOfHire"}})],1),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"relative h-16 mb-3"},[_c('label',{staticClass:"absolute text-input-label font-bold text-gris bottom-0"},[_vm._v(" DATE D’ANCIENNETÉ DANS LE POSTE ")])]),_c('custom-select-input-date',{attrs:{"id":"employee-seniority-date","name":"employee-seniority-date-input"},model:{value:(_vm.employee.seniorityDateInTheJob),callback:function ($$v) {_vm.$set(_vm.employee, "seniorityDateInTheJob", $$v)},expression:"employee.seniorityDateInTheJob"}})],1),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"relative h-16 mb-3"},[_c('label',{staticClass:"absolute text-input-label font-bold text-gris bottom-0"},[_vm._v(" CONTRAT ")])]),_c('custom-select-input',{attrs:{"id":"employee-contract","name":"contract-input","options":[
            { value: 'CDD', key: 'CDD' },
            { value: 'CDI', key: 'CDI' },
            { value: 'Alternance', key: 'Alternance' }
          ],"defaultOption":'Contrat',"cssClass":"text-input font-normal"},model:{value:(_vm.employee.contractType),callback:function ($$v) {_vm.$set(_vm.employee, "contractType", $$v)},expression:"employee.contractType"}})],1)]),_c('div',{staticClass:"flex items-center space-x-9"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"relative h-16 mb-3"},[_c('label',{staticClass:"absolute text-input-label font-bold text-gris bottom-0"},[_vm._v(" CSP ")])]),(_vm.employee.socioProfessionalCategory)?_c('custom-select-input',{attrs:{"id":"employee-csp","name":"csp-input","options":_vm.$employeeCsp,"defaultOption":'Sélectionnez un statut',"cssClass":"text-input font-normal"},model:{value:(_vm.employee.socioProfessionalCategory.name),callback:function ($$v) {_vm.$set(_vm.employee.socioProfessionalCategory, "name", $$v)},expression:"employee.socioProfessionalCategory.name"}}):_vm._e()],1),_c('div',{staticClass:"flex-1"},[_c('custom-form-input',{attrs:{"inputId":"employee-last-diploma","inputLabel":"DERNIER DIPLÔME OBTENU","inputPlaceholder":"Indiquez un titre ou un diplôme"},model:{value:(_vm.employee.lastDiploma),callback:function ($$v) {_vm.$set(_vm.employee, "lastDiploma", $$v)},expression:"employee.lastDiploma"}})],1),_c('div',{staticClass:"flex-1"},[_c('custom-form-input',{attrs:{"inputId":"employee-cqpm-category","inputLabel":"CQPM OBTENU AVEC CATÉGORIE ","inputPlaceholder":"Indiquez l’intitulé et la catégorie A, B, C, C*, D"},model:{value:(_vm.employee.cqpmCategory),callback:function ($$v) {_vm.$set(_vm.employee, "cqpmCategory", $$v)},expression:"employee.cqpmCategory"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }