var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'relative',
    'rounded-sm',
    'p-4 m-4 pt-8',
    'shadow-lg',
    'w-followed-companies-card',
    'h-followed-companies-card',
    _vm.bgColor
  ]},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hidePopup),expression:"hidePopup"}]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPopup),expression:"showPopup"}],staticClass:"small-popup"},[_c('div',{staticClass:"z-20 absolute right-2",on:{"click":function($event){$event.stopPropagation();return _vm.togglePopup($event)}}},[_c('img',{staticClass:"h-xs",attrs:{"src":_vm.$asset('icons/burger-icon.svg'),"alt":"burger-menu"}})]),_c('div',{staticClass:"relative"},[_c('div',{on:{"click":function($event){return _vm.shareCard()}}},[_vm._v("Partager")]),_c('div',{on:{"click":function($event){return _vm.deleteCard()}}},[_vm._v("Supprimer de la liste")])])])]),_c('div',{staticClass:"cursor-pointer z-20 absolute right-2 top-6 p-2",on:{"click":function($event){$event.stopPropagation();return _vm.togglePopup($event)}}},[_c('img',{staticClass:"h-xs",attrs:{"src":_vm.$asset('icons/burger-icon.svg'),"alt":"burger-menu"}})]),_c('div',{staticClass:"relative"},[_c('div',{staticClass:"bg-white h-28 w-36 m-auto rounded flex p-1.5"},[_c('img',{staticClass:"max-h-full m-auto",attrs:{"src":_vm.imgSource,"alt":_vm.altSource}})]),_c('h1',{staticClass:"text-center font-bold mt-5 text-h3"},[_vm._v(_vm._s(_vm.name))]),_c('h3',{staticClass:"text-center font-light m-3 mt-4"},[_vm._v(_vm._s(_vm.type))]),_c('button',{class:['redirect-button', 'hover:shadow-lg'],attrs:{"id":"goToFile"},on:{"click":_vm.redirectToFile}},[_vm._v(" Voir la fiche ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }