<template>
  <div class="z-50 bg-gris bg-opacity-75 transition-opacity fixed table top-0 left-0 w-full h-full">
    <div class="table-cell align-middle">
      <div class="bg-gris-clair rounded-sm sm:p-6 sm:pb-4 w-modal m-auto">
        <!--CONTENT-->
        <div class="text-right w-full flex">
          <img
            :src="$asset('icons/close-icon.svg')"
            class="ml-auto"
            alt="close"
            height="18"
            width="18"
            @click="onClose"
          />
        </div>
        <div class="px-28">
          <h3 class="text-center text-md font-bold text-gris">
            Etes vous sûr de vouloir supprimer cette fiche de votre liste ?
          </h3>
          <h3 class="text-center text-md text-rouge mt-5">
            {{ errorMessage }}
          </h3>
          <div class="flex flex-col items-center mt-5">
            <div>
              <button
                id="validate-btn"
                type="submit"
                class="submit-button bg-gris text-md"
                @click="deleteCard"
              >
                VALIDER
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companyId: { required: true }
  },
  data() {
    return {
      errorMessage:
        "Attention! Si vous êtes le dernier consultant à avoir accès à cette fiche entreprise, elle sera définitivement supprimée.",
      userId: localStorage.getItem("user-id")
    };
  },

  methods: {
    onClose() {
      this.$emit("close");
    },
    async deleteCard() {
      await this.$store.dispatch("deleteCompany", {
        userID: this.userId,
        companyID: this.companyId
      });
      this.$emit("close", this.companyId);
    }
  }
};
</script>
