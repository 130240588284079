<template>
  <div v-if="foundCompanies && foundCompanies.length > 0">
    <share-company
      v-if="showShareOption"
      :companyId="selectedCompanyId"
      @close="showShareOption = false"
    />
    <delete-company
      v-if="showDeleteRequest"
      :companyId="selectedCompanyId"
      @close="closeDeleteCompanyModal"
    />
    <div class="flex justify-between px-14 pt-8 pb-4">
      <div class="flex items-center text-gris">
        <h4 class="pr-4 font-bold">FILTRER PAR EFFECTIF</h4>
        <multi-range-slider @update="updateRange" />
      </div>
      <input
        id="searchBar"
        type="search"
        v-model="searchedCompany"
        placeholder="Chercher une entreprise"
        :class="[
          'w-96',
          'rounded',
          'bg-couleur-blanc',
          'focus:outline-none focus:ring-0',
          'focus:ring-offset-0',
          'focus:ring-gris-moyen focus:border-gris-moyen',
          'font-medium',
          'border-gris-moyen',
          'text-black',
          'shadow-md'
        ]"
      />
    </div>

    <div class="flex flex-wrap px-10">
      <div v-for="company in filteredCompanies" :key="company.id">
        <custom-company-card
          :name="company.name"
          :type="getCompanyType(company.employeesNumber)"
          :id="company.id"
          :imgSource="company.logo || $asset('icons/default_company_logo.png')"
          altSource="Company's logo"
          :backgroundColor="company.color"
          @share="shareCompany"
          @delete="deleteCompany"
        />
      </div>
    </div>
  </div>
  <div class="pt-8" v-else>
    <information message="Vous ne suivez actuellement aucune entreprise" />
  </div>
</template>

<script>
import CustomCompanyCard from "@/components/molecules/CustomCompanyCard.vue";
import MultiRangeSlider from "@/components/atoms/MultiRangeSlider.vue";
import ShareCompany from "@/components/molecules/ShareCompany.vue";
import DeleteCompany from "@/components/molecules/DeleteCompany.vue";
import Information from "@/components/organisms/Information.vue";

export default {
  components: {
    CustomCompanyCard,
    ShareCompany,
    DeleteCompany,
    MultiRangeSlider,
    Information
  },
  data() {
    return {
      showShareOption: false,
      showDeleteRequest: false,
      searchedCompany: "",
      selectedCompanyId: "",
      workforceRange: [0, 300],
      foundCompanies: [],
      colors: [
        "bg-violet-fonc-30",
        "bg-turquoise-30",
        "bg-couleur-rouge-30",
        "bg-couleur-vert-30",
        "bg-couleur-jaune-30",
        "bg-couleur-rose-30",
        "bg-couleur-bleu-30",
        "bg-couleur-orange-30"
      ],
      userId: ""
    };
  },
  async mounted() {
    this.userId = localStorage.getItem("user-id");
    await this.getCompanies();
  },
  methods: {
    async getCompanies() {
      this.foundCompanies = await this.$store.dispatch("getCompanies", this.userId);
      this.foundCompanies = this.assignColors(this.foundCompanies);
    },
    shareCompany(companyId) {
      this.selectedCompanyId = companyId;
      this.showShareOption = true;
    },
    deleteCompany(companyId) {
      this.selectedCompanyId = companyId;
      this.showDeleteRequest = true;
    },
    updateRange(startValue, endValue) {
      this.workforceRange = [startValue, endValue];
    },
    closeDeleteCompanyModal(companyID) {
      if (!companyID) {
        this.showDeleteRequest = false;
      } else {
        this.foundCompanies = this.foundCompanies.filter(card => card.id !== companyID);
        this.showDeleteRequest = false;
      }
    },
    getCompanyType(employeesNumber) {
      let type;
      switch (true) {
        case employeesNumber < 10:
          type = "TPE";
          break;
        case employeesNumber >= 10 && employeesNumber < 250:
          type = "PME";
          break;
        case employeesNumber >= 250 && employeesNumber < 5000:
          type = "ETI";
          break;
        default:
          type = "GE";
          break;
      }
      return `${type} (${employeesNumber})`;
    },
    assignColors(companies) {
      companies.forEach(company => {
        const currentColor = this.colors.shift();
        this.colors.push(currentColor);
        company.color = currentColor; // eslint-disable-line no-param-reassign
      });

      return companies;
    }
  },
  computed: {
    filteredCompanies() {
      let filteredCompanies = [];

      if (this.workforceRange[1] !== 300) {
        filteredCompanies = this.foundCompanies.filter(
          card =>
            card.employeesNumber >= this.workforceRange[0] &&
            card.employeesNumber <= this.workforceRange[1]
        );
      } else {
        filteredCompanies = this.foundCompanies.filter(
          card => card.employeesNumber >= this.workforceRange[0]
        );
      }

      if (this.searchedCompany !== "") {
        filteredCompanies = filteredCompanies.filter(company => {
          const cardName = company.name.toLowerCase();
          const searchKeyword = this.searchedCompany.toLowerCase();

          return cardName.includes(searchKeyword);
        });
      }

      return filteredCompanies;
    }
  }
};
</script>

<style scoped>
input::placeholder {
  @apply text-gris-moyen;
}
</style>
