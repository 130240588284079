import { AxiosResponse } from "axios";
import Vue from "vue";

import { ProfessionalFamily } from "@/models/ProfessionalFamily";
import { User } from "@/models/User";
import { Company } from "@/models/Company";
import { EmployeesByProfessionalFamilyFilter } from "@/models/EmployeesByProfessionalFamilyFilter";
import { Employee } from "@/models/Employee";
import { UpdateEmployeeComment } from "@/models/UpdateEmployeeComment";

export default class CompanyService {
  getCompany = async (id: string): Promise<Company> =>
    Vue.axios.get(`companies/${id}`).then((res: AxiosResponse<Company>) => res.data);

  getProfessionalFamilies = async (): Promise<ProfessionalFamily[]> =>
    Vue.axios
      .get<ProfessionalFamily[]>("professional-families")
      .then((res: AxiosResponse<ProfessionalFamily[]>): ProfessionalFamily[] => res.data)
      .catch(err => err);

  getProfessionalFamiliesEmployees = async (
    id: string,
    settings: EmployeesByProfessionalFamilyFilter
  ): Promise<ProfessionalFamily[]> =>
    Vue.axios
      .post<ProfessionalFamily[]>(`companies/${id}/professional-families-employees`, settings)
      .then((res: AxiosResponse<ProfessionalFamily[]>): ProfessionalFamily[] => res.data)
      .catch(err => err);

  createCompany = async (company: FormData): Promise<Company> =>
    Vue.axios.post("companies", company, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

  updateCompany = async (companyID: string, company: FormData): Promise<Company> =>
    Vue.axios.put(`companies/${companyID}`, company, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

  updateEmployeeCurrentSituationComment = (
    companyID: string,
    employeeID: string,
    updateComment: UpdateEmployeeComment
  ): Promise<Employee> =>
    Vue.axios
      .post(
        `companies/${companyID}/employees/${employeeID}/actual-situation-comment`,
        updateComment
      )
      .then((resp: AxiosResponse<Employee>) => resp.data)
      .catch(err => err);

  updateEmployeeRecommendationComment = (
    companyID: string,
    employeeID: string,
    updateComment: UpdateEmployeeComment
  ): Promise<Employee> =>
    Vue.axios
      .post(`companies/${companyID}/employees/${employeeID}/recommendation-comment`, updateComment)
      .then((resp: AxiosResponse<Employee>) => resp.data)
      .catch(err => err);

  updateCompanyAlerts = async (companyId: string): Promise<Employee[]> =>
    Vue.axios
      .put<Employee[]>(`companies/${companyId}/alerts`)
      .then((res: AxiosResponse<Employee[]>): Employee[] => res.data);

  shareCompany = async (params: { userID: string; companyID: string }): Promise<void> =>
    Vue.axios.put(`companies/${params.companyID}/share/${params.userID}`);

  getNotAllowedUsers = async (companyID: string): Promise<User[]> =>
    Vue.axios
      .get<User[]>(`companies/${companyID}/not-allowed-users`)
      .then((res: AxiosResponse<User[]>): User[] => res.data)
      .catch(err => err);

  getCompanyProfessionalFamilies = async (companyId: string): Promise<ProfessionalFamily[]> =>
    Vue.axios
      .get<ProfessionalFamily[]>(`companies/${companyId}/professional-families`)
      .then((res: AxiosResponse<ProfessionalFamily[]>): ProfessionalFamily[] => res.data);
}
