<template>
  <div class="relative">
    <datetime
      :id="id"
      :placeholder="placeholder"
      :input-class="
        inputValue && inputValue.length
          ? inputClass + ' text-gris'
          : inputClass + ' text-gris-moyen'
      "
      v-model="inputValue"
    >
    </datetime>
    <img class="calendar-icon" :src="$asset('icons/calendar-icon.svg')" alt="calendar-icon" />
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: null
    },
    cssClass: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: "jj/mm/aaaa"
    }
  },
  components: {
    Datetime
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        if (typeof val === "string" && val.length) {
          this.$emit("input", val);
        }
      }
    }
  },
  data() {
    return {
      inputClass: `${"w-full " +
        "block " +
        "border-0 " +
        "bg-secondary " +
        "focus:ring-black " +
        "font-normal " +
        "text-xs text-input " +
        "placeholder-gris-moyen " +
        "h-8 " +
        "rounded "}${this.cssClass}`
    };
  }
};
</script>
<style lang="scss" scoped>
.calendar-icon {
  position: absolute;
  right: 10px;
  bottom: 8px;
  height: 20px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
}
</style>
