<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>

      <div
        class="inline-block align-bottom bg-couleur-blanc rounded-sm text-left
        overflow-hidden shadow-xl transform transition-all
        sm:my-8 sm:align-middle w-file-upload-modal h-file-upload-modal"
      >
        <div class="flex flex-col items-center bg-couleur-blanc sm:p-6 sm:pb-4">
          <!--CONTENT-->
          <div class="text-right w-full flex">
            <img
              :src="$asset('icons/close-icon.svg')"
              class="ml-auto cursor-pointer"
              alt="close"
              height="18"
              width="18"
              @click="close"
            />
          </div>
          <h1 class="font-bold sub-view-title text-sub-view-title">
            {{ innerModalTitle }}
          </h1>
          <p class="mt-4 font-normal text-base text-gris text-center w-file-upload-modal-text">
            {{ innerModalSubtitle }}
          </p>
          <custom-file-input
            :file="innerFile"
            :title="innerModalTitle"
            @file-uploaded="receiveFile"
          />
          <custom-button
            id="validate-file-upload"
            name="VALIDER"
            class="mt-6"
            :disabled="innerFile === ''"
            @onClick="sendFile"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
import CustomFileInput from "@/components/atoms/CustomFileInput.vue";

export default {
  props: {
    modalTitle: {
      type: String,
      required: true
    },
    modalSubtitle: {
      type: String,
      required: true
    },
    file: {
      type: [File, String],
      required: true
    }
  },
  components: {
    CustomButton,
    CustomFileInput
  },
  methods: {
    close() {
      this.$emit("close");
    },
    receiveFile(file) {
      this.innerFile = file;
    },
    sendFile() {
      this.$emit("file-uploaded", this.innerFile);
      this.close();
    }
  },
  data() {
    return {
      innerFile: this.file,
      innerModalTitle: this.modalTitle,
      innerModalSubtitle: this.modalSubtitle
    };
  }
};
</script>
