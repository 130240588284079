<template>
  <main>
    <div class="lds-dual-ring"></div>
  </main>
</template>
<script type="module">
export default {
  props: {
    message: {
      type: String,
      required: false,
      default: "loading"
    }
  }
};
</script>
<style lang="css" scoped>
main {
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
  place-content: center;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0 0 0 / 0.4);
  z-index: 20;
}
.lds-dual-ring {
  @apply inline-block w-24 h-24;
}
.lds-dual-ring:after {
  content: "";
  border-radius: 50%;
  animation: lds-dual-ring 1.2s linear infinite;
  @apply block m-2 border-8 h-20 w-20 border-solid border-white;
  border-color: #fff transparent #fff transparent;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
