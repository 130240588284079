<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="inline-block align-bottom bg-couleur-blanc rounded-sm text-left
        overflow-hidden shadow-xl transform transition-all
        sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-couleur-blanc sm:p-6 sm:pb-4">
          <!--CONTENT-->
          <div class="text-right w-full flex">
            <img
              :src="$asset('icons/close-icon.svg')"
              class="ml-auto"
              alt="close"
              height="18"
              width="18"
              @click="onClose"
            />
          </div>
          <h2 class="text-center text-md font-bold text-gris">
            Réinitialiser votre mot de passe
          </h2>
          <form class="mt-8" @submit.prevent="resetPassword">
            <div>
              <div>
                <custom-select-input
                  label="TERRITOIRE"
                  name="territories"
                  id="territories"
                  :options="territories"
                  defaultOption="Sélectionnez un territoire de rattachement"
                  v-model="territory"
                />
              </div>
              <br />
              <div>
                <input
                  id="recovery-email-address"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required=""
                  v-model="email"
                  class="custom-input focus:ring-black"
                  placeholder="Addresse mail du compte"
                />
              </div>
              <br />
            </div>
            <div class="flex flex-col items-center">
              <div>
                <button
                  id="send-btn"
                  type="submit"
                  :class="[
                    'submit-button bg-gris text-md',
                    'disabled:bg-button-disabled',
                    'disabled:cursor-default'
                  ]"
                  :disabled="territory === '' || email === ''"
                >
                  ENVOYER
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";

export default {
  components: {
    CustomSelectInput
  },
  props: {
    territories: { required: true },
    territory: { required: false },
    email: { required: false }
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    async resetPassword() {
      const { email } = this;
      const { territory } = this;

      await this.$store
        .dispatch("resetPasswordRequest", { email, territory })
        .then(() => {
          this.onClose();
        })
        .catch(err => {
          this.emptyForm();
          console.log(err);
        });
    },
    emptyForm() {
      this.email = "";
      this.territory = "";
    }
  }
};
</script>
