import { AxiosResponse } from "axios";
import Vue from "vue";
import { EmployeeRecommendation } from "@/models/EmployeeRecommendation";
export default class EmployeesService {
  getAllEmployeesRecommendations = (): Promise<EmployeeRecommendation[]> =>
    Vue.axios
      .get("employees-recommendations")
      .then((resp: AxiosResponse<EmployeeRecommendation[]>) => resp.data)
      .catch(err => err);

  getEmployeeRecommendation = (id: string): Promise<EmployeeRecommendation> =>
    Vue.axios
      .get(`employees-recommendations/${id}`)
      .then((resp: AxiosResponse<EmployeeRecommendation>) => resp.data)
      .catch(err => err);
}
