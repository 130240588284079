<template>
  <div>
    <div class="flex relative line-draw-container">
      <div class="flex self-center border-b-2 border-gris-moyen w-full"></div>
      <custom-button
        id="addEmployee"
        name="Ajouter un salarié"
        :imgSource="$asset('icons/plus-icon.svg')"
        altSource="plus-icon"
        class="absolute button-line font-bold"
        @onClick="createEmployee"
      />
    </div>
    <div class="flex flex-col mt-1/4 mb-1/4 p-8">
      <img class="ml-auto mr-auto" :src="$asset('icons/info-icon.svg')" alt="info-icon" />
      <span class="mt-4 ml-auto mr-auto text-gris-moyen text-4xl font-bold">
        Il n'y a pas encore de salarié à afficher
      </span>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";

export default {
  components: {
    CustomButton
  },
  methods: {
    createEmployee() {
      this.$emit("createNewEmployee");
    }
  }
};
</script>

<style lang="scss" scoped>
.button-line {
  top: -16px;
  right: 2.5rem;
}
</style>
