<template>
  <div class="relative">
    <div v-if="inputLabel" class="relative h-16 mb-3">
      <label
        :for="inputId"
        class="absolute text-input-label font-bold text-gris bottom-0 leading-5"
      >
        {{ inputLabel }}
      </label>
    </div>
    <input
      :id="inputId"
      v-model="inputValue"
      class="custom-input-grey-placeholder focus:ring-black"
      :placeholder="inputPlaceholder"
      :type="type"
    />
    <template v-if="isPassword">
      <div @click="updateInputType" class="cursor-pointer">
        <img
          class="eye-icon"
          :src="$asset('icons/eye-icon.svg')"
          alt="eye-icon"
          v-if="inputType === 'password'"
        />
        <img
          class="eye-icon-closed"
          :src="$asset('icons/eye-icon-closed.svg')"
          alt="eye-icon-closed"
          v-if="inputType === 'event'"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    inputLabel: {
      type: String,
      required: false
    },
    inputId: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    inputPlaceholder: {
      type: String,
      required: false
    },
    isPassword: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    type() {
      return this.inputType;
    }
  },
  data() {
    return {
      inputType: ""
    };
  },
  mounted() {
    if (this.isPassword) {
      this.inputType = "password";
    }
  },
  methods: {
    updateInputType() {
      this.inputType = this.inputType === "event" ? "password" : "event";
    }
  }
};
</script>

<style lang="scss" scoped>
.eye-icon-closed {
  position: absolute;
  right: 0.8rem;
  bottom: 6px;
  height: 20px;
  margin: auto;
  font-size: 14px;
}

.eye-icon {
  position: absolute;
  right: 0.8rem;
  bottom: 3px;
  height: 20px;
  margin: auto;
  font-size: 14px;
}
</style>
