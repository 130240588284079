<template>
  <div class="mt-16">
    <basic-modal
      v-if="showModal"
      modal-title="Paramétrer la cartographie"
      @close="changeParamsModalState(false)"
    >
      <current-situation-params-modal
        :show-coef="showCoef"
        :show-salary-grade="showSalaryGrade"
        @close="changeParamsModalState(false)"
        @validate="changeView($event)"
      />
    </basic-modal>

    <div class="mb-4">
      <custom-sub-view-title title="Cartographie des emplois" />
    </div>

    <div class="relative">
      <div class="flex absolute items-center right-0 space-x-2 mb-4">
        <custom-button id="export-in-png" name="PNG" fontSize="text-base" @onClick="exportTable" />
        <custom-button
          v-if="usedClassification === 'Classification Actuelle'"
          id="table-settings"
          name=""
          :imgSource="$asset('icons/table-settings-icon.svg')"
          @onClick="changeParamsModalState(true)"
        />
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="relative overflow-scroll mt-12 text-gris">
        <div ref="exportedTable">
          <table class="w-min-full bg-couleur-blanc" aria-describedby="jobsCartographyTable">
            <thead>
              <tr v-if="professionalFamilies.length">
                <th
                  id="blank-corner-cell"
                  class="border-l-0 border-r-4"
                  :colspan="professionalFamiliesColspan"
                ></th>
                <th
                  v-for="(professionalFamily, professionalFamilyindex) in professionalFamilies"
                  :id="professionalFamily.id"
                  :key="professionalFamilyindex"
                  :colspan="professionalFamily.services.length"
                  class="text-center py-5 font-bold border-2"
                >
                  {{ professionalFamily.name }}
                </th>
              </tr>
              <tr class="border-b-4 border-gris-moyen">
                <th id="level-cell-title" class="border-l-0 py-5 font-bold border-r-4">
                  {{ tableTitle }}
                </th>
                <th
                  v-if="showSalaryGrade"
                  id="grade-cell-title"
                  class="border-l-0 py-5 font-bold border-r-4"
                >
                  Echelon
                </th>
                <th
                  v-if="showCoef"
                  id="coef-cell-title"
                  class="border-l-0 py-5 font-bold border-r-4"
                >
                  Coefficient
                </th>
                <th
                  v-for="(service, serviceIndex) in allServices"
                  :id="serviceIndex"
                  :key="serviceIndex"
                  class="text-center py-5 font-bold"
                >
                  {{ service.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="showSalaryGrade || showCoef">
                <template v-for="(level, levelIndex) in gradesListByLevel">
                  <tr v-for="(coef, grade) in level" :key="levelIndex + '' + grade">
                    <template v-if="grade !== 3 || levelIndex === 'V'">
                      <td
                        v-if="grade === 0"
                        :rowspan="levelIndex === 'V' ? 4 : 3"
                        class="border-2 border-b-4"
                      >
                        {{ levelIndex }}
                      </td>
                      <td
                        v-if="showSalaryGrade"
                        :class="['border-2', { 'border-b-4': grade === 2 }]"
                      >
                        {{ grade !== 3 ? grade + 1 : "" }}
                      </td>
                      <td v-if="showCoef" :class="['border-2', { 'border-b-4': grade === 2 }]">
                        {{ coef }}
                      </td>
                      <td
                        v-for="(service, serviceIndex) in allServices"
                        :key="serviceIndex"
                        :class="['border-2', { 'border-b-4': grade === 2 }]"
                      >
                        <p
                          v-for="(job, jobName) in employeesJobsByLevelAndGrade(
                            service,
                            numericValues.get(levelIndex),
                            grade + 1
                          )"
                          :key="jobName"
                        >
                          ({{ job.length }}) {{ jobName }}
                        </p>
                      </td>
                    </template>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr v-for="(level, levelIndex) in levels" :key="levelIndex">
                  <td class="border-l-0 font-bold border-b-4">{{ level }}</td>
                  <td
                    v-for="(service, serviceIndex) in allServices"
                    :key="serviceIndex"
                    class="border-2 border-b-4"
                  >
                    <p
                      v-for="(job, jobName) in employeesJobsGrouped(service, level)"
                      :key="jobName"
                    >
                      ({{ job.length }}) {{ jobName }}
                    </p>
                  </td>
                </tr>
              </template>
              <tr>
                <td class="font-bold border-l-0 border-r-4">Total effectif</td>
                <td
                  v-if="showSalaryGrade || showCoef"
                  :colspan="showSalaryGrade && showCoef ? 2 : 1"
                ></td>
                <td
                  v-for="(service, serviceIndex) in allServices"
                  :key="serviceIndex"
                  class="text-center font-bold"
                >
                  {{
                    service.employees.filter(
                      employee => employee.level && employee.socioProfessionalCategory
                    ).length
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";
import CustomButton from "../atoms/CustomButton.vue";
import CurrentSituationParamsModal from "@/components/molecules/CurrentSituationParamsModal.vue";
import BasicModal from "@/components/molecules/BasicModal.vue";

import { checkFieldValidity, saveTable } from "@/utils";

export default {
  props: {
    settings: {
      required: true
    },
    recommendation: {
      type: Boolean,
      required: false,
      default: false
    },
    usedClassification: {
      required: true
    }
  },
  components: {
    CustomSubViewTitle,
    CustomButton,
    CurrentSituationParamsModal,
    BasicModal
  },
  data() {
    return {
      showModal: false,
      showSalaryGrade: false,
      showCoef: false,
      innerCompanyId: this.$route.query.companyId,
      employeesIds: [],
      professionalFamilies: [],
      gradesListByLevel: {
        V: [305, 335, 365, 395],
        IV: [255, 270, 285],
        III: [215, 225, 240],
        II: [170, 180, 190],
        I: [140, 145, 155]
      },
      allServices: [],
      numericValues: new Map([
        ["I", "1"],
        ["II", "2"],
        ["III", "3"],
        ["IV", "4"],
        ["V", "5"]
      ])
    };
  },
  watch: {
    async settings() {
      await this.getProfessionalFamiliesEmployees();
    }
  },
  computed: {
    levels() {
      if (this.usedClassification === "Future Classification") {
        return [
          "I - 18",
          "I - 17",
          "H - 16",
          "H - 15",
          "G - 14",
          "G - 13",
          "F - 12",
          "F - 11",
          "E - 10",
          "E - 9",
          "D - 8",
          "D - 7",
          "C - 6",
          "C - 5",
          "B - 4",
          "B - 3",
          "A - 2",
          "A - 1"
        ];
      } else {
        return ["niveau V", "niveau IV", "niveau III", "niveau II", "niveau I"];
      }
    },
    tableTitle() {
      if (this.usedClassification === "Future Classification") {
        return "Groupe - Classe d'emplois";
      } else {
        return "Niveaux de qualification";
      }
    },
    professionalFamiliesColspan() {
      if (this.showSalaryGrade && this.showCoef) {
        return 3;
      } else if (this.showSalaryGrade || this.showCoef) {
        return 2;
      }
      return 1;
    }
  },
  mounted() {
    this.employeesIds = localStorage.getItem(`${this.innerCompanyId}-employees-ids`)
      ? JSON.parse(localStorage.getItem(`${this.innerCompanyId}-employees-ids`))
      : [];
    this.getProfessionalFamiliesEmployees();
  },
  methods: {
    groupArrayBy(array, key) {
      return array.reduce((rv, element) => {
        const current = rv;
        current[element[key]] = current[element[key]] || [];
        current[element[key]].push(element);

        return current;
      }, {});
    },
    async getProfessionalFamiliesEmployees() {
      if (this.innerCompanyId) {
        const allEmployees = await this.$store.dispatch("getManyEmployees", this.innerCompanyId);
        const apprenticeshipEmployeesID = allEmployees
          .filter(employee => employee.contractType === "Alternance")
          .map(employee => employee.id);
        const searchedIds = this.employeesIds.filter(
          id => apprenticeshipEmployeesID.includes(id) === false
        );
        this.professionalFamilies = await this.$store
          .dispatch("getProfessionalFamiliesEmployees", {
            id: this.innerCompanyId,
            settings: {
              employeesIds: searchedIds,
              professionalFamilies: this.settings
            }
          })
          .catch(() => {
            this.error = "L'entreprise n'existe pas.";
          });

        this.professionalFamilies = this.professionalFamilies.filter(
          professionalFamily => professionalFamily.services.length
        );
        if (this.recommendation) {
          this.updateRecommendationEmployees();
        }
        this.allServices = [].concat(
          ...this.professionalFamilies.map(professionalFamily => professionalFamily.services)
        );
      }
    },
    updateRecommendationEmployees() {
      for (const family of this.professionalFamilies) {
        for (const service of family.services) {
          for (const employee of service.employees) {
            employee.level = checkFieldValidity(employee.employeeRecommendation?.nonExecutiveLevel);
            employee.salaryGrade = checkFieldValidity(
              employee.employeeRecommendation?.nonExecutiveSalaryGrade
            );
            employee.jobGroupAndClass = checkFieldValidity(
              employee.employeeRecommendation?.jobGroupAndClass
            );
            if (employee.coef) {
              employee.coef.value = checkFieldValidity(
                employee.employeeRecommendation?.coef?.value
              );
            } else if (employee.employeeRecommendation?.coef) {
              employee.coef = { value: employee.employeeRecommendation?.coef.value };
            }
            employee.index = checkFieldValidity(employee.employeeRecommendation?.executiveIndex);
            employee.position = checkFieldValidity(
              employee.employeeRecommendation?.executivePosition
            );
            employee.currentJobTitle = checkFieldValidity(
              employee.employeeRecommendation?.currentJobTitle
            );
          }
        }
      }
    },
    employeesJobsGrouped(service, input) {
      let allEmployees;
      if (this.usedClassification === "Classification Actuelle") {
        const level = input.split(" ");
        allEmployees = service.employees.filter(
          employee =>
            employee.level === this.numericValues.get(level[1]) &&
            employee.socioProfessionalCategory
        );
      } else {
        allEmployees = service.employees.filter(employee => employee.jobGroupAndClass === input);
      }
      return this.groupArrayBy(allEmployees, "currentJobTitle");
    },
    employeesJobsByLevelAndGrade(service, level, grade) {
      const allEmployees = service.employees.filter(
        employee =>
          employee.level === level &&
          employee.salaryGrade === grade &&
          employee.socioProfessionalCategory
      );
      return this.groupArrayBy(allEmployees, "currentJobTitle");
    },
    exportTable() {
      const node = this.$refs.exportedTable;
      saveTable(node, "jobs-cartography");
    },
    changeParamsModalState(state) {
      this.showModal = state;
    },
    changeView(newValues) {
      this.showSalaryGrade = newValues.showSalaryGrade;
      this.showCoef = newValues.showCoef;
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  border-left: 4px solid;
  @apply border-gris-moyen;

  tr {
    @apply border-gris-moyen;
  }
  td {
    @apply border-gris-moyen;
    @apply border-2;
    @apply px-4;
    @apply leading-9;
  }
  th {
    @apply px-4;

    @apply border-2;
  }
}
</style>
