var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.foundCompanies && _vm.foundCompanies.length > 0)?_c('div',[(_vm.showShareOption)?_c('share-company',{attrs:{"companyId":_vm.selectedCompanyId},on:{"close":function($event){_vm.showShareOption = false}}}):_vm._e(),(_vm.showDeleteRequest)?_c('delete-company',{attrs:{"companyId":_vm.selectedCompanyId},on:{"close":_vm.closeDeleteCompanyModal}}):_vm._e(),_c('div',{staticClass:"flex justify-between px-14 pt-8 pb-4"},[_c('div',{staticClass:"flex items-center text-gris"},[_c('h4',{staticClass:"pr-4 font-bold"},[_vm._v("FILTRER PAR EFFECTIF")]),_c('multi-range-slider',{on:{"update":_vm.updateRange}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchedCompany),expression:"searchedCompany"}],class:[
        'w-96',
        'rounded',
        'bg-couleur-blanc',
        'focus:outline-none focus:ring-0',
        'focus:ring-offset-0',
        'focus:ring-gris-moyen focus:border-gris-moyen',
        'font-medium',
        'border-gris-moyen',
        'text-black',
        'shadow-md'
      ],attrs:{"id":"searchBar","type":"search","placeholder":"Chercher une entreprise"},domProps:{"value":(_vm.searchedCompany)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchedCompany=$event.target.value}}})]),_c('div',{staticClass:"flex flex-wrap px-10"},_vm._l((_vm.filteredCompanies),function(company){return _c('div',{key:company.id},[_c('custom-company-card',{attrs:{"name":company.name,"type":_vm.getCompanyType(company.employeesNumber),"id":company.id,"imgSource":company.logo || _vm.$asset('icons/default_company_logo.png'),"altSource":"Company's logo","backgroundColor":company.color},on:{"share":_vm.shareCompany,"delete":_vm.deleteCompany}})],1)}),0)],1):_c('div',{staticClass:"pt-8"},[_c('information',{attrs:{"message":"Vous ne suivez actuellement aucune entreprise"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }