<template>
  <main class="relative overflow-scroll rounded-lg">
    <custom-loader v-if="loading" />
    <div
      v-if="companyId && error"
      class="
        mx-4
        border
        text-xs
        border-error-red
        text-error-red
        px-2
        py-1
        rounded
        relative
        mb-2
      "
      role="alert"
    >
      <span class="block sm:inline"> {{ error }} </span>
    </div>
    <div class="flex relative button-container">
      <div class="flex self-center border-b-2 border-gris-moyen w-full"></div>
      <custom-button
        id="updateAlerts"
        name="Actualiser les alertes"
        class="absolute button-line font-bold"
        @onClick="updateCompanyAlerts"
      />
    </div>
    <div class="overflow-scroll">
      <div
        v-if="companyId && employees && employees.length > 0"
        class="h-table-container text-gris mt-2"
      >
        <table class="w-table isolate" aria-describedby="employeesTable">
          <thead class="relative z-2">
            <tr class="sticky top-0">
              <th
                :class="['sticky_col0 z-1', 'border-0', 'overflow-auto', 'p-4', 'h-table-header']"
                scope="col"
              >
                <custom-checkbox
                  @change="changeStatusOfAll"
                  :is-checked="employees.length === checkedEmployeesIds.length"
                />
              </th>
              <th
                v-for="(item, index) in headerItems"
                :key="index"
                :class="[
                  'relative',
                  'border-0',
                  'w-table-cell',
                  'break-normal',
                  'text-table-cell-content',
                  'h-table-header',
                  'p-1',
                  'p-b-0',
                  { 'border-r-0': index === headerItems.length - 1 },
                  { 'sticky_col1 z-1': index === 0 }
                ]"
                scope="col"
              >
                <div class="h-full">
                  <div class="flex flex-col justify-between">
                    <div>
                      <span
                        :class="[
                          'float-left',
                          'cursor-pointer',
                          {
                            'text-turquoise':
                              currentHeaderIndexToSortBy === index && sortDirection === 'asc'
                          }
                        ]"
                        @click="sort(index, 'asc')"
                      >
                        &#8595;
                      </span>
                      <span
                        :class="[
                          'float-left',
                          'cursor-pointer',
                          {
                            'text-turquoise':
                              currentHeaderIndexToSortBy === index && sortDirection === 'desc'
                          }
                        ]"
                        @click="sort(index, 'desc')"
                      >
                        &#8593;
                      </span>
                    </div>
                    <div>
                      <p>
                        <strong>{{ item }}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="relative z-1">
            <tr v-for="(employee, outerIndex) in employees" :key="outerIndex">
              <td :class="['sticky_col0 relative z-1', 'border-0', 'overflow-auto', 'p-4']">
                <custom-checkbox
                  @change="changeStatus($event, employee.id)"
                  :is-checked="isIdInArray(employee.id)"
                />
              </td>
              <td
                v-for="(field, index) in employee.fields"
                :key="outerIndex + '' + index"
                :class="[
                  { 'border-2': index !== 0 },
                  'border-gris-moyen',
                  'w-table-cell',
                  {
                    'bg-couleur-orange-30':
                      employee.alerts &&
                      employee.alerts.find(alert => alert.fieldName === employee.fields[index].key)
                  },
                  { 'border-r-0': index === employee.fields.length - 1 },
                  { 'sticky_col1 z-1 border-0': index === 0 }
                ]"
              >
                <custom-table-cell
                  :value="field.value"
                  @edit-infos="editEmployeeInformations(employee.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <information
        v-else-if="companyId && employees && employees.length === 0"
        message="Vous devez d'abord créer des salariés afin d'accéder à cette fonctionnalité"
        class="p-subview"
      />

      <information
        v-else
        message="Veuillez créer une entreprise afin d'accéder à cette fonctionnalité"
        class="p-subview"
      />
    </div>
  </main>
</template>

<script>
import CustomTableCell from "@/components/molecules/CustomTableCell.vue";
import CustomCheckbox from "@/components/atoms/CustomCheckbox.vue";
import CustomButton from "@/components/atoms/CustomButton.vue";
import Information from "@/components/organisms/Information.vue";
import CustomLoader from "@/shared/CustomLoader.vue";

export default {
  components: {
    CustomTableCell,
    CustomCheckbox,
    Information,
    CustomButton,
    CustomLoader
  },
  async mounted() {
    this.loading = true;
    await this.updateClassification();
    await this.getAllEmployees();
    this.checkedEmployeesIds = localStorage.getItem(`${this.companyId}-employees-ids`)
      ? JSON.parse(localStorage.getItem(`${this.companyId}-employees-ids`))
      : [];
  },
  methods: {
    isIdInArray(employeeId) {
      return this.checkedEmployeesIds.indexOf(employeeId) > -1;
    },
    changeStatusOfAll(checked) {
      if (checked) {
        this.checkedEmployeesIds = this.employees.map(employee => employee.id);
      } else {
        this.checkedEmployeesIds = [];
      }
      localStorage.setItem(
        `${this.companyId}-employees-ids`,
        JSON.stringify(this.checkedEmployeesIds)
      );
    },
    changeStatus(checked, employeeId) {
      const index = this.checkedEmployeesIds.indexOf(employeeId);
      if (checked) {
        if (index <= -1) {
          this.checkedEmployeesIds.push(employeeId);
        }
      } else if (index > -1) {
        this.checkedEmployeesIds.splice(index, 1);
      }
      localStorage.setItem(
        `${this.companyId}-employees-ids`,
        JSON.stringify(this.checkedEmployeesIds)
      );
    },
    async getAllEmployees() {
      await this.$store
        .dispatch("getManyEmployees", this.companyId)
        .finally(() => (this.loading = false))
        .then(data => {
          this.employees = this.restructureEmployeesObject(data);
        })
        .catch(() => {
          this.error = "L'entreprise n'existe pas.";
        });
    },
    compareTwoEmployees(firstEmployee, secondEmployee) {
      let comparison = 0;
      if (
        firstEmployee.fields[this.currentHeaderIndexToSortBy].value >
        secondEmployee.fields[this.currentHeaderIndexToSortBy].value
      ) {
        comparison = 1;
      } else if (
        firstEmployee.fields[this.currentHeaderIndexToSortBy].value <
        secondEmployee.fields[this.currentHeaderIndexToSortBy].value
      ) {
        comparison = -1;
      }
      return this.sortDirection === "desc" ? comparison * -1 : comparison;
    },
    sort(index, sortDirection) {
      this.sortDirection = sortDirection;
      this.currentHeaderIndexToSortBy = index;
      this.employees = this.employees.sort(this.compareTwoEmployees);
    },
    restructureEmployeesObject(employees) {
      if (employees) {
        return employees.map(employee => {
          const innerEmployee = {
            id: employee.id,
            fields: [],
            alerts: employee.alerts
          };
          innerEmployee.fields.push(
            {
              value: employee.registrationNumber,
              key: "registrationNumber"
            },
            { value: employee.gender, key: "gender" },
            {
              value: this.getDateReducedFormat(employee.birthDate),
              key: "birthDate"
            },
            { value: employee.service?.name, key: "service" },
            {
              value: this.getDateReducedFormat(employee.seniorityDateInTheJob),
              key: "seniorityDateInTheJob"
            },
            {
              value: this.getDateReducedFormat(employee.dateOfHire),
              key: "dateOfHire"
            },
            {
              value: employee.jobProfessionalFamily?.name,
              key: "jobProfessionalFamily"
            },
            { value: employee.currentJobTitle, key: "currentJobTitle" },
            { value: employee.contractType, key: "contractType" },
            {
              value: employee.socioProfessionalCategory?.name,
              key: "socioProfessionalCategory"
            }
          );
          if (!this.futureClassification) {
            innerEmployee.fields.push(
              {
                value: this.romanValues.get(employee.level),
                key: "level"
              },
              { value: employee.salaryGrade !== 4 ? employee.salaryGrade : "", key: "salaryGrade" },
              { value: employee.coef?.value, key: "coef" }
            );
          } else if (this.futureClassification) {
            innerEmployee.fields.push({
              value: employee.jobGroupAndClass,
              key: "jobGroupAndClass"
            });
          }
          innerEmployee.fields.push(
            { value: employee.group, key: "group" },
            { value: employee.lastDiploma, key: "lastDiploma" },
            { value: employee.cqpmCategory, key: "cqpmCategory" },
            {
              value: employee.nonExecutiveEmployeeBaseSalary,
              key: "nonExecutiveEmployeeBaseSalary"
            },
            {
              value: employee.nonExecutiveEmployeeYearlyBonusWithoutSeniorityBonus,
              key: "nonExecutiveEmployeeYearlyBonusWithoutSeniorityBonus"
            },
            {
              value: employee.nonExecutiveEmployeeSeniorityBonus,
              key: "nonExecutiveEmployeeSeniorityBonus"
            },
            {
              value: employee.grossSalaryAllIncludedWithoutSeniorityAndExceptionalBonus,
              key: "grossSalaryAllIncludedWithoutSeniorityAndExceptionalBonus"
            },
            {
              value: employee.nonExecutiveEmployeeLegalWorkingTime,
              key: "nonExecutiveEmployeeLegalWorkingTime"
            },
            {
              value: employee.nonExecutiveEmployeeDayPackageForTheYear,
              key: "nonExecutiveEmployeeDayPackageForTheYear"
            },
            {
              value: employee.nonExecutiveEmployeeModulationOfWorkingTime,
              key: "nonExecutiveEmployeeModulationOfWorkingTime"
            },
            {
              value: employee.nonExecutiveEmployeePartTimeWork,
              key: "nonExecutiveEmployeePartTimeWork"
            },
            {
              value: employee.nonExecutiveEmployeeTeamWork,
              key: "nonExecutiveEmployeeTeamWork"
            }
          );

          return innerEmployee;
        });
      }
      return [];
    },
    getDateReducedFormat(inputDate) {
      if (!inputDate) {
        return "";
      }

      const date = new Date(inputDate);

      if (date && !Number.isNaN(date.getTime())) {
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      }

      return "";
    },

    editEmployeeInformations(employeeId) {
      this.$router.push({
        path: "employees",
        query: { companyId: this.companyId, employeeId }
      });
    },
    async updateClassification() {
      const company = await this.$store.dispatch("getCompany", this.companyId);
      this.futureClassification = company.usedClassification === "Future Classification";
      const actualClassificationItems = ["Niveau", "Echelon", "COEF"];
      const futureClassificationItems = ["Groupe - Classe d'emplois"];
      const headerItemsEnd = [
        "Groupe",
        "Dernier diplôme obtenu",
        "CQPM de quelle catégorie ?",
        "Salaire de base annuel hors prime et heures supplementaires",
        "Primes versées annuellement hors prime d'ancienneté",
        "Prime d'ancienneté (uniqument pour les NC)",
        "Salaire brut annuel tout compris exclue prime d'ancienneté ET prime exeptionnelle",
        "Durée légale du travail (35 heures - 151,67 h)",
        "Forfait jours sur l'année",
        "Modulation du Temps de Travail (1607 heures)",
        "Travail à temps partiel",
        "Travail en équipe"
      ];
      if (!this.futureClassification) {
        this.headerItems = this.headerItems.concat(...actualClassificationItems);
      } else {
        this.headerItems = this.headerItems.concat(...futureClassificationItems);
      }
      this.headerItems = this.headerItems.concat(...headerItemsEnd);
    },
    async updateCompanyAlerts() {
      const unformattedEmployees = await this.$store.dispatch(
        "updateCompanyAlerts",
        this.companyId
      );

      this.employees = this.restructureEmployeesObject(unformattedEmployees);
    }
  },
  data() {
    return {
      error: null,
      companyId: this.$route.query.companyId,
      currentHeaderIndexToSortBy: null,
      sortDirection: null,
      checkedEmployeesIds: [],
      romanValues: new Map([
        ["1", "I"],
        ["2", "II"],
        ["3", "III"],
        ["31", "III A"],
        ["32", "III B"],
        ["33", "III C"],
        ["4", "IV"],
        ["5", "V"]
      ]),
      headerItems: [
        "Matricule",
        "Sexe (H/F)",
        "Date de naissance (JJ/MM/AAAA)",
        "Service/Secteur/Atelier/Ilot…",
        "Date d'ancienneté dans l'emploi",
        "Date d'embauche (JJ/MM/AAAA)",
        "Famille professionnelle de l'emploi",
        "Intitulé d'emploi actuel",
        "Contrat",
        "CSP"
      ],
      employees: [],
      futureClassification: false,
      loading: false
    };
  }
};
</script>
<style lang="scss" scoped>
.button-container {
  padding-top: 2.275rem;
  padding-bottom: 3rem;
}

.button-line {
  top: 1.275rem;
  right: 2.45rem;
}
th::before {
  content: "";
  z-index: -1;
  @apply absolute h-full w-full bg-white inset-0;
  box-shadow: 0 5px 15px rgba(0 0 0 / 0.15);
}
.sticky_col0 {
  box-shadow: 5px 0 10px rgba(0 0 0 / 0.2);
  @apply sticky left-0 bg-white;
}
.sticky_col1 {
  box-shadow: 5px 0 10px rgba(0 0 0 / 0.2);
  @apply sticky bg-white;
  left: 52px;
}
</style>
