<template>
  <div class="p-subview">
    <custom-loader v-if="loading" />
    <div
      v-for="error in errors"
      :key="error"
      class="
        border
        text-xs
        border-error-red
        text-error-red
        px-2
        py-1
        rounded
        relative
        mb-2
      "
      role="alert"
    >
      <span class="block sm:inline"> {{ error }} </span>
    </div>
    <custom-sub-view-title title="Caractéristiques de l'entreprise">
      <div class="mb-sub-views-spacing">
        <div class="flex items-center space-x-9 mb-sub-views-spacing">
          <div class="flex-1">
            <custom-form-input
              inputId="company-name"
              inputLabel="NOM DE L'ENTREPRISE OU DE L'ETABLISSEMENT"
              v-model="company.name"
            />
          </div>
          <div class="flex-1">
            <custom-form-input
              inputId="geographical-department"
              inputLabel="DÉPARTEMENT GÉOGRAPHIQUE"
              v-model="company.geographicalDepartement"
              inputPlaceholder="Département"
            />
          </div>
          <div class="flex-1">
            <custom-form-input
              inputId="employees-number"
              inputLabel="NOMBRE DE SALARIÉS"
              v-model="company.employeesNumber"
            />
          </div>
        </div>
        <div>
          <div class="flex items-center space-x-9">
            <div class="flex-1">
              <div class="relative h-16 mb-3">
                <label
                  for="professional-family"
                  class="absolute text-input-label font-bold text-gris bottom-0"
                >
                  FAMILLE PROFESSIONNELLE À LAQUELLE LE SERVICE / SECTEUR / ATELIER / ILOT EST
                  RATTACHÉ
                </label>
              </div>
            </div>
            <div class="flex-1">
              <div class="relative h-16 mb-3">
                <label
                  for="services-list"
                  class="absolute text-input-label font-bold text-gris bottom-0"
                >
                  LISTE DES SERVICES / SECTEURS / ATELIERS / ILOTS
                </label>
              </div>
            </div>
            <div class="flex-1"></div>
          </div>
          <edit-company-service
            v-for="(service, index) in company.services"
            id="edit-service"
            :key="service.name"
            :index="index"
            :service="service"
            :isEditing="true"
            @deleteService="deleteServiceFromCompany"
            @editService="editServiceInCompany"
            changeServiceInCompany
            class="mb-3"
          />
          <edit-company-service
            id="create-service"
            :service="blankService"
            :isEditing="false"
            @createService="addServiceToCompany"
            @editService="editServiceInCompany"
            class="mb-3"
          />
        </div>
        <div>
          <div class="flex items-center space-x-9">
            <div class="flex-1">
              <div class="relative h-16 mb-3">
                <label
                  for="classificationChoice"
                  class="absolute text-input-label font-bold text-gris bottom-0"
                >
                  CHOIX DE LA CLASSIFICATION
                </label>
              </div>
            </div>
          </div>
          <div class="flex items-center space-x-9">
            <div class="flex-1">
              <custom-select-input
                name="classificationChoice"
                id="classificationChoice"
                :options="options"
                v-model="company.usedClassification"
                cssClass="text-input font-normal"
              />
            </div>
            <div class="flex-1"></div>
            <div class="flex-1"></div>
          </div>
        </div>
      </div>
    </custom-sub-view-title>
    <div>
      <custom-sub-view-title title="Informations complémentaires">
        <div class="flex mt-file-upload-input space-x-7">
          <file-upload
            title="LOGO DE L'ENTREPRISE"
            inBoxText="Importer le logo"
            @file-uploaded="receiveLogo"
          />
          <file-upload
            title="DONNÉES DES SALARIÉS"
            inBoxText="Importer un nouveau fichier .csv"
            @file-uploaded="receiveEmployeesFile"
          />
        </div>
      </custom-sub-view-title>
    </div>
    <custom-button
      id="createCompany"
      name="ENREGISTRER"
      class="ml-auto float-right relative bottom-4"
      :disabled="company.name === '' || !company.usedClassification"
      @onClick="createOrUpdateCompany"
    />
  </div>
</template>

<script>
import EditCompanyService from "@/components/molecules/EditCompanyService.vue";
import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";
import CustomButton from "@/components/atoms/CustomButton.vue";
import FileUpload from "@/components/molecules/FileUpload.vue";
import CustomFormInput from "@/components/atoms/CustomFormInput.vue";
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";
import CustomLoader from "@/shared/CustomLoader.vue";
import { toastMessagesTranslation } from "@/utils.ts";

export default {
  components: {
    EditCompanyService,
    CustomSubViewTitle,
    CustomButton,
    FileUpload,
    CustomFormInput,
    CustomSelectInput,
    CustomLoader
  },
  props: {},
  data() {
    return {
      company: {
        name: "",
        geographicalDepartement: "",
        employeesNumber: 0,
        usedClassification: "Classification Actuelle",
        services: []
      },
      logo: null,
      employeesFile: null,
      blankService: {
        name: "",
        professionalFamily: ""
      },
      lastService: {},
      errors: [],
      id: this.$route.query.companyId,
      options: [
        {
          key: "Future Classification",
          value: "Future Classification"
        },
        {
          key: "Classification Actuelle",
          value: "Classification Actuelle"
        }
      ],
      loading: false,
      successToastInfos: { isShowed: false, message: "success" }
    };
  },
  async mounted() {
    if (this.id) {
      await this.getCompany();
    }
  },
  methods: {
    async getCompany() {
      this.loading = true;
      await this.$store
        .dispatch("getCompany", this.id)
        .finally(() => (this.loading = false))
        .then(company => {
          this.company = {
            name: company.name,
            geographicalDepartement: company.geographicalDepartement,
            employeesNumber: company.employeesNumber,
            usedClassification: company.usedClassification,
            services: company.services
              ? company.services.map(service => ({
                  id: service.id,
                  name: service.name,
                  professionalFamily: service.professionalFamily.name
                }))
              : []
          };
        })
        .catch(() => {
          this.errors.push("L'entreprise n'existe pas.");
        });
    },
    deleteServiceFromCompany(index) {
      if (index > -1) {
        this.company.services.splice(index, 1);
      }
    },
    editServiceInCompany({ index, service }) {
      if (index > -1) {
        this.company.services[index] = service;
      } else {
        this.lastService = service;
      }
    },
    addServiceToCompany(service) {
      this.company.services.push(service);
    },
    async createOrUpdateCompany() {
      this.id && (this.company.id = this.id);
      this.loading = true;
      (!this.id ? this.dispatch("createCompany") : this.dispatch("updateCompany"))
        .finally(() => {
          this.loading = false;
        })
        .then(() => {
          this.successToastInfos.isShowed = true;
        });
    },
    async dispatch(event) {
      this.errors = [];
      if (this.lastService.name && this.lastService.professionalFamily) {
        this.company.services.push(this.lastService);
      }

      const formData = new FormData();
      formData.append("company", JSON.stringify(this.company));
      formData.append("logo", this.logo);
      formData.append("employeesFile", this.employeesFile);

      let data;
      if (this.id) {
        data = {
          companyId: this.id,
          company: formData
        };
      } else {
        data = formData;
      }
      await this.$store
        .dispatch(event, data)
        .then(resp => {
          this.$router.push({
            path: "employees",
            query: {
              companyId: resp.data.id,
              successToastMessage: toastMessagesTranslation[event]
                ? toastMessagesTranslation[event]
                : "success",
              showSuccessToast: true
            }
          });
        })
        .catch(err => {
          if (err.response) {
            let errorMessage = err.response.data.message;
            if (errorMessage === "Company does not exist") {
              errorMessage = "L'entreprise n'existe pas.";
            }
            this.errors.push(errorMessage);
          }
        });
    },
    receiveLogo(logo) {
      this.logo = logo;
    },
    receiveEmployeesFile(employeesFile) {
      this.employeesFile = employeesFile;
    }
  }
};
</script>
