<template>
  <div class="relative">
    <div
      class="
        bg-couleur-blanc
        flex
        justify-between
        pt-2
        pl-6
        pr-6
        pb-2
        shadow-md
      "
    >
      <div id="logos" class="cursor-pointer" @click="redirectToHome">
        <img class="h-sm" :src="$asset('icons/logo-uimm.svg')" alt="UIMM LOGO" />
      </div>

      <div class="flex self-center text-gris" v-click-outside="closePopup">
        <p class="text-input pr-6">{{ firstname }} {{ lastname }}</p>
        <div class="cursor-pointer" @click="showPopup = !showPopup">
          <img class="h-xs" :src="$asset('icons/deconnect-icon.svg')" alt="power-logo" />
        </div>
      </div>
    </div>
    <div v-if="showPopup" class="flex justify-end right-0 z-50 absolute">
      <a
        class="
          bg-couleur-blanc
          rounded-b
          p-3
          px-6
          text-error-red
          cursor-pointer
          shadow-xl
        "
        @click="disconnect"
      >
        Se déconnecter
      </a>
    </div>
  </div>
</template>

<script>
import store from "../../store";

export default {
  data() {
    return {
      firstname: store.getters.firstname,
      lastname: store.getters.lastname,
      showPopup: false
    };
  },
  methods: {
    closePopup() {
      this.showPopup = false;
    },
    async disconnect() {
      return this.$store
        .dispatch("disconnect")
        .then(() => {
          this.$router.push("/login");
        })
        .catch(err => {
          console.log(err);
        });
    },
    redirectToHome() {
      const home = "/";
      if (this.$route.path !== home) this.$router.push(home);
    }
  }
};
</script>
