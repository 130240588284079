import Vue from "vue";
import { LoginResponse, RefreshedTokens } from "@/models/LoginResponse";
import { Role } from "@/models/Role";
import { User } from "@/models/User";

export default class AuthService {
  authRequest = async (user: User): Promise<LoginResponse> => {
    try {
      const loginResponse = await Vue.axios.post("login", user);
      const { token, refreshToken } = loginResponse.data;
      const { firstname, lastname, id, roles, territory } = loginResponse.data.user;

      localStorage.setItem("user-firstname", firstname || "");
      localStorage.setItem("user-lastname", lastname || "");
      localStorage.setItem("user-id", id || "");
      localStorage.setItem("user-token", token);
      localStorage.setItem("user-territory-id", territory.id || "");
      localStorage.setItem("user-refresh-token", refreshToken);

      if (roles) localStorage.setItem("is-admin", this.isUserAdmin(roles).toString());
      Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      return loginResponse;
    } catch (e) {
      this.emptyLocalStorage();
      console.error(e.message);
      throw e;
    }
  };

  refreshToken = async (): Promise<RefreshedTokens> => {
    try {
      const refreshToken = localStorage.getItem("user-refresh-token");
      const response = await Vue.axios.post<RefreshedTokens>("refresh", {
        refreshToken: refreshToken
      });
      localStorage.setItem("user-token", response.data.token);
      localStorage.setItem("user-refresh-token", response.data.refreshToken);
      return response.data;
    } catch (e) {
      this.emptyLocalStorage();
      console.error(e.message);
      throw e;
    }
  };

  logout = async (): Promise<RefreshedTokens> => {
    try {
      const response = await Vue.axios.get("logout");
      this.emptyLocalStorage();
      return response.data;
    } catch (e) {
      console.error(e.message);
      throw e;
    }
  };

  emptyLocalStorage = (): void => {
    localStorage.clear();
  };

  isUserAdmin = (roles: Role[]): boolean => {
    if (roles.find(role => role.name === "admin") !== undefined) return true;
    return false;
  };
}
