<template>
  <div>
    <custom-sub-view-title title="Eléments de rémunération" class="mt-16">
      <h2 class="text-gris font-bold mt-10 text-2xl -mb-2">
        Non-cadres
      </h2>
      <div class="flex items-center space-x-9">
        <div class="flex-1">
          <custom-form-price-input
            inputId="employee-base-salary"
            inputLabel="SALAIRE DE BASE ANNUEL HORS PRIME ET HEURES SUPPLÉMENTAIRES"
            v-model="employee.nonExecutiveEmployeeBaseSalary"
            inputPlaceholder="Indiquez le salaire annuel brut"
          />
        </div>
        <div class="flex-1">
          <custom-form-price-input
            inputId="employee-base-salary"
            inputLabel="PRIMES VERSÉES ANNUELLEMENT HORS PRIME D'ANCIENNETÉ"
            v-model="employee.nonExecutiveEmployeeYearlyBonusWithoutSeniorityBonus"
            inputPlaceholder="Indiquez la prime annuelle"
          />
        </div>
        <div class="flex-1">
          <custom-form-price-input
            inputId="employee-base-salary"
            inputLabel="PRIME D'ANCIENNETÉ"
            v-model="employee.nonExecutiveEmployeeSeniorityBonus"
            inputPlaceholder="Indiquez la prime d'ancienneté"
          />
        </div>
      </div>
      <div class="flex items-center space-x-9 mt-4">
        <div class="flex-1">
          <custom-form-price-input
            inputId="employee-base-salary"
            inputLabel="
              SALAIRE BRUT ANNUEL TOUT COMPRIS EXCLUE PRIME D'ANCIENNETÉ ET PRIME EXCEPTIONNELLE
              "
            v-model="employee.grossSalaryAllIncludedWithoutSeniorityAndExceptionalBonus"
            inputPlaceholder="Indiquez le salaire annuel brut"
          />
        </div>
        <div class="flex-1"></div>
        <div class="flex-1"></div>
      </div>
    </custom-sub-view-title>
  </div>
</template>

<script>
import CustomFormPriceInput from "@/components/atoms/CustomFormPriceInput.vue";
import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";

export default {
  components: {
    CustomSubViewTitle,
    CustomFormPriceInput
  },
  props: {
    employee: {
      required: true
    }
  },
  data() {
    return {
      actualEmployee: {
        nonExecutiveEmployeeBaseSalary: this.employee.nonExecutiveEmployeeBaseSalary || "",
        nonExecutiveEmployeeYearlyBonusWithoutSeniorityBonus:
          this.employee.nonExecutiveEmployeeYearlyBonusWithoutSeniorityBonus || "",
        executiveEmployeeBaseSalaryForThirtyFiveHours:
          this.employee.executiveEmployeeBaseSalaryForThirtyFiveHours || "",
        nonExecutiveEmployeeSeniorityBonus: this.employee.nonExecutiveEmployeeSeniorityBonus || "",
        grossSalaryAllIncludedWithoutSeniorityAndExceptionalBonus:
          this.employee.grossSalaryAllIncludedWithoutSeniorityAndExceptionalBonus || ""
      },
      errors: []
    };
  },
  watch: {
    employee: {
      deep: true,
      handler() {
        this.actualEmployee = this.$props.employee;
      }
    }
  }
};
</script>
