<template>
  <div>
    <employees-actual-personal-data :employee="employee"> </employees-actual-personal-data>

    <employees-actual-job-data :employee="employee" :services="company.services || []">
    </employees-actual-job-data>

    <employees-actual-classification
      :employee="employee"
      :usedClassification="this.company.usedClassification"
    ></employees-actual-classification>

    <employees-actual-remuneration
      :employee="employee"
      v-if="employee.contractType !== $SpecificContractType"
    ></employees-actual-remuneration>

    <employees-actual-working-time
      :employee="employee"
      v-if="employee.contractType !== $SpecificContractType"
    ></employees-actual-working-time>
  </div>
</template>

<script>
import EmployeesActualPersonalData from "@/components/pages/forms/sub-views/employees/EmployeesActualPersonalData.vue";
import EmployeesActualJobData from "@/components/pages/forms/sub-views/employees/EmployeesActualJobData.vue";
import EmployeesActualClassification from "@/components/pages/forms/sub-views/employees/EmployeesActualClassification.vue";
import EmployeesActualRemuneration from "@/components/pages/forms/sub-views/employees/EmployeesActualRemuneration.vue";
import EmployeesActualWorkingTime from "@/components/pages/forms/sub-views/employees/EmployeesActualWorkingTime.vue";

export default {
  components: {
    EmployeesActualPersonalData,
    EmployeesActualJobData,
    EmployeesActualClassification,
    EmployeesActualRemuneration,
    EmployeesActualWorkingTime
  },
  props: {
    employee: {
      required: true
    },
    company: {
      required: true
    }
  },
  data() {
    return {
      actualEmployee: {},
      errors: []
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.flex-g-1 {
  flex-grow: 1;
}

.flex-g-2 {
  flex-grow: 2;
}
</style>
