var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'table-cell-content',
    'relative',
    'text-table-cell-content',
    'flex',
    'justify-between',
    'p-2',
    'space-x-2'
  ]},[_c('span',[_vm._v(_vm._s(_vm.value))]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hidePopup),expression:"hidePopup"}]},[(_vm.showPopup)?_c('div',{staticClass:"small-popup"},[_c('div',{staticClass:"z-20 absolute right-1 p-0.5",on:{"click":function($event){$event.stopPropagation();return _vm.togglePopup($event)}}},[_c('img',{staticClass:"h-xs",attrs:{"src":_vm.$asset('icons/burger-icon.svg'),"alt":"burger-menu"}})]),_c('div',{staticClass:"relative w-36"},[_c('div',{on:{"click":function($event){return _vm.$emit('edit-infos')}}},[_vm._v("Editer l'information")])])]):_vm._e()]),_c('div',{staticClass:"settings-icon flex flex-col justify-center w-4",on:{"click":function($event){$event.stopPropagation();return _vm.togglePopup($event)}}},[_c('img',{staticClass:"h-in-cell-burger-icon",attrs:{"src":_vm.$asset('icons/burger-icon.svg'),"alt":"burger-menu"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }