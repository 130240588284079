import { AxiosResponse } from "axios";
import Vue from "vue";
import { Employee } from "@/models/Employee";

export default class EmployeeService {
  getAllEmployees = (companyId: string): Promise<Employee[]> =>
    Vue.axios
      .get(`companies/${companyId}/employees`)
      .then((resp: AxiosResponse<Employee[]>) => resp.data)
      .catch(err => err);

  getEmployee = (companyId: string, employeeID: string): Promise<Employee> =>
    Vue.axios
      .get(`companies/${companyId}/employees/${employeeID}`)
      .then((resp: AxiosResponse<Employee[]>) => resp.data)
      .catch(err => err);

  createEmployee = (companyId: string, employee: Employee): Promise<Employee> =>
    Vue.axios
      .post(`companies/${companyId}/employees`, employee)
      .then((resp: AxiosResponse<Employee>) => resp.data);

  updateEmployee = (
    companyId: string,
    employeeID: string,
    employee: Employee
  ): Promise<Employee | Error> =>
    Vue.axios
      .patch(`companies/${companyId}/employees/${employeeID}`, employee)
      .then((resp: AxiosResponse<Employee>) => resp.data);

  deleteEmployee = (companyId: string, employeeID: string): Promise<Employee> =>
    Vue.axios
      .delete(`companies/${companyId}/employees/${employeeID}`)
      .then((resp: AxiosResponse<Employee>) => resp.data)
      .catch(err => err);
}
