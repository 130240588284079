import EmployeeRecommendationService from "@/services/EmployeeRecommendationService";
import { EmployeeRecommendation } from "@/models/EmployeeRecommendation";

const employeeRecommendationService = new EmployeeRecommendationService();

const actions = {
  getOneEmployeeRecommendation(_: any, id: string): Promise<EmployeeRecommendation> {
    return employeeRecommendationService.getEmployeeRecommendation(id);
  }
};

export default {
  actions
};
