<template>
  <div class="bg-gris-clair h-screen overflow-auto">
    <Header />
    <div class="mx-auto mx-8 mt-8 h-full bg-gris-clair w-sub-view-container">
      <div class="flex">
        <custom-nav-bar
          class="justify-start"
          :elements="getNavBarElements()"
          :activePath="this.$route.path"
          :companyId="this.$route.query.companyId"
        />
      </div>
      <div class="bg-couleur-blanc rounded-lg mt-8">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import CustomNavBar from "@/components/organisms/CustomNavBar.vue";
import Header from "@/components/organisms/Header.vue";

export default {
  components: { Header, CustomNavBar },
  methods: {
    getNavBarElements() {
      return [
        {
          name: "L'ENTREPRISE",
          id: "company",
          imgSource: "form-icon.svg",
          altSource: "Company",
          path: "company"
        },
        {
          name: "LES SALARIÉS",
          id: "employees",
          imgSource: "employee-icon.svg",
          altSource: "Employees",
          path: "employees"
        },
        {
          name: "TABLEAU",
          id: "table",
          imgSource: "table-icon.svg",
          altSource: "table",
          path: "table"
        },
        {
          name: "CARTOGRAPHIE",
          id: "cartography",
          imgSource: "cartography-icon.svg",
          altSource: "cartography",
          path: "cartography"
        }
      ];
    }
  }
};
</script>
