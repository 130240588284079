<template>
  <div class="z-50 bg-gris bg-opacity-75 transition-opacity fixed table top-0 left-0 w-full h-full">
    <div class="table-cell align-middle">
      <div class="bg-gris-clair rounded-sm sm:p-6 sm:pb-4 w-modal m-auto px-8">
        <div class="text-right w-full flex">
          <img
            :src="$asset('icons/close-icon.svg')"
            class="ml-auto"
            alt="close"
            height="18"
            width="18"
            @click="onClose"
          />
        </div>
        <div class="px-28">
          <h2 class="text-center text-md font-bold text-gris">
            Partager cette fiche avec un consultant
          </h2>
          <form class="mt-8" @submit.prevent="shareCard">
            <CustomSelectInput
              label="LISTE DES CONSULTANTS DU TERRITOIRE N'AYANT PAS ENCORE LES DROITS"
              name="consultants"
              id="consultants"
              :options="consultants"
              defaultOption="Sélectionnez un consultant"
              v-model="consultant"
            />
            <div class="flex flex-col items-center">
              <div class="mt-5">
                <button
                  id="share-btn"
                  type="submit"
                  :class="[
                    'submit-button bg-gris text-md',
                    'disabled:bg-button-disabled',
                    'disabled:cursor-default'
                  ]"
                  :disabled="!consultant"
                >
                  PARTAGER
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";

export default {
  components: {
    CustomSelectInput
  },
  props: {
    companyId: { required: true }
  },
  data() {
    return {
      consultant: "",
      notAllowedConsultants: [],
      consultants: []
    };
  },
  async mounted() {
    await this.getConsultants();
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    async shareCard() {
      const sharedUserId = this.notAllowedConsultants.find(
        consultant => consultant.name === this.consultant
      ).id;
      await this.$store
        .dispatch("shareCompany", { userID: sharedUserId, companyID: this.companyId })
        .then(() => {
          this.$emit("close");
        })
        .catch(err => {
          console.log(err);
        });
      this.$emit("close");
    },
    async getConsultants() {
      return this.$store
        .dispatch("getNotAllowedUsers", this.companyId)
        .then(resp => {
          this.notAllowedConsultants = resp.map(consultant => ({
            name: `${consultant.firstname} ${consultant.lastname}`,
            id: consultant.id
          }));
          this.consultants = this.notAllowedConsultants.map(consultant => ({
            key: consultant.name,
            value: consultant.name
          }));
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
