<template>
  <div
    v-if="
      (apprenticeShipRatio && apprenticeShipRatio > 0) ||
        (hasApprenticeShipEmployees && apprenticeShipRatio === 0)
    "
  >
    <custom-sub-view-title title="Alternants" />
    <div class="relative">
      <div class="flex absolute items-center right-0 space-x-2">
        <custom-button id="export-in-png" name="PNG" fontSize="text-base" @onClick="exportTable" />
      </div>
    </div>
    <div class="mt-8 mb-3">
      <strong>Ratio: {{ apprenticeShipRatio }}% d'alternants</strong>
    </div>
    <div ref="exportedTable" class="bg-couleur-blanc text-gris">
      <table aria-describedby="apprenticeship-table" class="apprenticeship-table">
        <tr class="text-center py-7">
          <th scope="empty" colspan="2"></th>
          <th scope="number">Matricule</th>
          <th scope="age"><strong>Age</strong></th>
          <th scope="job-title"><strong>Poste</strong></th>
          <th scope="group"><strong>Groupe</strong></th>
          <th scope="comments"><strong>Commentaire</strong></th>
        </tr>
        <template v-for="professionalFamily in allApprenticeshipEmployees">
          <template v-for="(service, index) in professionalFamily.services">
            <tr v-for="(employee, innerIndex) in service.employees" :key="employee.firstName">
              <td
                v-if="index === 0 && innerIndex === 0"
                class="text-center truncate font-bold rotated"
                :rowspan="getApprenticiesNumberInProfessionalFamily(professionalFamily)"
              >
                <p>{{ professionalFamily.name }}</p>
              </td>
              <td
                v-if="innerIndex === 0"
                class="text-center truncate font-bold rotated"
                :rowspan="service.employees.length"
              >
                <p>{{ service.name }}</p>
              </td>
              <td>{{ employee.registrationNumber }}</td>
              <td>{{ getAge(employee.birthDate) }}</td>
              <td>{{ employee.currentJobTitle }}</td>
              <td>{{ employee.group }}</td>
              <td class="textinput p-0 relative w-2/5">
                <textarea
                  type="text"
                  v-model="employee.comment"
                  placeholder="Ajouter un commentaire"
                  class="textarea-grey-placeholder"
                  @keyup="updateEmployeeComment(employee.id, employee.comment)"
                />
              </td>
            </tr>
          </template>
        </template>
      </table>
    </div>
  </div>
  <div v-else class="p - subview">
    <information message="Il n'y a pas d'alternants" />
  </div>
</template>

<script>
import debounce from "lodash.debounce";

import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";
import CustomButton from "@/components/atoms/CustomButton.vue";
import Information from "./Information.vue";

import { saveTable } from "@/utils";

export default {
  components: {
    CustomSubViewTitle,
    CustomButton,
    Information
  },
  props: {
    setLoading: {
      required: true
    }
  },
  data() {
    return {
      apprenticeShipRatio: 0,
      allApprenticeshipEmployees: [],
      companyId: this.$route.query.companyId || "",
      hasApprenticeShipEmployees: false
    };
  },
  async mounted() {
    await this.computeApprenticeshipRatio();
  },
  methods: {
    getApprenticiesNumberInProfessionalFamily(professionalFamily) {
      let apprenticesNumber = 0;

      professionalFamily.services.forEach(services => {
        apprenticesNumber += services.employees.length;
      });

      return apprenticesNumber;
    },
    getAge(date) {
      if (!date) return "";
      const birthDate = new Date(date);
      var ageDifMs = Date.now() - birthDate.getTime();
      var ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970) + " ans";
    },
    async generateSettings() {
      const settings = [];
      let companyProfessionalFamilies = await this.$store.dispatch(
        "getCompanyProfessionalFamilies",
        this.companyId
      );

      if (!companyProfessionalFamilies) {
        companyProfessionalFamilies = [];
      }

      companyProfessionalFamilies.forEach(family => {
        settings.push({
          name: family.name,
          services: family.services
        });
      });

      settings.push({
        name: "Autre",
        services: ["Autre"]
      });

      return settings;
    },
    async computeApprenticeshipRatio() {
      this.setLoading && this.setLoading(true);
      const allEmployees = await this.$store.dispatch("getManyEmployees", this.companyId);
      const { employeesNumber } = await this.$store.dispatch("getCompany", this.companyId);
      const settings = await this.generateSettings();
      const apprenticeshipEmployeesId = allEmployees
        .filter(employee => employee.contractType === "Alternance")
        .map(employee => employee.id);

      this.hasApprenticeShipEmployees = apprenticeshipEmployeesId.length > 0 ? true : false;
      this.allApprenticeshipEmployees = await this.$store.dispatch(
        "getProfessionalFamiliesEmployees",
        {
          id: this.companyId,
          settings: {
            employeesIds: apprenticeshipEmployeesId,
            professionalFamilies: settings
          }
        }
      );

      this.apprenticeShipRatio = employeesNumber
        ? parseFloat(apprenticeshipEmployeesId.length / employeesNumber).toFixed(2) * 100
        : 0;
      this.setLoading && this.setLoading(false);
    },
    updateEmployeeComment: debounce(async function(employeeId, employeeComment) {
      if (employeeComment) {
        await this.$store.dispatch("updateEmployeeCurrentSituationComment", {
          companyId: this.companyId,
          employeeID: employeeId,
          updateComment: {
            comment: employeeComment
          }
        });
      }
    }, 1000),
    exportTable() {
      const node = this.$refs.exportedTable;
      saveTable(node, "apprenticeship-ratio");
    }
  }
};
</script>

<style lang="scss" scoped>
.apprenticeship-table {
  @apply w-full;
  @apply border-collapse;

  td,
  th {
    @apply p-2;
    @apply border-2;
    @apply border-gris-moyen;
  }

  .textinput {
    padding: 0 !important;
  }

  .rotated {
    @apply text-center;
    @apply whitespace-nowrap;
    @apply align-middle;
    @apply w-10;
    @apply h-32;

    p {
      -moz-transform: rotate(-90deg); /* FF3.5+ */
      -o-transform: rotate(-90deg); /* Opera 10.5 */
      -webkit-transform: rotate(-90deg); /* Saf3.1+, Chrome */
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083); /* IE6,IE7 */
      -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)"; /* IE8 */
      @apply -ml-40;
      @apply -mr-40;
    }
  }
}
</style>
