var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['flex items-center', _vm.disabled ? 'cursor-not-allowed' : 'cursor-pointer']},[_c('div',{class:[
      'submit-button',
      'hover:shadow-lg',
      _vm.backgroundColor,
      _vm.padding,
      { 'pointer-events-none bg-button-disabled': _vm.disabled },
      { 'bg-gris': !_vm.disabled }
    ],attrs:{"id":_vm.id},on:{"click":_vm.onClick}},[_c('img',{attrs:{"src":_vm.imgSource,"alt":_vm.altSource}}),(_vm.imgSource && _vm.name.length)?_c('div',{staticClass:"ml-3"}):_vm._e(),_c('div',[_c('p',{class:[_vm.fontSize, 'h-6']},[_vm._v(_vm._s(_vm.name))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }