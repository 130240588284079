<template>
  <div
    :class="[
      'relative',
      'rounded-sm',
      'p-4 m-4 pt-8',
      'shadow-lg',
      'w-followed-companies-card',
      'h-followed-companies-card',
      bgColor
    ]"
  >
    <div v-click-outside="hidePopup">
      <div class="small-popup" v-show="showPopup">
        <div class="z-20 absolute right-2" @click.stop="togglePopup">
          <img class="h-xs" :src="$asset('icons/burger-icon.svg')" alt="burger-menu" />
        </div>
        <div class="relative">
          <div @click="shareCard()">Partager</div>
          <div @click="deleteCard()">Supprimer de la liste</div>
        </div>
      </div>
    </div>
    <div class="cursor-pointer z-20 absolute right-2 top-6 p-2" @click.stop="togglePopup">
      <img class="h-xs" :src="$asset('icons/burger-icon.svg')" alt="burger-menu" />
    </div>
    <div class="relative">
      <div class="bg-white h-28 w-36 m-auto rounded flex p-1.5">
        <img class="max-h-full m-auto" :src="imgSource" :alt="altSource" />
      </div>
      <h1 class="text-center font-bold mt-5 text-h3">{{ name }}</h1>
      <h3 class="text-center font-light m-3 mt-4">{{ type }}</h3>
      <button id="goToFile" @click="redirectToFile" :class="['redirect-button', 'hover:shadow-lg']">
        Voir la fiche
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: { required: true },
    type: { required: true },
    id: { required: true },
    imgSource: { required: true },
    altSource: { required: true },
    backgroundColor: { required: true }
  },
  methods: {
    redirectToFile() {
      this.$router.push({ path: "forms", query: { companyId: this.id } });
    },
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    hidePopup() {
      if (this.showPopup) {
        this.showPopup = false;
      }
    },
    shareCard() {
      this.togglePopup();
      this.$emit("share", this.id);
    },
    deleteCard() {
      this.togglePopup();
      this.$emit("delete", this.id);
    }
  },
  data() {
    return {
      showPopup: false,
      bgColor: this.backgroundColor
    };
  }
};
</script>
<style scoped>
.redirect-button {
  @apply flex;
  @apply py-1;
  @apply px-7;
  @apply mt-6;
  @apply shadow-md;
  @apply bg-couleur-blanc;
  @apply rounded-lg;
  @apply m-auto;
}
.small-popup {
  @apply absolute;
  @apply z-30;
  @apply rounded;
  @apply p-2;
  @apply pr-10;
  @apply bg-couleur-blanc;
  @apply shadow-md;
  @apply cursor-pointer;
  @apply right-2;
  @apply top-6;
}
</style>
