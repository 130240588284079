import EmployeeService from "@/services/EmployeeService";
import { Employee } from "@/models/Employee";

const employeesService = new EmployeeService();

const actions = {
  getManyEmployees(_: any, companyId: string): Promise<Employee[]> {
    return employeesService.getAllEmployees(companyId);
  },
  getOneEmployee(_: any, params: { companyId: string; id: string }): Promise<Employee> {
    return employeesService.getEmployee(params.companyId, params.id);
  },

  createEmployee(_: any, params: { companyId: string; employee: Employee }): Promise<Employee> {
    return employeesService.createEmployee(params.companyId, params.employee);
  },

  updateEmployee(
    _: any,
    params: { companyId: string; id: string; employee: Employee }
  ): Promise<Employee | Error> {
    return employeesService.updateEmployee(params.companyId, params.id, params.employee);
  },

  deleteEmployee(_: any, params: { companyId: string; id: string }): Promise<Employee> {
    return employeesService.deleteEmployee(params.companyId, params.id);
  }
};

export default {
  actions
};
