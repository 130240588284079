<template>
  <div class="p-subview overflow-hidden">
    <div v-if="companyId">
      <custom-sub-tab-navbar :titles="titles" @tab-change="changeTab" />
      <custom-loader v-if="loading" />
      <div class="mt-14">
        <forms-current-situation
          :setLoading="setLoading"
          v-if="activeTab === 'SITUATION ACTUELLE' && employeesIds.length > 0"
        />

        <forms-recommandations
          :setLoading="setLoading"
          v-else-if="activeTab === 'RECOMMANDATIONS' && employeesIds.length > 0"
        />

        <forms-apprenticeship :setLoading="setLoading" v-else-if="activeTab === 'ALTERNANTS'" />

        <information
          v-else-if="employeesIds.length === 0"
          message="Vous devez d'abord sélectionner des salariés dans l'onglet tableau"
        />
      </div>
    </div>

    <information
      v-else
      message="Veuillez créer une entreprise afin d'accéder à cette fonctionnalité"
    />
  </div>
</template>

<script>
import CustomSubTabNavbar from "@/components/molecules/CustomSubTabNavbar.vue";
import FormsCurrentSituation from "@/components/organisms/FormsCurrentSituation.vue";
import FormsRecommandations from "@/components/organisms/FormsRecommandations.vue";
import FormsApprenticeship from "@/components/organisms/FormsApprenticeship.vue";
import Information from "@/components/organisms/Information.vue";
import CustomLoader from "@/shared/CustomLoader.vue";

export default {
  components: {
    CustomSubTabNavbar,
    FormsCurrentSituation,
    FormsRecommandations,
    FormsApprenticeship,
    Information,
    CustomLoader
  },
  data() {
    return {
      companyId: this.$route.query.companyId || "",
      titles: ["SITUATION ACTUELLE", "RECOMMANDATIONS", "ALTERNANTS"],
      activeTab: "SITUATION ACTUELLE",
      employeesIds: [],
      loading: false
    };
  },
  mounted() {
    this.employeesIds = localStorage.getItem(`${this.companyId}-employees-ids`)
      ? JSON.parse(localStorage.getItem(`${this.companyId}-employees-ids`))
      : [];
  },
  methods: {
    changeTab(value) {
      this.activeTab = value;
    },
    setLoading(value) {
      this.loading = value;
    }
  }
};
</script>
