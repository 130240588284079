var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-file-upload"},[(_vm.isOpenModal)?_c('file-upload-modal',{attrs:{"modal-title":_vm.inBoxText,"modal-subtitle":"Importer un fichier depuis votre ordinateur.\n          Celui-ci doit correspondre au modèle fourni sinon il risque de ne pas être supporté","file":_vm.file ? _vm.file : ''},on:{"close":function($event){_vm.isOpenModal = false},"file-uploaded":_vm.sendFile}}):_vm._e(),_c('label',{staticClass:"text-input-label font-bold text-gris "},[_vm._v(" "+_vm._s(_vm.title))]),_c('div',{staticClass:"h-file-upload bg-secondary mt-3 p-3 rounded cursor-pointer",on:{"click":function($event){return _vm.openModal()}}},[_c('div',{class:[
        'transition duration-350',
        'border',
        'border-dashed',
        'border-gris-moyen',
        'rounded',
        'h-full',
        'flex flex-col',
        'justify-center',
        'hover:bg-gris-moyen',
        'hover:bg-opacity-30',
        { 'space-y-4': _vm.file }
      ]},[_c('img',{staticClass:"h-6 mb-4",attrs:{"src":_vm.$asset('icons/upload-icon.svg'),"alt":""}}),(_vm.file)?_c('div',[_c('p',{staticClass:"font-normal text-sm text-gris text-center"},[_vm._v(" Actuellement le fichier ")]),_c('p',{staticClass:"font-normal text-sm text-gris font-bold mx-4 truncate w-42 text-center"},[_vm._v(" "+_vm._s(_vm.file.name)+" ")]),_c('p',{staticClass:"font-normal text-sm text-gris text-center"},[_vm._v(" est utilisé ")])]):_c('p',{staticClass:"font-normal text-sm text-gris-moyen text-center mx-5"},[_vm._v(_vm._s(_vm.inBoxText))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }