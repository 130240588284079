<template>
  <div>
    <div class="flex">
      <div class="flex self-center border-b-2 border-black px-4 mr-4 w-sub-view-title-bar" />
      <div class="flex self-center">
        <h1 class="text-sub-view-title text-gris font-bold self-center mr-4">
          {{ title }}
        </h1>
      </div>
    </div>
    <slot> </slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>
