<template>
  <v-chart class="chart" :option="getOption()" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart from "vue-echarts";

use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent]);

export default {
  components: {
    VChart
  },
  props: {
    chartName: { required: true },
    inputData: { required: true }
  },
  methods: {
    getOption() {
      return {
        tooltip: {
          trigger: "item"
        },
        legend: {
          orient: "vertical",
          right: "right",
          top: "middle"
        },
        textStyle: {
          fontFamily: "Candara",
          fontWeight: "bold"
        },
        series: [
          {
            name: this.chartName,
            type: "pie",
            radius: "60%",
            label: {
              show: false
            },
            data: this.inputData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ],
        color: ["#00b2e2", "#a6a3de", "#7e57c6", "#f87498", "#f9cd03"]
      };
    }
  }
};
</script>

<style scoped>
.chart {
  height: 25rem;
}
</style>
