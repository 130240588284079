<template>
  <div>
    <label v-if="label" :for="label" class="block text-xs font-medium text-gris mb-3 font-bold">
      {{ label }}
    </label>
    <select
      :class="[
        cssClass,
        'cursor-pointer',
        'custom-input',
        'focus:ring-black',
        { 'text-gris': inputValue && inputValue.length },
        { 'text-gris-moyen': !inputValue || !inputValue.length }
      ]"
      :name="name"
      :id="id"
      v-model="inputValue"
    >
      <option class="text-gray-500" :value="null">
        {{ defaultOption }}
      </option>

      <option v-for="option in options" :key="option.key" :value="option.value">
        {{ option.key }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    defaultOption: {
      type: String,
      required: false,
      default: "Option par défaut"
    },
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    cssClass: {
      type: String,
      required: false
    }
  },
  computed: {
    inputValue: {
      get() {
        if (typeof this.value === "number") {
          return this.value.toString();
        }
        return this.value ? this.value : null;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
