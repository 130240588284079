<template>
  <div
    :class="[
      'table-cell-content',
      'relative',
      'text-table-cell-content',
      'flex',
      'justify-between',
      'p-2',
      'space-x-2'
    ]"
  >
    <span>{{ value }}</span>
    <div v-click-outside="hidePopup">
      <div class="small-popup" v-if="showPopup">
        <div class="z-20 absolute right-1 p-0.5" @click.stop="togglePopup">
          <img class="h-xs" :src="$asset('icons/burger-icon.svg')" alt="burger-menu" />
        </div>
        <div class="relative w-36">
          <div @click="$emit('edit-infos')">Editer l'information</div>
        </div>
      </div>
    </div>
    <div class="settings-icon flex flex-col justify-center w-4" @click.stop="togglePopup">
      <img class="h-in-cell-burger-icon" :src="$asset('icons/burger-icon.svg')" alt="burger-menu" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: false
    }
  },
  data() {
    return {
      showPopup: false,
      showInformationModal: false
    };
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    hidePopup() {
      this.showPopup = false;
    }
  }
};
</script>

<style scoped>
.small-popup {
  @apply absolute;
  @apply z-30;
  @apply rounded;
  @apply p-1;
  @apply bg-couleur-blanc;
  @apply shadow-md;
  @apply cursor-pointer;
  @apply right-1;
  @apply top-2;
}
.settings-icon {
  @apply invisible;
}
.table-cell-content:hover > .settings-icon {
  @apply visible;
  @apply cursor-pointer;
}
</style>
