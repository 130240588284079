<template>
  <div class="flex items-center space-x-9">
    <div class="flex-1">
      <custom-select-input
        name="professionalFamily"
        id="professionalFamily"
        :options="options"
        :defaultOption="'Choisissez une famille professionnelle'"
        v-model="innerServiceProfessionalFamily"
        cssClass="text-input font-normal"
      />
    </div>
    <div class="flex-1">
      <custom-form-input
        inputId="service-name"
        v-model="innerServiceName"
        inputPlaceholder="Saisissez le nom d'un service, secteur, ..."
      />
    </div>
    <div v-if="isEditing" class="flex-1">
      <button
        @click="deleteService"
        class="shadow-lg text-service-delete-button p-service-delete-button"
      >
        Supprimer cette ligne
      </button>
    </div>
    <div v-else class="flex-1">
      <custom-button
        id="create"
        :imgSource="$asset('icons/plus-icon.svg')"
        altSource="plus-icon"
        class="ml-auto"
        padding="square-button"
        @onClick="createService"
      />
    </div>
  </div>
</template>

<script>
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";
import CustomButton from "@/components/atoms/CustomButton.vue";
import CustomFormInput from "@/components/atoms/CustomFormInput.vue";

export default {
  components: {
    CustomSelectInput,
    CustomButton,
    CustomFormInput
  },
  props: {
    service: {
      required: true
    },
    isEditing: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      innerServiceId: this.service.id,
      innerServiceName: this.service.name,
      innerServiceProfessionalFamily: this.service.professionalFamily,
      options: []
    };
  },
  watch: {
    innerServiceName() {
      this.editService();
    },
    innerServiceProfessionalFamily() {
      this.editService();
    }
  },
  async mounted() {
    await this.getProfessionalFamilies();
  },
  methods: {
    async getProfessionalFamilies() {
      return this.$store
        .dispatch("getProfessionalFamilies")
        .then(data => {
          this.options = data.map(professionalFamily => ({
            key: professionalFamily.name,
            value: professionalFamily.name
          }));
        })
        .catch(err => {
          console.log(err);
        });
    },
    editService() {
      this.$emit("editService", {
        index: this.index,
        service: {
          id: this.innerServiceId,
          name: this.innerServiceName,
          professionalFamily: this.innerServiceProfessionalFamily
        }
      });
    },
    deleteService() {
      this.$emit("deleteService", this.index);
    },
    createService() {
      this.$emit("createService", {
        id: this.innerServiceId,
        name: this.innerServiceName,
        professionalFamily: this.innerServiceProfessionalFamily
      });
      this.emptyAllInputs();
    },
    emptyAllInputs() {
      this.innerServiceName = "";
      this.innerServiceProfessionalFamily = "";
    }
  }
};
</script>
