<template>
  <label class="container">
    <input type="checkbox" @change="sendStatus" :checked="isChecked" />
    <span class="checkmark rounded"></span>
  </label>
</template>

<script>
export default {
  props: {
    isChecked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    sendStatus(event) {
      this.$emit("change", event.srcElement.checked);
    }
  }
};
</script>

<style>
.container {
  @apply block;
  @apply relative;
  @apply mr-5;
  @apply mb-4;
  @apply cursor-pointer;
  @apply text-2xl;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  @apply select-none;
}

.container input {
  @apply absolute;
  @apply opacity-0;
  @apply cursor-pointer;
  @apply h-0;
  @apply w-0;
}

.checkmark {
  @apply absolute;
  @apply top-0;
  @apply left-0;
  @apply h-6;
  @apply w-6;
  @apply bg-gris-clair;
  @apply cursor-pointer;
}

.container:hover input ~ .checkmark {
  @apply bg-gris-clair;
}

.checkmark:after {
  content: "";
  @apply absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  @apply block;
}

.container .checkmark:after {
  @apply left-2;
  @apply bottom-1;
  width: 10px;
  @apply h-5;
  @apply border-solid;
  @apply border-gris-moyen;
  @apply border-t-0;
  border-right-width: 2.5px;
  border-bottom-width: 2.5px;
  @apply border-l-0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  @apply transform;
  @apply rotate-45;
}
</style>
