import { Company } from "@/models/Company";
import {
  ExecutiveConventionalMinimums,
  ExecutiveConventionalMinimumsDto
} from "@/models/ExecutiveConventionalMinimums";
import {
  NewConventionalMinimums,
  UpdateNewConventionalMinimum,
  NewConventionalMinimumsDto
} from "@/models/NewConventionalMinimums";
import {
  NonExecutiveConventionalMinimums,
  NonExecutiveConventionalMinimumsDto
} from "@/models/NonExecutiveConventionalMinimums";
import { Territory } from "@/models/Territory";
import TerritoriesService from "@/services/TerritoriesService";

const territoriesService = new TerritoriesService();

const actions = {
  async getTerritoryCompanies(): Promise<Company[]> {
    return territoriesService.getTerritoryCompanies();
  },

  async getTerritories(): Promise<Territory[]> {
    return territoriesService.getTerritories();
  },

  async getNonExecutiveConventionalMinimums(): Promise<NonExecutiveConventionalMinimums[]> {
    return territoriesService.getNonExecutiveConventionalMinimums();
  },

  async updateNonExecutiveConventionalMinimums(
    _: any,
    minimum: NonExecutiveConventionalMinimumsDto
  ): Promise<NonExecutiveConventionalMinimumsDto> {
    return territoriesService.updateNonExecutiveConventionalMinimums(minimum);
  },

  async getExecutiveConventionalMinimums(): Promise<ExecutiveConventionalMinimums[]> {
    return territoriesService.getExecutiveConventionalMinimums();
  },

  async updateExecutiveConventionalMinimums(
    _: any,
    minimum: ExecutiveConventionalMinimumsDto
  ): Promise<ExecutiveConventionalMinimumsDto> {
    return territoriesService.updateExecutiveConventionalMinimums(minimum);
  },

  async getNewConventionalMinimums(): Promise<NewConventionalMinimums[]> {
    return territoriesService.getNewConventionalMinimums();
  },

  async updateNewConventionalMinimums(
    _: any,
    minimum: UpdateNewConventionalMinimum
  ): Promise<NewConventionalMinimumsDto> {
    return territoriesService.updateNewConventionalMinimums(minimum);
  }
};

export default {
  actions
};
