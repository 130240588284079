<template>
  <div>
    <ul>
      <li class="mb-5">
        <div>
          <custom-checkbox
            :is-checked="innerShowSalaryGrade"
            @change="innerShowSalaryGrade = $event"
          />
        </div>
        <div class="ml-8 font-bold text-input-label">Afficher les échelons</div>
      </li>
      <li class="mb-5">
        <div>
          <custom-checkbox :is-checked="innerShowCoef" @change="innerShowCoef = $event" />
        </div>
        <div class="ml-8 font-bold text-input-label">Afficher les coefficients</div>
      </li>
    </ul>

    <custom-button id="validate-params-button" name="VALIDER" class="mt-8" @onClick="validate" />
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
import CustomCheckbox from "../atoms/CustomCheckbox.vue";

export default {
  props: {
    showSalaryGrade: {
      type: Boolean,
      required: true
    },
    showCoef: {
      type: Boolean,
      required: true
    }
  },
  components: {
    CustomButton,
    CustomCheckbox
  },
  methods: {
    validate() {
      this.$emit("validate", {
        showSalaryGrade: this.innerShowSalaryGrade,
        showCoef: this.innerShowCoef
      });
      this.close();
    },
    close() {
      this.$emit("close");
    }
  },
  data() {
    return {
      innerModalTitle: this.modalTitle,
      innerShowSalaryGrade: this.showSalaryGrade,
      innerShowCoef: this.showCoef
    };
  }
};
</script>
