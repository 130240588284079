<template>
  <div class="relative">
    <div v-if="inputLabel" class="relative h-16 mb-3">
      <label
        :for="inputId"
        class="absolute text-input-label font-bold text-gris bottom-0 leading-5"
      >
        {{ inputLabel }}
      </label>
    </div>
    <input
      :id="inputId"
      type="number"
      v-model="inputValue"
      :class="inputClass"
      :placeholder="inputPlaceholder"
    />
    <img class="euro-icon" :src="$asset('icons/euro-icon.svg')" alt="euro-icon" />
  </div>
</template>

<script>
export default {
  props: {
    inputLabel: {
      type: String,
      required: false
    },
    inputId: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    inputPlaceholder: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      inputClass:
        "placeholder-gris-moyen " +
        "w-full " +
        "px-3 py-2 " +
        "block " +
        "border-0 " +
        "bg-secondary " +
        "focus:ring-black " +
        "text-input-label " +
        "rounded " +
        "font-normal " +
        "text-gris " +
        "h-8"
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.euro-icon {
  position: absolute;
  right: 0.8rem;
  bottom: 6px;
  height: 20px;
  margin: auto;
  font-size: 14px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
