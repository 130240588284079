var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-sub-view-title',{staticClass:"mt-16",attrs:{"title":"Temps et durée de travail"}},[_c('h2',{staticClass:"text-gris font-bold mt-10 text-2xl -mb-8"},[_vm._v(" Non-cadres ")]),_c('div',{staticClass:"flex items-center space-x-9"},[_c('div',{staticClass:"flex-1"},[_c('custom-form-input',{attrs:{"inputId":"employee-legal-working-time","inputLabel":"TEMPS DE TRAVAIL MENSUEL","inputPlaceholder":"Indiquez le temps de travail"},model:{value:(_vm.employee.nonExecutiveEmployeeLegalWorkingTime),callback:function ($$v) {_vm.$set(_vm.employee, "nonExecutiveEmployeeLegalWorkingTime", $$v)},expression:"employee.nonExecutiveEmployeeLegalWorkingTime"}})],1),_c('div',{staticClass:"flex-1"},[_c('custom-form-input',{attrs:{"inputId":"employee-day-package","inputLabel":"FORFAIT JOURS SUR L'ANNÉE ","inputPlaceholder":"Forfait en jours"},model:{value:(_vm.employee.nonExecutiveEmployeeDayPackageForTheYear),callback:function ($$v) {_vm.$set(_vm.employee, "nonExecutiveEmployeeDayPackageForTheYear", $$v)},expression:"employee.nonExecutiveEmployeeDayPackageForTheYear"}})],1),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"relative h-16 mb-3"},[_c('label',{staticClass:"absolute text-input-label font-bold text-gris bottom-0"},[_vm._v(" MODULATION DU TEMPS DE TRAVAIL (1607 HEURES) ")])]),_c('custom-select-input',{attrs:{"id":"employee-modulation","name":"modulation-input","options":[
            { value: 'Oui', key: 'Oui' },
            { value: 'Non', key: 'Non' }
          ],"defaultOption":'Modulation',"cssClass":"text-input font-normal"},model:{value:(_vm.employee.nonExecutiveEmployeeModulationOfWorkingTime),callback:function ($$v) {_vm.$set(_vm.employee, "nonExecutiveEmployeeModulationOfWorkingTime", $$v)},expression:"employee.nonExecutiveEmployeeModulationOfWorkingTime"}})],1)]),_c('div',{staticClass:"flex items-center space-x-9"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"relative h-16 mb-3"},[_c('label',{staticClass:"absolute text-input-label font-bold text-gris bottom-0"},[_vm._v(" TRAVAIL A TEMPS PARTIEL ")])]),_c('custom-select-input',{attrs:{"id":"employee-work-time","name":"work-time-input","options":[
            { value: '100%', key: '100%' },
            { value: '90%', key: '90%' },
            { value: '80%', key: '80%' },
            { value: '50%', key: '50%' }
          ],"defaultOption":'% de présence',"cssClass":"text-input font-normal"},model:{value:(_vm.employee.nonExecutiveEmployeePartTimeWork),callback:function ($$v) {_vm.$set(_vm.employee, "nonExecutiveEmployeePartTimeWork", $$v)},expression:"employee.nonExecutiveEmployeePartTimeWork"}})],1),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"relative h-16 mb-3"},[_c('label',{staticClass:"absolute text-input-label font-bold text-gris bottom-0"},[_vm._v(" TRAVAIL EN ÉQUIPES ")])]),_c('custom-select-input',{attrs:{"id":"employee-team-work","name":"team-work-input","options":[
            { value: 'Oui', key: 'Oui' },
            { value: 'Non', key: 'Non' }
          ],"defaultOption":'Travail en équipe',"cssClass":"text-input font-normal"},model:{value:(_vm.employee.nonExecutiveEmployeeTeamWork),callback:function ($$v) {_vm.$set(_vm.employee, "nonExecutiveEmployeeTeamWork", $$v)},expression:"employee.nonExecutiveEmployeeTeamWork"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }