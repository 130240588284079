<template>
  <div>
    <table aria-describedby="new-conventional-minimums" class="new-conventional-minimums-table">
      <tr class="text-center font-bold border-b-4 border-gris-moyen">
        <th scope="quote"><strong>Cotations</strong></th>
        <th scope="job-class"><strong>Classe d'emplois</strong></th>
        <th scope="job-group"><strong>Groupe d'emplois</strong></th>
        <th scope="minimum"><strong>Minimum conventionnel annuel</strong></th>
      </tr>
      <tbody
        v-for="conventionalMinimums in allConventionalMinimums"
        :key="conventionalMinimums.jobGroup"
      >
        <tr
          v-for="(convention, index) in conventionalMinimums.annualConvention"
          :key="convention.jobClass"
        >
          <td class="text-center">{{ convention.quote }}</td>
          <td class="text-center">{{ convention.jobClass }}</td>
          <td v-if="index === 0" rowspan="2" class="text-center">
            <strong> {{ jobGroupLetters.get(conventionalMinimums.jobGroup) }} </strong>
          </td>
          <td>
            <input
              type="number"
              v-model="convention.updatedConventionalMinimum"
              class="custom-input-grey-placeholder focus:ring-black mc-input"
              @keyup="
                updateNewConventionalMinimums(
                  convention.jobClass,
                  convention.updatedConventionalMinimum
                )
              "
            />
          </td>
        </tr>
        <div></div>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jobGroupLetters: new Map([
        [1, "A"],
        [2, "B"],
        [3, "C"],
        [4, "D"],
        [5, "E"],
        [6, "F"],
        [7, "G"],
        [8, "H"],
        [9, "I"]
      ]),
      newConventionalMinimums: []
    };
  },
  computed: {
    allConventionalMinimums() {
      return this.newConventionalMinimums;
    }
  },
  methods: {
    async getNewConventionalMinimums() {
      this.newConventionalMinimums = await this.$store.dispatch("getNewConventionalMinimums");
    },
    updateNewConventionalMinimums: async function(jobClass, minimum) {
      await this.$store.dispatch("updateNewConventionalMinimums", {
        jobClass,
        updatedConventionalMinimum: minimum ? minimum : "0"
      });
    }
  },
  async mounted() {
    await this.getNewConventionalMinimums();
  }
};
</script>

<style>
.new-conventional-minimums-table {
  @apply w-full;
  @apply border-collapse;
}
.new-conventional-minimums-table td,
th {
  @apply p-2;
  @apply border-2;
  @apply border-gris-moyen;
}
.mc-input::-webkit-outer-spin-button,
.mc-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  @apply m-0;
}
</style>
