<template>
  <div class="p-subview overflow-hidden">
    <custom-loader v-if="loading" message="loading..." />
    <custom-success-toast :toastInfos="successToastInfos" v-if="successToastInfos.isShowed" />
    <div
      v-for="error in errors"
      :key="error"
      class="
        border
        text-xs
        border-error-red
        text-error-red
        px-2
        py-1
        rounded
        relative
        mb-2
      "
      role="alert"
    >
      <span class="block sm:inline"> {{ error }} </span>
    </div>

    <div v-if="companyId">
      <div v-if="employees.length !== 0 || isCreating === true">
        <div class="flex relative line-draw-container mb-16">
          <div class="flex self-center border-b-2 border-gris-moyen w-full z-0"></div>
          <h3
            class="
              absolute
              title-line
              font-bold
              z-10
              px-4
              bg-white
              text-xl
              uppercase
            "
          >
            {{ selectedEmployee.registrationNumber }}
          </h3>
          <div class="absolute input-line flex items-center">
            <custom-select-input
              name="professional-family"
              id="professional-family"
              :options="employeeSelectOptions"
              v-model="selectedEmployeeIdValue"
              :defaultOption="'Sélectionner un salarié'"
              cssClass="text-input font-normal w-auto"
            />
            <custom-button
              id="create-employee"
              :imgSource="$asset('icons/plus-icon.svg')"
              altSource="plus-button"
              class="ml-12"
              padding="square-button"
              @onClick="createNewEmployee"
            />
          </div>
        </div>

        <div class="flex relative line-draw-container">
          <div class="flex self-center border-b-2 border-gris-moyen w-full z-0"></div>
          <div
            class="
              absolute
              buttons-line
              w-full
              justify-center
              z-10
              flex
              space-x-24
              text-gris
            "
          >
            <h3
              class="
                font-bold
                z-10
                px-4
                bg-white
                text-xl
                uppercase
                cursor-pointer
              "
              :class="activeTab === 'actual' ? '' : ' text-gris-moyen'"
              @click="changeTab('actual')"
            >
              Situation actuelle
            </h3>
            <h3
              class="
                font-bold
                z-10
                px-4
                bg-white
                text-xl
                uppercase
                cursor-pointer
              "
              :class="activeTab === 'recommendations' ? '' : ' text-gris-moyen'"
              @click="changeTab('recommendations')"
            >
              Recommandations
            </h3>
          </div>
        </div>

        <employees-actual
          :employee="selectedEmployee"
          :company="company"
          :setLoading="setLoading"
          v-if="activeTab === 'actual'"
        />

        <employees-recommendations
          :employeeRecommendationId="employeeRecommendationId"
          :company="company"
          :employeeContractType="selectedEmployee.contractType"
          @recommendationsChanged="recommendationsChanged($event)"
          :setLoading="setLoading"
          v-if="activeTab === 'recommendations'"
        />

        <div class="flex mt-20 space-x-9 justify-end">
          <custom-button
            v-if="activeTab === 'actual' && this.selectedEmployee.id"
            id="delete"
            name="SUPPRIMER LA FICHE"
            @onClick="deleteEmployeeActual"
          />
          <custom-button
            id="save"
            name="ENREGISTRER"
            :disabled="!selectedEmployee.registrationNumber"
            @onClick="saveEmployee"
          />
        </div>
      </div>

      <no-employees
        v-if="employees.length === 0 && isCreating === false"
        @createNewEmployee="createNewEmployee"
      />
    </div>

    <information
      v-else
      message="Veuillez créer une entreprise afin d'accéder à cette fonctionnalité"
    />
  </div>
</template>

<script>
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";
import NoEmployees from "@/components/pages/forms/sub-views/employees/NoEmployees.vue";
import EmployeesActual from "@/components/pages/forms/sub-views/employees/EmployeesActual.vue";
import CustomButton from "@/components/atoms/CustomButton.vue";
import EmployeesRecommendations from "@/components/pages/forms/sub-views/employees/EmployeesRecommendations.vue";
import Information from "@/components/organisms/Information.vue";
import CustomLoader from "@/shared/CustomLoader.vue";
import CustomSuccessToast from "@/shared/CustomSuccessToast.vue";

export default {
  components: {
    CustomSelectInput,
    NoEmployees,
    EmployeesActual,
    CustomButton,
    EmployeesRecommendations,
    Information,
    CustomLoader,
    CustomSuccessToast
  },
  async mounted() {
    if (this.companyId) {
      await this.getAllEmployees();
    }
  },
  data() {
    return {
      companyId: this.$route.query.companyId || "",
      company: {},
      employees: [],
      errors: [],
      activeTab: "actual",
      selectedEmployeeIdValue: this.$route.query.employeeId || "",
      isCreating: false,
      selectedEmployee: {},
      loading: false,
      successToastInfos: {
        isShowed: this.$route.query.showSuccessToast || false,
        message: this.$route.query.successToastMessage || "success"
      }
    };
  },
  watch: {
    selectedEmployeeIdValue: {
      immediate: true,
      async handler() {
        if (this.selectedEmployeeIdValue?.length) {
          this.loading = true;
          const employee = await this.$store.dispatch("getOneEmployee", {
            companyId: this.companyId,
            id: this.selectedEmployeeIdValue
          });
          this.loading = false;
          this.initEmployeeObject(employee);
        }
      }
    },
    companyId: {
      immediate: true,
      async handler() {
        if (this.companyId) {
          const foundCompany = await this.$store.dispatch("getCompany", this.companyId);

          this.company = {
            name: foundCompany.name,
            geographicalDepartement: foundCompany.geographicalDepartement,
            employeesNumber: foundCompany.employeesNumber,
            usedClassification: foundCompany.usedClassification,
            services: foundCompany.services
              ? foundCompany.services.map(service => ({
                  id: service.id,
                  name: service.name
                }))
              : []
          };
        }
      }
    }
  },
  methods: {
    changeTab(value) {
      this.activeTab = value;
    },

    recommendationsChanged(recommendations) {
      this.selectedEmployee.employeeRecommendation = recommendations;
    },

    async saveEmployee() {
      this.loading = true;
      this.errors = [];
      (this.selectedEmployeeIdValue ? this.updateEmployee() : this.createEmployee())
        .finally(() => {
          this.loading = false;
        })
        .then(successMessage => {
          this.successToastInfos.message = successMessage;
          this.successToastInfos.isShowed = true;
        })
        .catch(err => {
          const errorMessage = err.response.data.message;
          this.errors.unshift(errorMessage);
        });
    },

    async updateEmployee() {
      this.checkProfessionalFamilyAndService();
      const updatedEmployee = await this.$store.dispatch("updateEmployee", {
        companyId: this.companyId,
        id: this.selectedEmployeeIdValue,
        employee: this.selectedEmployee
      });
      this.initEmployeeObject(updatedEmployee);
      this.selectedEmployeeIdValue = updatedEmployee.id;
      return "salarié mis à jour avec succès";
    },

    async createEmployee() {
      this.checkProfessionalFamilyAndService();
      const createdEmployee = await this.$store.dispatch("createEmployee", {
        companyId: this.companyId,
        employee: this.selectedEmployee
      });
      this.employees.push(createdEmployee);
      this.initEmployeeObject(createdEmployee);
      this.selectedEmployeeIdValue = createdEmployee.id;
      return "salarié créé avec succès !";
    },

    async deleteEmployeeActual() {
      await this.$store.dispatch("deleteEmployee", {
        companyId: this.companyId,
        id: this.selectedEmployee.id
      });
      const idx = this.employees.findIndex(emp => emp.id === this.selectedEmployee.id);
      this.employees.splice(idx, 1);
      this.selectedEmployee = {};
      this.selectedEmployeeIdValue = "";
    },

    createNewEmployee() {
      this.isCreating = true;
      this.initEmployeeObject({});
      this.selectedEmployee.employeeRecommendation = {};
      this.selectedEmployeeIdValue = "";
    },

    async getAllEmployees() {
      const allEmployees = await this.$store.dispatch("getManyEmployees", this.companyId);
      this.employees = allEmployees;
      if (this.employees.length && this.selectedEmployeeIdValue.length === 0) {
        this.selectedEmployeeIdValue = this.employees[0].id;
      }
    },

    initEmployeeObject(employeeData) {
      // as some employeeData fields could be null we have to initialize the values bellow
      this.selectedEmployee = {
        ...employeeData,
        coef: { value: employeeData.coef?.value } || { value: null },
        jobProfessionalFamily: employeeData?.jobProfessionalFamily || { name: null },
        service: employeeData?.service || { name: null },
        socioProfessionalCategory: employeeData?.socioProfessionalCategory || {
          name: null
        }
      };
    },

    checkProfessionalFamilyAndService() {
      if (
        !this.selectedEmployee.jobProfessionalFamily.name ||
        !this.selectedEmployee.service.name
      ) {
        const emptyNameObject = { name: null };
        this.selectedEmployee.jobProfessionalFamily = emptyNameObject;
        this.selectedEmployee.service = emptyNameObject;
      }
    },
    setLoading(value) {
      this.loading = value;
    }
  },
  computed: {
    employeeSelectOptions() {
      return this.employees.length
        ? this.employees
            .map(empl => ({
              key: `${empl.registrationNumber}`,
              value: empl.id
            }))
            .sort((e1, e2) => parseInt(e1.registrationNumber) - parseInt(e2.registrationNumber))
        : [];
    },

    employeeRecommendationId() {
      return this.selectedEmployee.employeeRecommendation?.id;
    }
  }
};
</script>

<style lang="scss" scoped>
.title-line {
  left: 100px;
  top: -10px;
}

.input-line {
  left: 74%;
  top: -17px;
}

.buttons-line {
  top: -10px;
}
</style>
