<template>
  <div class="bg-gris-clair">
    <password-recovery
      v-if="showRecovery"
      @close="showRecovery = false"
      :territories="territories"
      :territory="territory"
      :email="email"
    />
    <div class="min-h-screen flex items-center justify-center">
      <div class="bg-couleur-blanc rounded-lg max-w-xl w-full h-38 px-28 py-10">
        <div>
          <div class="flex items-center justify-center mb-10">
            <img class="h-lg" :src="$asset('icons/logo-uimm.svg')" alt="UIMM LOGO" />
          </div>

          <h2 class="mt-16 mb-6 text-center text-2xl font-bold text-gris">
            Connexion
          </h2>

          <div
            v-show="loginError.length"
            class="
              border
              text-xs
              border-error-red
              text-error-red
              px-2
              py-1
              rounded
              relative
            "
            role="alert"
          >
            <span class="block sm:inline"> {{ loginError }} </span>
          </div>
          <br />
        </div>

        <form class="space-y-6" @submit.prevent="login">
          <div>
            <div>
              <custom-select-input
                label="TERRITOIRE"
                name="territories"
                id="territories"
                :options="territories"
                defaultOption="Sélectionnez un territoire de rattachement"
                v-model="territory"
              />
            </div>

            <br />

            <div>
              <label for="password" class="block text-xs font-medium text-gris mb-3 font-bold">
                ADRESSE MAIL
              </label>

              <input
                id="email-address"
                name="email"
                type="email"
                autocomplete="email"
                required=""
                v-model="email"
                class="custom-input focus:ring-black"
              />
            </div>

            <br />

            <div>
              <label for="password" class="block text-xs font-medium text-gris mb-3 font-bold">
                MOT DE PASSE
              </label>

              <input
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required=""
                v-model="password"
                class="custom-input focus:ring-black"
              />
            </div>

            <div class="text-xs">
              <a
                id="passwordRecovery"
                class="font-light text-gris underline cursor-pointer leading-8"
                @click="showRecovery = true"
              >
                mot de passe oublié
              </a>
            </div>
          </div>

          <div class="flex flex-col items-center">
            <div>
              <button
                id="validate-btn"
                type="submit"
                :class="[
                  'submit-button bg-gris text-md',
                  'disabled:bg-button-disabled',
                  'disabled:cursor-default'
                ]"
                :disabled="territory === '' || email === '' || password === ''"
              >
                VALIDER
              </button>
            </div>

            <div>
              <a
                class="
                  font-light
                  text-xs text-gris
                  underline
                  cursor-pointer
                  pt-2
                "
                @click="goToWelcomePage"
              >
                Pas encore de compte ? S'inscrire
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordRecovery from "@/components/molecules/PasswordRecovery.vue";
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";

export default {
  components: {
    PasswordRecovery,
    CustomSelectInput
  },
  data() {
    return {
      email: "",
      password: "",
      territory: "",
      territories: [],
      loginError: "",
      showRecovery: false
    };
  },
  async mounted() {
    await this.getTerritories();
  },
  methods: {
    async getTerritories() {
      await this.$store
        .dispatch("getTerritories")
        .then(allTerritories => {
          this.territories = allTerritories.map(territory => ({
            key: territory.name,
            value: territory.name
          }));
        })
        .catch(err => {
          console.log(err);
        });
    },
    async login() {
      this.loginError = "";

      const { email } = this;
      const { password } = this;
      const { territory } = this;

      await this.$store
        .dispatch("authRequest", { email, password, territory })
        .then(() => {
          this.$router.push("/");
        })
        .catch(err => {
          this.loginError = "Le mot de passe ou l'adresse mail est incorrect.";
          console.log(err);
        });
    },
    goToWelcomePage() {
      this.$router.push("/welcome");
    }
  }
};
</script>
