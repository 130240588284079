<template>
  <div>
    <div class="mb-10">
      <custom-sub-view-title title="Analyse des éléments de rémunération" />
      <div class="relative">
        <div class="flex absolute items-center right-0 space-x-2 -mt-5">
          <custom-button
            id="export-in-png"
            name="PNG"
            fontSize="text-base"
            @onClick="exportTable"
          />
        </div>
      </div>
    </div>
    <div ref="exportedTable" class="bg-couleur-blanc text-gris">
      <table aria-describedby="income-analysis-table" class="income-analysis-table" v-if="fetched">
        <tr class="text-center py-7">
          <th scope="empty" colspan="2"></th>
          <th scope="number">Matricule</th>
          <th scope="job">Postes</th>
          <template v-if="usedClassification === 'Classification Actuelle'">
            <th scope="niveau" class="rotated">
              <p><strong>Niveau</strong></p>
            </th>
            <th scope="echelon" class="rotated">
              <p><strong>Echelon</strong></p>
            </th>
            <th scope="coeff" class="rotated">
              <p><strong>Coefficient</strong></p>
            </th>
          </template>
          <template v-if="usedClassification !== 'Classification Actuelle'">
            <th scope="job-group"><strong>Groupe - Classe d'emplois</strong></th>
          </template>
          <th scope="annual-income"><strong>Salaire annuel</strong></th>
          <th scope="all-included-income"><strong>Salaire annuel tout compris</strong></th>
          <th scope="territory-min-income"><strong>Salaire min du territoire</strong></th>
          <th scope="income-differences">
            <strong>
              Comparatif salaire réel & salaire min sur le territoire
            </strong>
          </th>
          <th scope="comments"><strong>Commentaire</strong></th>
        </tr>
        <template v-for="professionalFamily in allProfessionalFamily">
          <template v-for="(service, index) in professionalFamily.services">
            <tr
              v-for="(employee, employeeIndex) in service.employees"
              :key="`${service.id}-${employee.id}`"
            >
              <td
                v-if="index === 0 && employeeIndex === 0"
                class="text-center font-bold rotated"
                :rowspan="getNumberOfEmployeesInAProfessionalFamily(professionalFamily)"
              >
                <p>{{ professionalFamily.name }}</p>
              </td>
              <td
                v-if="employeeIndex === 0"
                class="text-center font-bold rotated"
                :rowspan="service.employees.length"
              >
                <p>{{ service.name }}</p>
              </td>
              <td>{{ employee.registrationNumber }}</td>
              <td>{{ employee.currentJobTitle }}</td>
              <template v-if="usedClassification === 'Classification Actuelle'">
                <td>{{ romanValues.get(employee.level) }}</td>
                <td>{{ employee.salaryGrade }}</td>
                <td>{{ getEmployeCoefficient(employee) }}</td>
              </template>
              <template v-if="usedClassification !== 'Classification Actuelle'">
                <td>{{ employee.jobGroupAndClass }}</td>
              </template>
              <td>{{ getAnnualPay(employee) }}</td>
              <td>{{ getAllincludedAnnualPay(employee) }}</td>
              <td>
                {{
                  getTerritoryMinimumSalary(employee)
                    ? getTerritoryMinimumSalary(employee) + "€"
                    : "N/A"
                }}
              </td>
              <td :class="getComparedSalary(employee) < 0 ? 'bg-couleur-orange-30' : ''">
                {{ getComparedSalary(employee) ? getComparedSalary(employee) + "€" : "N/A" }}
              </td>
              <td class="textinput p-0 relative w-1/5">
                <textarea
                  type="text"
                  v-if="!recommendation"
                  v-model="employee.comment"
                  placeholder="Ajouter un commentaire"
                  class="textarea-grey-placeholder"
                  @keyup="updateEmployeeComment(employee.id, employee.comment)"
                />
                <textarea
                  type="text"
                  v-if="recommendation"
                  v-model="employee.recommendationComment"
                  placeholder="Ajouter un commentaire"
                  class="textarea-grey-placeholder"
                  @keyup="updateEmployeeComment(employee.id, employee.recommendationComment)"
                />
              </td>
            </tr>
          </template>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import debounce from "lodash.debounce";

import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";
import CustomButton from "@/components/atoms/CustomButton.vue";

import { saveTable } from "@/utils";

export default {
  props: {
    companyId: {
      required: true
    },
    settings: {
      required: true
    },
    recommendation: {
      type: Boolean,
      required: false,
      default: false
    },
    usedClassification: {
      required: true
    },
    setLoading: {
      required: true
    }
  },
  components: {
    CustomSubViewTitle,
    CustomButton
  },
  data() {
    return {
      innerCompanyId: this.$route.query.companyId,
      employeesIds: [],
      allProfessionalFamily: [],
      fetched: false,
      nonExecutiveConventionalMinimums: [],
      executiveConventionalMinimums: [],
      newConventionalMinimums: [],
      romanValues: new Map([
        ["1", "I"],
        ["2", "II"],
        ["3", "III"],
        ["31", "III A"],
        ["32", "III B"],
        ["33", "III C"],
        ["4", "IV"],
        ["5", "V"]
      ]),
      jobGroupLetterToNumeric: {
        A: 1,
        B: 2,
        C: 3,
        D: 4,
        E: 5,
        F: 6,
        G: 7,
        H: 8,
        I: 9
      }
    };
  },
  methods: {
    retrieveSettings() {
      this.settings = JSON.parse(localStorage.getItem("settings"));
    },
    getNumberOfEmployeesInAProfessionalFamily(professionalFamily) {
      let numberOfEmployees = 0;
      professionalFamily.services.forEach(service => {
        numberOfEmployees += service.employees.length;
      });
      return numberOfEmployees;
    },
    async getNonExecutiveConventionalMinimums() {
      this.nonExecutiveConventionalMinimums = await this.$store.dispatch(
        "getNonExecutiveConventionalMinimums"
      );
    },
    async getExecutiveConventionalMinimums() {
      this.executiveConventionalMinimums = await this.$store.dispatch(
        "getExecutiveConventionalMinimums"
      );
    },
    async getNewConventionalMinimums() {
      this.newConventionalMinimums = await this.$store.dispatch("getNewConventionalMinimums");
    },
    async getProfessionalFamiliesEmployees() {
      this.fetched = false;
      if (this.innerCompanyId) {
        const allEmployees = await this.$store.dispatch("getManyEmployees", this.innerCompanyId);
        const apprenticeshipEmployeesID = allEmployees
          .filter(employee => employee.contractType === "Alternance")
          .map(employee => employee.id);
        const searchedIds = this.employeesIds.filter(
          id => apprenticeshipEmployeesID.includes(id) === false
        );
        this.allProfessionalFamily = await this.$store.dispatch(
          "getProfessionalFamiliesEmployees",
          {
            id: this.innerCompanyId,
            settings: {
              employeesIds: searchedIds,
              professionalFamilies: this.settings
            }
          }
        );
        if (this.recommendation) {
          this.updateRecommendationEmployees();
        }
        this.fetched = true;
      }
    },
    updateRecommendationEmployees() {
      for (const family of this.allProfessionalFamily) {
        for (const service of family.services) {
          for (const employee of service.employees) {
            employee.currentJobTitle = employee.employeeRecommendation?.currentJobTitle;
            employee.level = employee.employeeRecommendation?.nonExecutiveLevel;
            employee.salaryGrade = employee.employeeRecommendation?.nonExecutiveSalaryGrade;
            employee.jobGroupAndClass = employee.employeeRecommendation?.jobGroupAndClass;
            if (employee.coef) {
              employee.coef.value = employee.employeeRecommendation?.coef?.value;
            } else if (employee.employeeRecommendation?.coef) {
              employee.coef = { value: employee.employeeRecommendation?.coef.value };
            }
            employee.index = employee.employeeRecommendation?.executiveIndex;
            employee.position = employee.employeeRecommendation?.executivePosition;
            employee.executiveEmployeeBaseSalaryForThirtyFiveHours =
              employee.employeeRecommendation?.executiveEmployeeBaseSalaryForThirtyFiveHours;
            employee.nonExecutiveEmployeeBaseSalary =
              employee.employeeRecommendation?.nonExecutiveEmployeeBaseSalary;
            employee.grossSalaryAllIncludedWithoutSeniorityAndExceptionalBonus =
              employee.employeeRecommendation?.grossSalaryAllIncludedWithoutSeniorityAndExceptionalBonus;
          }
        }
      }
    },

    updateEmployeeComment: debounce(async function(employeeId, employeeComment) {
      const event = !this.recommendation
        ? "updateEmployeeCurrentSituationComment"
        : "updateEmployeeRecommendationComment";
      if (employeeComment !== "") {
        await this.$store.dispatch(event, {
          companyId: this.innerCompanyId,
          employeeID: employeeId,
          updateComment: {
            comment: employeeComment
          }
        });
      }
    }, 1000),
    isEmployeeExecutive(employee) {
      return employee.socioProfessionalCategory?.name === "cadre";
    },
    getTerritoryMinimumSalary(employee) {
      if (this.usedClassification === "Classification Actuelle") {
        return this.getOldClassificationMinimum(employee);
      }
      return this.getNewConventionalMinimum(employee.jobGroupAndClass);
    },
    getOldClassificationMinimum(employee) {
      if (this.isEmployeeExecutive(employee)) {
        return this.getExecutiveConventionalMinimum(employee);
      }
      return this.getNonExecutiveConventionalMinimum(
        employee.level,
        employee.salaryGrade,
        employee.coef?.value
      );
    },
    getNonExecutiveConventionalMinimum(level, salaryGrade, coef) {
      if (!coef) {
        return null;
      }
      const conventionalMinimum = this.nonExecutiveConventionalMinimums.find(
        necm => necm.position === parseInt(level)
      );
      if (!conventionalMinimum) {
        return null;
      }
      const annualConvention = conventionalMinimum.conventionAnnuel.find(
        convention => convention.echelon === salaryGrade && convention.coefficient === coef
      );
      if (!annualConvention) {
        return null;
      }
      return annualConvention.minimumsConventionnelsAnnuel;
    },
    getExecutiveConventionalMinimum(employee) {
      const conventionalMinimum = this.executiveConventionalMinimums.find(
        necm => necm.position === parseInt(employee.position)
      );
      if (!conventionalMinimum) {
        return null;
      }
      const annualConvention = conventionalMinimum.conventionAnnuel.find(
        convention => convention.index === parseInt(employee.index, 10)
      );
      if (!annualConvention) {
        return null;
      }
      return this.getExecutiveConventionalMinimumValue(employee, annualConvention);
    },
    getNewConventionalMinimum(employeeJobGroupAndClass) {
      if (!employeeJobGroupAndClass) return null;
      const jobGroupAndClass = employeeJobGroupAndClass.replace(/ /g, "").split("-");
      const conventionalMinimum = this.newConventionalMinimums.find(
        ncm => ncm.jobGroup === this.jobGroupLetterToNumeric[jobGroupAndClass[0]]
      );
      if (!conventionalMinimum) {
        return null;
      }
      const annualConvention = conventionalMinimum.annualConvention.find(
        convention => convention.jobClass === parseInt(jobGroupAndClass[1])
      );
      if (!annualConvention) {
        return null;
      }
      return annualConvention.updatedConventionalMinimum;
    },
    getExecutiveConventionalMinimumValue(employee, annualConvention) {
      if (employee.hasExecutiveEmployeeBaseSalary === "Oui") {
        return annualConvention.thirthyFiveHours;
      }
      const rateTypes = new Map([
        ["Forfait en heures sur l’année (1767h à 1927h)", annualConvention.from1767to1927Hours],
        ["Forfait en heures sur l’année (1607h à 1767h)", annualConvention.from1607to1767Hours],
        ["Forfait jours annuel base 218 jours", annualConvention.annualDaysRate],
        ["Forfait sans référence horaire base 218 jours", annualConvention.noReferenceRate]
      ]);
      return rateTypes.get(employee.rateType);
    },
    getComparedSalary(employee) {
      if (!this.getTerritoryMinimumSalary(employee)) {
        return null;
      }
      if (this.isEmployeeExecutive(employee)) {
        if (!employee.executiveEmployeeBaseSalaryForThirtyFiveHours) {
          return null;
        }
        return (
          employee.executiveEmployeeBaseSalaryForThirtyFiveHours -
          this.getTerritoryMinimumSalary(employee)
        );
      }
      if (!employee.grossSalaryAllIncludedWithoutSeniorityAndExceptionalBonus) {
        return null;
      }
      return (
        employee.grossSalaryAllIncludedWithoutSeniorityAndExceptionalBonus -
        this.getTerritoryMinimumSalary(employee)
      );
    },
    getEmployeCoefficient(employee) {
      return employee.coef ? employee.coef.value : "";
    },
    getAnnualPay(employee) {
      if (this.isEmployeeExecutive(employee)) {
        return employee.executiveEmployeeBaseSalaryForThirtyFiveHours
          ? employee.executiveEmployeeBaseSalaryForThirtyFiveHours + "€"
          : "";
      }
      return employee.nonExecutiveEmployeeBaseSalary
        ? employee.nonExecutiveEmployeeBaseSalary + "€"
        : "";
    },
    getAllincludedAnnualPay(employee) {
      if (this.isEmployeeExecutive(employee)) {
        return employee.executiveEmployeeBaseSalaryForThirtyFiveHours
          ? employee.executiveEmployeeBaseSalaryForThirtyFiveHours + "€"
          : "";
      }
      return employee.grossSalaryAllIncludedWithoutSeniorityAndExceptionalBonus
        ? employee.grossSalaryAllIncludedWithoutSeniorityAndExceptionalBonus + "€"
        : "";
    },
    exportTable() {
      const node = this.$refs.exportedTable;
      saveTable(node, "income-analysis");
    }
  },
  watch: {
    async settings() {
      await this.getProfessionalFamiliesEmployees();
    }
  },
  async mounted() {
    this.setLoading && this.setLoading(true);
    this.employeesIds = localStorage.getItem(`${this.innerCompanyId}-employees-ids`)
      ? JSON.parse(localStorage.getItem(`${this.innerCompanyId}-employees-ids`))
      : [];
    await this.getNonExecutiveConventionalMinimums();
    await this.getExecutiveConventionalMinimums();
    await this.getNewConventionalMinimums();
    await this.getProfessionalFamiliesEmployees();
    this.setLoading && this.setLoading(false);
  }
};
</script>

<style lang="scss" scoped>
.income-analysis-table {
  @apply w-full;
  @apply border-collapse;

  td,
  th {
    @apply p-2;
    @apply border-2;
    @apply border-gris-moyen;
  }
  .textinput {
    padding: 0 !important;
  }
  .rotated {
    @apply text-center;
    @apply whitespace-nowrap;
    @apply align-middle;
    @apply w-10;
    @apply h-24;

    p {
      -moz-transform: rotate(-90deg); /* FF3.5+ */
      -o-transform: rotate(-90deg); /* Opera 10.5 */
      -webkit-transform: rotate(-90deg); /* Saf3.1+, Chrome */
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083); /* IE6,IE7 */
      -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)"; /* IE8 */
      @apply -ml-40;
      @apply -mr-40;
    }
  }
}
</style>
