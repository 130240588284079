<template>
  <div class="p-subview">
    <div v-if="allTerritoryCompanies && allTerritoryCompanies.length > 0">
      <div id="CompaniesAverageSize">
        <custom-sub-view-title title="Taille moyenne des entreprises" />
        <div class="flex">
          <div class="w-1/2">
            <CustomPieChart
              v-if="fetched"
              chartName="Taille moyenne des entreprises"
              :inputData="averageSizes"
              class="-ml-20 -mt-10"
            />
          </div>
          <div class="font-bold flex flex-col justify-center text-gris">
            <div class="flex w-auto items-center">
              <h1 class="text-7xl mr-5">{{ followedCompanies }}</h1>
              <h2 class="text-xl">
                Entreprise{{ followedCompanies > 1 ? "s" : "" }} sur le territoire
              </h2>
            </div>
            <div class="flex w-96 items-center">
              <h1 class="text-7xl mr-5">{{ nbrOfEmployees }}</h1>
              <h2 class="text-xl">Salariés sur le territoire</h2>
            </div>
          </div>
        </div>
      </div>
      <div id="Alerts">
        <custom-sub-view-title title="Alertes" class="mb-sub-views-spacing" />
        <div class="w-72 ml-12">
          <CustomSelectInput
            label=""
            name="companiesSizes"
            id="companiesSizes"
            :options="companiesSizes"
            defaultOption="Taille de l'entreprise"
            v-model="selectedSize"
          />
        </div>
        <div class="flex">
          <div class="w-1/2">
            <CustomPieChart
              v-if="fetched"
              chartName="Nombre d'entreprises"
              :inputData="alertsData"
              class="-ml-20"
            />
          </div>
          <div class="font-bold flex w-auto items-center text-gris">
            <h1 class="text-7xl mr-5">{{ alertsByCompanies }}</h1>
            <h2 class="text-xl">Alertes par entreprise en moyenne</h2>
          </div>
        </div>
      </div>
      <div id="followedCompaniesCards">
        <custom-sub-view-title
          title="Liste des entreprises du territoire"
          class="mb-sub-views-spacing"
        />
        <div class="flex flex-wrap">
          <div v-for="company in allTerritoryCompanies" :key="company.id" class="m-2">
            <div class="bg-white w-36 h-28 border-2 shadow-xl flex p-1.5">
              <img
                :src="company.logo || $asset('icons/default_company_logo.png')"
                alt="Company's logo"
                class="max-h-full m-auto"
              />
            </div>
            <h1 class="mt-3 text-center text-gris">{{ getFormatedCompanyName(company) }}</h1>
            <h1 v-if="company.longName !== ''" class="text-center text-gris">
              {{ company.longName }}
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="p-subview">
      <information message="Aucune entreprise sur le territoire" />
    </div>
  </div>
</template>

<script>
import CustomPieChart from "@/components/atoms/CustomPieChart.vue";
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";
import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";
import Information from "@/components/organisms/Information.vue";

export default {
  components: {
    CustomPieChart,
    CustomSelectInput,
    CustomSubViewTitle,
    Information
  },
  data() {
    return {
      followedCompanies: 0,
      nbrOfEmployees: 0,
      alertsByCompanies: 0,
      fetched: false,
      allTerritoryCompanies: [],
      averageSizes: [],
      alertsData: [],
      companiesSizes: [
        { key: "Toutes les tailles d'entreprise", value: "Toutes les tailles d'entreprise" }
      ],
      allSizes: [
        "0 - 50 Salariés",
        "50 - 100 Salariés",
        "100 - 250 Salariés",
        "Plus de 250 Salariés"
      ],
      selectedSize: "Toutes les tailles d'entreprise",
      userId: ""
    };
  },
  methods: {
    async getTerritoryCompanies() {
      return this.$store
        .dispatch("getTerritoryCompanies")
        .then(territoryCards => {
          this.allTerritoryCompanies = territoryCards;
          this.nbrOfEmployees = this.getNbrOfEmployees();
          this.averageSizes = this.getAverageSizes();
          this.followedCompanies = this.allTerritoryCompanies.length;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getNbrOfEmployees() {
      let nbrOfEmployees = 0;
      this.allTerritoryCompanies.forEach(company => {
        nbrOfEmployees += company.employeesNumber;
      });
      return nbrOfEmployees;
    },
    getAverageSizes() {
      const small = this.allTerritoryCompanies.filter(company => company.employeesNumber <= 50)
        .length;
      const medium = this.allTerritoryCompanies.filter(
        company => company.employeesNumber > 50 && company.employeesNumber <= 250
      ).length;
      const large = this.allTerritoryCompanies.filter(company => company.employeesNumber > 250)
        .length;
      return [
        { value: small, name: "- 50 salariés" },
        { value: medium, name: "50 - 250 salariés" },
        { value: large, name: "+ 250 salariés" }
      ];
    },
    getAverageAlerts() {
      let totalNumberOfAlerts = 0;
      this.allTerritoryCompanies.forEach(company => {
        totalNumberOfAlerts += company.alerts.length;
      });
      return Math.round((totalNumberOfAlerts / this.allTerritoryCompanies.length) * 100) / 100 || 0;
    },
    getFilteredCompanies(size) {
      const filteredCompanies = new Map([
        ["Toutes les tailles d'entreprise", () => this.allTerritoryCompanies],
        [
          "0 - 50 Salariés",
          () => this.allTerritoryCompanies.filter(company => company.employeesNumber <= 50)
        ],
        [
          "50 - 100 Salariés",
          () =>
            this.allTerritoryCompanies.filter(
              company => company.employeesNumber > 50 && company.employeesNumber <= 100
            )
        ],
        [
          "100 - 250 Salariés",
          () =>
            this.allTerritoryCompanies.filter(
              company => company.employeesNumber > 100 && company.employeesNumber <= 250
            )
        ],
        [
          "Plus de 250 Salariés",
          () => this.allTerritoryCompanies.filter(company => company.employeesNumber > 250)
        ]
      ]);
      return filteredCompanies.get(size)();
    },
    getNbrOfAlertsByCompaniesAndFilter(companies, start, end) {
      if (end) {
        return companies.filter(
          company => company.alerts.length < end && company.alerts.length >= start
        ).length;
      }
      return companies.filter(company => company.alerts.length >= start).length;
    },
    getAlertsData() {
      const companies = this.getFilteredCompanies(this.selectedSize);
      return [
        {
          value: this.getNbrOfAlertsByCompaniesAndFilter(companies, 0, 10),
          name: "0 - 10 alertes"
        },
        {
          value: this.getNbrOfAlertsByCompaniesAndFilter(companies, 10, 20),
          name: "10 - 20 alertes"
        },
        {
          value: this.getNbrOfAlertsByCompaniesAndFilter(companies, 20, 30),
          name: "20 - 30 alertes"
        },
        {
          value: this.getNbrOfAlertsByCompaniesAndFilter(companies, 30, 40),
          name: "30 - 40 alertes"
        },
        { value: this.getNbrOfAlertsByCompaniesAndFilter(companies, 40), name: "+ 40 alertes" }
      ];
    },
    updateCompanySize() {
      for (const size of this.allSizes) {
        const companies = this.getFilteredCompanies(size);
        if (companies.length > 0) {
          this.companiesSizes.push({ key: size, value: size });
        }
      }
    },
    getFormatedCompanyName(company) {
      if (company.name.length <= 20) {
        company.longName = "";
      } else if (company.name.substring(21).length <= 20) {
        company.longName = company.name.substring(20);
      } else {
        company.longName = company.name.substring(20, 35) + "...";
      }
      return company.name.substring(0, 20);
    }
  },
  watch: {
    selectedSize() {
      this.alertsData = this.getAlertsData();
    }
  },
  async mounted() {
    this.userId = localStorage.getItem("user-id");
    await this.getTerritoryCompanies();
    this.updateCompanySize();
    this.alertsData = this.getAlertsData();
    this.alertsByCompanies = this.getAverageAlerts();
    this.fetched = true;
  }
};
</script>
