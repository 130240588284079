export const employeeCoefs = [
  { value: 140, key: 140 },
  { value: 145, key: 145 },
  { value: 155, key: 155 },
  { value: 170, key: 170 },
  { value: 180, key: 180 },
  { value: 190, key: 190 },
  { value: 215, key: 215 },
  { value: 225, key: 225 },
  { value: 240, key: 240 },
  { value: 255, key: 255 },
  { value: 270, key: 270 },
  { value: 285, key: 285 },
  { value: 305, key: 305 },
  { value: 335, key: 335 },
  { value: 365, key: 365 },
  { value: 395, key: 395 }
];

export const employeeJobGroups = [
  { value: "A - 1", key: "A - 1" },
  { value: "A - 2", key: "A - 2" },
  { value: "B - 3", key: "B - 3" },
  { value: "B - 4", key: "B - 4" },
  { value: "C - 5", key: "C - 5" },
  { value: "C - 6", key: "C - 6" },
  { value: "D - 7", key: "D - 7" },
  { value: "D - 8", key: "D - 8" },
  { value: "E - 9", key: "E - 9" },
  { value: "E - 10", key: "E - 10" },
  { value: "F - 11", key: "F - 11" },
  { value: "F - 12", key: "F - 12" },
  { value: "G - 13", key: "G - 13" },
  { value: "G - 14", key: "G - 14" },
  { value: "H - 15", key: "H - 15" },
  { value: "H - 16", key: "H - 16" },
  { value: "I - 17", key: "I - 17" },
  { value: "I - 18", key: "I - 18" }
];

export const employeeLevels = [
  { value: "1", key: "I" },
  { value: "2", key: "II" },
  { value: "3", key: "III" },
  { value: "4", key: "IV" },
  { value: "5", key: "V" }
];

export const employeeSalaryGrade = [
  { value: 1, key: "1" },
  { value: 2, key: "2" },
  { value: 3, key: "3" }
];

export const employeeGroups = [
  { value: "1", key: "1" },
  { value: "2", key: "2" },
  { value: "3", key: "3" }
];

export const employeePositions = [
  { value: "1", key: "I" },
  { value: "2", key: "II" },
  { value: "3", key: "III" },
  { value: "31", key: "III A" },
  { value: "32", key: "III B" },
  { value: "33", key: "III C" },
  { value: "4", key: "IV" },
  { value: "5", key: "V" }
];

export const employeeCsp = [
  { value: "Ouvrier", key: "Ouvrier" },
  { value: "Technicien d'atelier", key: "Technicien d'atelier" },
  { value: "Administratif", key: "Administratif" },
  { value: "Technicien (hors atelier)", key: "Technicien (hors atelier)" },
  { value: "Agent de maîtrise", key: "Agent de maîtrise" }
];

export const professionalFamilies = [
  { value: "Production", key: "Production" },
  { value: "Commercial", key: "Commercial" },
  { value: "Logistique", key: "Logistique" },
  { value: "Gestion/Finances", key: "Gestion/Finances" },
  { value: "R&D", key: "R&D" },
  { value: "Ressources Humaines", key: "Ressources Humaines" },
  { value: "Achats", key: "Achats" },
  { value: "QHSE", key: "QHSE" },
  { value: "Maintenance", key: "Maintenance" },
  { value: "Marketing", key: "Marketing" },
  { value: "Communication", key: "Communication" },
  { value: "Direction Générale", key: "Direction Générale" },
  { value: "Informatique", key: "Informatique" },
  { value: "BE / Méthodes", key: "BE / Méthodes" },
  { value: "Administratif", key: "Administratif" },
  { value: "Juridique", key: "Juridique" },
  { value: "Services généraux", key: "Services généraux" }
];
