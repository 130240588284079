<template>
  <div class="self-center w-72">
    <div class="flex justify-between w-80 text-sm">
      <p>0</p>
      <p>50</p>
      <p>100</p>
      <p>250</p>
      <p>250+</p>
    </div>
    <vue-range-slider
      v-model="value"
      :min="0"
      :max="200"
      :step="50"
      :tooltip="false"
      :processStyle="processStyle"
      :bgStyle="backgroundStyle"
      :piecewise="true"
      :piecewise-style="piecewiseStyle"
      :width="width"
      @slide-end="sendRange()"
    >
    </vue-range-slider>
  </div>
</template>

<script>
import VueRangeSlider from "vue-range-component-fixed";

export default {
  components: { VueRangeSlider },
  data() {
    return {
      value: [0, 200],
      processStyle: { backgroundColor: "#abb2ce" },
      backgroundStyle: {
        backgroundColor: "#fff",
        boxShadow: "inset 0.5px 0.5px 3px 1px rgba(0,0,0,.36)"
      },
      piecewiseStyle: {
        backgroundColor: "#abb2ce",
        height: "0.735rem",
        width: "0.3rem",
        "border-radius": "3px"
      },
      width: "19rem"
    };
  },
  methods: {
    sendRange() {
      const startValue = this.convertValues(this.value[0]);
      const endValue = this.convertValues(this.value[1]);
      this.$emit("update", startValue, endValue);
    },
    convertValues(value) {
      switch (value) {
        case 150:
          return 250;
        case 200:
          return 300;
        default:
          return value;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .slider-dot-handle {
  width: 0.375rem !important;
  height: 1rem !important;
  border-radius: 3px !important;
  background-color: #ecedf1 !important;
  margin-inline: auto;
}
</style>
