<template>
  <div class="flex flex-col mt-1/4 mb-1/4 p-8">
    <img class="ml-auto mr-auto" :src="$asset('icons/info-icon.svg')" alt="info-icon" />
    <span class="mt-4 ml-auto mr-auto text-gris-moyen text-4xl font-bold">
      {{ message }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.button-line {
  top: -16px;
  left: 82%;
}
</style>
