<template>
  <div>
    <custom-sub-view-title title="Classification" class="mt-16">
      <div
        v-if="
          employee.contractType !== $SpecificContractType &&
            usedClassification === 'Classification Actuelle'
        "
      >
        <h2 class="text-gris font-bold mt-10 text-2xl">
          Non-cadres
        </h2>

        <div class="flex items-center space-x-9">
          <div class="flex-1">
            <div class="relative h-8 mb-3 ">
              <label class="absolute text-input-label font-bold text-gris bottom-0">
                NIVEAU
              </label>
            </div>

            <custom-select-input
              id="employee-level"
              name="level-input"
              :options="$employeeLevels"
              :defaultOption="'Sélectionnez un niveau'"
              cssClass="text-input font-normal"
              v-model="employee.level"
            />
          </div>
          <div class="flex space-x-6 flex-1">
            <div class="flex-g-1">
              <div class="relative h-8 mb-3">
                <label class="absolute text-input-label font-bold text-gris bottom-0">
                  ÉCHELON
                </label>
              </div>
              <custom-select-input
                id="employee-salary-grade"
                name="salary-grade-input"
                :options="$employeeSalaryGrade"
                :defaultOption="'0'"
                cssClass="text-input font-normal"
                v-model="employee.salaryGrade"
              />
            </div>
            <div class="flex-g-2">
              <div class="relative h-8 mb-3">
                <label class="absolute text-input-label font-bold text-gris bottom-0">
                  COEFFICIENT
                </label>
              </div>
              <custom-select-input
                v-if="employee.coef"
                id="employee-coefficient"
                name="coefficient-input"
                :options="$employeeCoefs"
                :defaultOption="'Sélectionnez un coefficient'"
                cssClass="text-input font-normal"
                v-model="employee.coef.value"
              />
            </div>
          </div>
          <div class="flex-1"></div>
        </div>
      </div>
      <div
        v-if="
          employee.contractType !== $SpecificContractType &&
            usedClassification !== 'Classification Actuelle'
        "
      >
        <h2 class="text-gris font-bold mt-10 text-2xl">
          Cadres et non cadres
        </h2>
        <div class="flex items-center space-x-9">
          <div class="flex-1">
            <div class="relative h-8 mb-3">
              <label class="absolute text-input-label font-bold text-gris bottom-0">
                GROUPE - CLASSE D'EMPLOIS
              </label>
            </div>
            <custom-select-input
              id="employee-jobGroup"
              name="job-class-input"
              :options="$employeeJobGroups"
              cssClass="text-input font-normal"
              v-model="employee.jobGroupAndClass"
            />
          </div>
          <div class="flex-1"></div>
          <div class="flex-1"></div>
        </div>
      </div>
      <div v-if="employee.contractType === $SpecificContractType">
        <h2 class="text-gris font-bold mt-10 text-2xl">
          Alternants
        </h2>
        <div class="flex items-center space-x-9">
          <div class="flex-1">
            <div class="relative h-8 mb-3">
              <label class="absolute text-input-label font-bold text-gris bottom-0">
                GROUPE
              </label>
            </div>
            <custom-select-input
              id="employee-group"
              name="group-input"
              :options="$employeeGroups"
              :defaultOption="'Sélectionnez un groupe'"
              cssClass="text-input font-normal"
              v-model="employee.group"
            />
          </div>
          <div class="flex-1"></div>
          <div class="flex-1"></div>
        </div>
      </div>
    </custom-sub-view-title>
  </div>
</template>
<script>
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";
import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";

export default {
  components: {
    CustomSubViewTitle,
    CustomSelectInput
  },
  props: {
    employee: {
      required: true
    },
    usedClassification: {
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.flex-g-1 {
  flex-grow: 1;
}

.flex-g-2 {
  flex-grow: 2;
}
</style>
