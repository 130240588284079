<template>
  <div>
    <custom-sub-view-title title="Données de l’emploi" class="mt-16">
      <div class="flex items-center space-x-9">
        <div class="flex-1">
          <div class="relative h-16 mb-3">
            <label
              for="professional-family"
              class="absolute text-input-label font-bold text-gris bottom-0"
            >
              FAMILLE PROFESSIONNELLE DE L'EMPLOI
            </label>
          </div>

          <custom-select-input
            v-if="employee.jobProfessionalFamily"
            id="employee-job-profam-name"
            name="cqpm-input"
            :options="$professionalFamilies"
            :defaultOption="'Famille professionnelle de l’emploi'"
            cssClass="text-input font-normal"
            v-model="employee.jobProfessionalFamily.name"
          />
        </div>
        <div class="flex-1">
          <div class="relative h-16 mb-3">
            <label class="absolute text-input-label font-bold text-gris bottom-0">
              SERVICE / SECTEUR / ATELIER / ILOT
            </label>
          </div>
          <custom-select-input
            v-if="employee.service"
            id="employee-service"
            name="service-input"
            :options="servicesOptions"
            :defaultOption="'Service / Secteur / Atelier / Ilot'"
            cssClass="text-input font-normal"
            v-model="employee.service.name"
          />
        </div>
        <div class="flex-1">
          <custom-form-input
            inputId="employee-current-job-title"
            inputLabel="INTITULÉ D'EMPLOI ACTUEL"
            v-model="employee.currentJobTitle"
            inputPlaceholder="Intitulé de l'emploi actuel"
          />
        </div>
      </div>
    </custom-sub-view-title>
  </div>
</template>

<script>
import CustomSelectInput from "@/components/atoms/CustomSelectInput.vue";
import CustomFormInput from "@/components/atoms/CustomFormInput.vue";
import CustomSubViewTitle from "@/components/atoms/CustomSubViewTitle.vue";

export default {
  components: {
    CustomSubViewTitle,
    CustomFormInput,
    CustomSelectInput
  },
  props: {
    employee: {
      required: true
    },
    services: {
      required: true,
      type: Array
    }
  },
  computed: {
    servicesOptions() {
      return this.services
        ? this.services.map(service => ({ value: service.name, key: service.name }))
        : [];
    }
  },
  data() {
    return {
      actualEmployee: {
        cqpmCategory: this.employee.cqpmCategory || "",
        currentJobTitle: this.employee.currentJobTitle || "",
        jobProfessionalFamily: this.employee.jobProfessionalFamily || {},
        service: this.employee.service || {}
      },
      errors: []
    };
  },
  watch: {
    employee: {
      deep: true,
      handler() {
        this.actualEmployee = this.$props.employee;
      }
    }
  }
};
</script>
