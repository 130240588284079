import Vue from "vue";
import axios, { AxiosError } from "axios";
import VueAxios from "vue-axios";

import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import "vue-range-component-fixed/dist/vue-range-slider.css";
import "./assets/styles/tailwind.css";
import "./assets/styles/main.css";

import AuthService from "./services/AuthService";
import router from "./router";
import store from "./store";

import {
  employeeCoefs,
  employeeJobGroups,
  employeeLevels,
  employeeSalaryGrade,
  employeeGroups,
  employeePositions,
  employeeCsp,
  professionalFamilies
} from "./constant";

import App from "./App.vue";

document.title = "Cartometa";

Vue.prototype.$asset = (path: string) => `/assets/${path}`;
Vue.prototype.$SpecificContractType = "Alternance";
Vue.prototype.$Non = "Non";
Vue.prototype.$employeeCoefs = employeeCoefs;
Vue.prototype.$employeeJobGroups = employeeJobGroups;
Vue.prototype.$employeeLevels = employeeLevels;
Vue.prototype.$employeeSalaryGrade = employeeSalaryGrade;
Vue.prototype.$employeeGroups = employeeGroups;
Vue.prototype.$employeePositions = employeePositions;
Vue.prototype.$employeeCsp = employeeCsp;
Vue.prototype.$professionalFamilies = professionalFamilies;

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_BASE_URL}/api`;
Vue.axios.interceptors.response.use(
  response => response,
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      const authService = new AuthService();
      const { token } = await authService.refreshToken();
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    return Promise.reject(error);
  }
);

Vue.use(Datetime as any);

Vue.config.productionTip = false;
const userToken = localStorage.getItem("user-token");
if (userToken) {
  axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
}

Vue.directive("click-outside", {
  bind: (el: any, binding) => {
    // eslint-disable-next-line no-param-reassign
    el.clickOutsideEvent = (event: { stopPropagation: () => void; target: any }) => {
      event.stopPropagation();
      if (!(el === event.target || el.contains(event.target))) {
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: (el: any): void => {
    document.removeEventListener("click", el.clickOutsideEvent);
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
