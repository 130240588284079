<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>

      <div
        class="inline-block align-bottom bg-couleur-blanc rounded-sm text-left
        overflow-hidden shadow-xl transform transition-all
        sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-couleur-blanc sm:p-6 sm:pb-4">
          <!--CONTENT-->
          <h2 class="text-center text-m font-bold text-gris">
            Inscription
          </h2>
          <div class="flex flex-col items-center mt-4">
            <p class="text-center text-xs mb-4">
              Votre demande d'accès à la plateforme a été envoyée à l'administrateur du territoire.
              Vous pourrez vous connecter une fois que celle-ci aura été validée.
            </p>
            <button class="submit-button bg-gris text-md" @click="close">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
      this.$router.push("/login");
    }
  }
};
</script>
