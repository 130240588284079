<template>
  <div class="flex relative line-draw-container">
    <div class="flex self-center border-b-2 border-gris-moyen w-full z-0"></div>
    <div class="absolute buttons-line w-full justify-center z-10 flex space-x-24">
      <h3
        v-for="title in titles"
        :key="title"
        :class="[
          'title',
          { 'text-gris-moyen': activeTab !== title },
          { 'text-gris': activeTab === title }
        ]"
        @click="changeTab(title)"
      >
        {{ title }}
      </h3>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    titles: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      activeTab: this.titles.length ? this.titles[0] : ""
    };
  },
  methods: {
    changeTab(value) {
      this.activeTab = value;
      this.$emit("tab-change", value);
    }
  }
};
</script>
<style lang="css" scoped>
.title-line {
  left: 100px;
  top: -10px;
}
.input-line {
  left: 74%;
  top: -17px;
}
.buttons-line {
  top: -10px;
}
.title {
  @apply font-bold;
  @apply z-10;
  @apply px-4;
  @apply bg-white;
  @apply text-xl;
  @apply uppercase;
  @apply cursor-pointer;
}
</style>
