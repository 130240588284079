<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>

      <div
        class="inline-block align-bottom bg-couleur-blanc rounded-sm text-left
        overflow-hidden shadow-xl transform transition-all
        sm:my-8 sm:align-middle w-file-upload-modal h-file-upload-modal"
      >
        <div class="flex flex-col items-center bg-couleur-blanc sm:p-6 sm:pb-4">
          <!--CONTENT-->
          <div class="text-right w-full flex">
            <img
              :src="$asset('icons/close-icon.svg')"
              class="ml-auto cursor-pointer h-6 w-6"
              alt="close"
              @click="close"
            />
          </div>
          <h1 class="font-bold sub-view-title text-sub-view-title mb-8">
            {{ innerModalTitle }}
          </h1>

          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalTitle: {
      type: String,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  },
  data() {
    return {
      innerModalTitle: this.modalTitle
    };
  }
};
</script>
